import { useCallback, useState } from "react";
import UserGroup from "../../Domain/Entities/UserGroup/UserGroup";
import classnames from 'classnames';
import UserGroupEditItem from "./UserGroupEditItem";
import IconButton from "@mui/material/IconButton";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export type UserGroupListType = {
  userGroup: UserGroup
  isSelected?: boolean
  onSelectedUserGroup?: (event: any, userGroup: UserGroup) => void
  canUpdate?: boolean
  canDelete?: boolean
  className?: string,
  onUpdate?: (userGroup: UserGroup, newName: string) => Promise<any>
  onDeleteClick?: (userGroup: UserGroup) => void
}

export default function UserGroupListItem(props: UserGroupListType) {
  const { userGroup, isSelected, onSelectedUserGroup, onUpdate, canUpdate, canDelete, onDeleteClick } = props;
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const className = classnames('user-group-list-item', props.className, {
    'user-group-list-item--selected': isSelected
  })

  const onClickCallback = useCallback((event: any) => onSelectedUserGroup?.(event, userGroup),
    [onSelectedUserGroup, userGroup]
  )
  const onCancel = () => {
    setIsUpdating(false)
  }
  const onCreate = (value: string) => {
    setIsUpdateInProgress(true)
    return onUpdate?.(userGroup, value)
      .finally(() => {
        setIsUpdating(false)
        setIsUpdateInProgress(false)
      })
  }
  return <div className={className} onClick={onClickCallback} onDoubleClick={() => setIsUpdating(true)}>
    <UserGroupEditItem
      show={isUpdating}
      initialValue={userGroup.name}
      onCreate={onCreate}
      onCancel={onCancel}
      isLoading={isUpdateInProgress}
    >
      {userGroup.name}
    </UserGroupEditItem>
    <div className="user-group-list-item__actions">
      {canUpdate && (
        <IconButton
          className="user-group-list-item__edit-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsUpdating(true)
          }}
          onMouseDown={(event) => event.stopPropagation()}
          title="Изменить название группы пользователей"
        >
          <ModeEditOutlineOutlined />
        </IconButton>
      )}
      {canDelete && (
        <IconButton
          className="user-group-list-item__delete-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDeleteClick?.(userGroup)
          }}
          onMouseDown={(event) => event.stopPropagation()}
          title="Удалить группу пользователей"
        >
          <DeleteOutlined color="error" />
        </IconButton>
      )}
    </div>
  </div>
}