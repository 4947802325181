import { memo } from "react";
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import classnames from "classnames";
import TextField from "../../Common/Components/TextField";
import Loader from "../../Common/Components/Loader";
import useCreateDirectory from "../_hooks/Directory/useCreateDirectory";
import Directory from "../../Domain/Entities/Directory/Directory";
import DirectoryCreateDto from "../../Domain/Dtos/Directory/DirectoryCreateDto";
import { useModal } from "../../System/hooks/useModal";
import { apiErrorParser } from "../../Common/utils";

export type DirectoryProps = {
  onCancel?: () => void,
  onCreated?: (data: any) => void,
  className?: string,
  parent?: Directory,
}

function DirectoryCreate(props: DirectoryProps) {
  const { onCancel, onCreated, parent } = props;
  const modal = useModal();
  const createDirectoryHook = useCreateDirectory((data: any) => onCreated?.(data));
  const className = classnames(
    'directory-create',
    props.className,
  )

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const { value } = event.target

      if (value !== '') {
        onCreate(event.target.value)
      }
    }

    if (event.key === 'Escape') {
      onCancel?.()
    }
  }

  const onCreate = (value: string) => {
    return createDirectoryHook
      .mutateAsync(new DirectoryCreateDto(value, parent?.id))
      .catch((errors) => modal.showInformationModal({
        title: value,
        contentText: apiErrorParser(errors).common || 'Произошла ошибка',
        onAccepte: () => {
          modal.closeModal()
          onCancel?.()
        }
      }))
  }

  const onBlur = (event: any) => {
    const { value } = event.target

    if (value === '') {
      onCancel?.()
      return
    }

    onCreate(value)
  }



  return <div className={className}>
    <FolderOutlined />
    <div className="directory-create__content">
      <TextField
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        size="small"
        autoFocus
        disabled={createDirectoryHook.isLoading}
        placeholder="Название папки"
      />
      {createDirectoryHook.isLoading && <Loader size={24} />}
    </div>
  </div >
}

export default memo(DirectoryCreate)