export default class ChangePasswordDto {
  constructor(
    readonly oldPassword: string,
    readonly newPassword: string,
    readonly confirmPassword: string,
  ) { }

  static fromObject(obj: any) {
    return new ChangePasswordDto(
      obj.oldPassword,
      obj.newPassword,
      obj.confirmPassword,
    )
  }
}