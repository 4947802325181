import { AccountCircle } from "@mui/icons-material";
import { useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Profile from "../../../Domain/Entities/Profile/Profile";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../../Domain/Enums/Roles";

export type UserAccountProps = {
  profile: Profile,
  onLogoutClick?: (event: any) => void;
  onExportPasscardsClick?: (event: any) => void;
}

export default function UserAccount(props: UserAccountProps) {
  const { profile, onLogoutClick, onExportPasscardsClick } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClickHandler = (event: any) => {
    onLogoutClick?.(event)
    handleClose()
  }

  const onExportPasscardsClickHandler = (event: any) => {
    onExportPasscardsClick?.(event)
    handleClose()
  }

  const onProfileClickHandler = (event: any) => {
    navigate("/profile")
    handleClose()
  }

  const onUsersGroupClickHandler = (event: any) => {
    navigate("/user-groups")
    handleClose()
  }

  const onUsersClickHandler = (event: any) => {
    navigate("/users")
    handleClose()
  }

  const onInviteCodeClickHandler = (event: any) => {
    navigate("/invite-codes")
    handleClose()
  }

  const onMatrixClickHandler = (event: any) => {
    navigate("/matrix")
    handleClose()
  }

  const onUserStoragesClickHandler = (event: any) => {
    navigate("/user-storages")
    handleClose()
  }

  return <div className="user-account">
    <Button
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="inherit"
    >
      <span className="user-account__user-first-name">{profile.firstName}</span>
      <AccountCircle />
    </Button>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={onProfileClickHandler} className="user-account__profile-link">
        Профиль
      </MenuItem>
      <Divider />
      {profile.role.role === ROLES.ADMIN &&
        <MenuItem onClick={onUsersGroupClickHandler}>
          Группы пользователей
        </MenuItem>
      }
      {profile.role.role === ROLES.ADMIN &&
        <MenuItem onClick={onUsersClickHandler}>
          Пользователи
        </MenuItem>
      }
      {profile.role.role === ROLES.ADMIN &&
        <MenuItem onClick={onInviteCodeClickHandler}>
          Инвайт-коды
        </MenuItem>
      }
      {profile.role.role === ROLES.ADMIN &&
        <MenuItem onClick={onMatrixClickHandler}>
          Матрица доступа
        </MenuItem>
      }
      {profile.role.role === ROLES.ADMIN &&
        <MenuItem onClick={onUserStoragesClickHandler}>
          Хранилища пользователей
        </MenuItem>
      }
      {profile.role.role === ROLES.ADMIN && <Divider />}
      {profile.role.role === ROLES.ADMIN &&
        <MenuItem onClick={onExportPasscardsClickHandler} className="user-account__profile-link">
          Импорт / Экспорт
        </MenuItem>
      }
      {profile.role.role === ROLES.ADMIN && <Divider />}
      <MenuItem onClick={onLogoutClickHandler}>Выйти</MenuItem>
    </Menu>
  </div>
}