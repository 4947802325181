import { IconButton, TextareaAutosize } from "@mui/material";
import { Body, Cell, EditableCell, Row, Table } from "../../../Common/Components/Table";
import Passcard from "../../../Domain/Entities/Passcard/Passcard";
import ViewPassword from "../ViewPassword/ViewPassword";
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import DriveFolderUploadOutlined from '@mui/icons-material/DriveFolderUploadOutlined';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import Star from '@mui/icons-material/Star';
import Copy from "../../../Common/Components/Copy/Copy";
import UrlOrText from "../../../Common/Components/UrlOrText/UrlOrText";
import DataTable, { BodyLineCell, HeadLineCell, TableRowProps } from "../../../Common/Components/Table/DataTable";
import { memo, useMemo } from "react";
import Draggable from "../../../Common/Components/DragAndDrop/Draggable";
import CreatePasscard from "../../MainPage/CreatePasscard";

export type PasscardTableProps = {
  onChange?: (value: Passcard) => void,
  onDeleteFromDirectory?: (value: Passcard) => void,
  onDeleteFromAllDirectory?: (value: Passcard) => void,
  onMoveButtonClick?: (value: Passcard) => void,
  onMoveToDirectory?: (directoryId: number, passcard: Passcard) => void,
  passcards: Array<Passcard>,
  canMove?: (passcard: Passcard) => boolean
  availableDragAndDrop?: boolean
  canDeletePasscardFromAllDirectory?: (passcard: Passcard) => boolean
  canDeletePasscardFromDirectory?: (passcard: Passcard) => boolean
  onFavoriteClick?: (value: Passcard) => void,
  canFavorites?: (passcard: Passcard) => boolean
  canEdit?: (passcard: Passcard) => boolean
  canCreate?: boolean
}

const getCellComponent = (
  canEdit: boolean,
  onBlur?: (value: string, fieldName: string, passcard: Passcard) => void,
  onKeyDown?: (event: any, fieldName: string, passcard: Passcard) => void,
) => {
  return canEdit
    ? ({ children, defaultValue, fieldName, titleValue, maxLength, onBlur, onKeyDown }: {
      children: any, defaultValue: any, titleValue: string | undefined, fieldName: string, maxLength?: number, onBlur?: (event: any) => void,
      onKeyDown?: (event: any) => void,
    }) => <EditableCell
      title={titleValue}
      editComponent={<TextareaAutosize
        autoFocus
        defaultValue={defaultValue}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        maxLength={maxLength || 50}
      />}
    >
        {children}
      </EditableCell>
    : ({ children, defaultValue, titleValue }: { children: any, defaultValue: any, titleValue: any }) => <Cell width={100} title={titleValue}>{children}</Cell>
}

const getCells = (
  passcard: Passcard,
  canEdit?: (passcard: Passcard) => boolean,
  onBlur?: (value: string, fieldName: string, passcard: Passcard) => void,
  onKeyDown?: (event: any, fieldName: string, passcard: Passcard) => void
) => {
  const cellComponent = getCellComponent(Boolean(canEdit?.(passcard)), onBlur, onKeyDown)
  return [
    {
      id: 1,
      value: passcard.name,
      renderValue: <Copy className="passcard-table__copy" value={passcard.name}>
        {passcard.name}
      </Copy>,
      cellComponent,
      config: {
        width: 100,
        title: passcard.name,
        defaultValue: passcard.name,
        onBlur: (e: any) => {
          onBlur?.(e.target.value, 'name', passcard)
        },
        onKeyDown: (event: any) => onKeyDown?.(event, 'name', passcard),
        maxLength: 50,
      }
    },
    {
      id: 2,
      value: passcard.url,
      renderValue: <Copy
        className="passcard-table__copy passcard-table__passcard-url"
        value={passcard.url}
      >
        <UrlOrText
          target="_blank"
          rel="noopener noreferrer"
          href={passcard.url}
        />
      </Copy>,
      cellComponent,
      config: {
        width: 100,
        title: passcard.url,
        defaultValue: passcard.url,
        onBlur: (e: any) => onBlur?.(e.target.value, 'url', passcard),
        onKeyDown: (event: any) => onKeyDown?.(event, 'url', passcard),
        maxLength: 50,
      }
    },
    {
      id: 3,
      value: passcard.login,
      renderValue: <Copy className="passcard-table__copy" value={passcard.login}>
        {passcard.login}
      </Copy>,
      cellComponent,
      config: {
        width: 100,
        title: passcard.login,
        defaultValue: passcard.login,
        onBlur: (e: any) => onBlur?.(e.target.value, 'login', passcard),
        onKeyDown: (event: any) => onKeyDown?.(event, 'login', passcard),
        maxLength: 50,
      }
    },
    {
      id: 4,
      value: passcard.password,
      renderValue: <Copy className="passcard-table__copy" value={passcard.password}>
        <ViewPassword passcard={passcard} />
      </Copy>,
      cellComponent,
      config: {
        width: 100,
        defaultValue: passcard.password,
        onBlur: (e: any) => onBlur?.(e.target.value, 'password', passcard),
        onKeyDown: (event: any) => onKeyDown?.(event, 'password', passcard),
        maxLength: 50,
      }
    },
    {
      id: 5,
      value: passcard.description,
      renderValue: passcard.description,
      cellComponent,
      config: {
        width: 100,
        title: passcard.description,
        defaultValue: passcard.description,
        onBlur: (e: any) => onBlur?.(e.target.value, 'description', passcard),
        onKeyDown: (event: any) => onKeyDown?.(event, 'description', passcard),
        maxLength: 50,
      }
    },
  ]
}

const getPlaceholderRow = (passcard: Passcard) => {
  return <Table className="passcard-placeholder-table">
    <Body>
      <Row>
        <Cell height={50}>{passcard.name}</Cell>
        <Cell> <UrlOrText
          target="_blank"
          rel="noopener noreferrer"
          href={passcard.url}
        /></Cell>
        <Cell>{passcard.login}</Cell>
        <Cell>********</Cell>
        <Cell>{passcard.description}</Cell>
      </Row>
    </Body>
  </Table>
}

const getRowWrapper = (passcard: Passcard, hasPasscardFullAccess: boolean, onDraggingEndHandler: (passcard: Passcard) => (from: HTMLElement, to: HTMLElement | undefined) => Promise<void>) => {
  if (!hasPasscardFullAccess) {
    const result = ({ children, ...restProps }: any) => <Row key={undefined} {...restProps}>{children}</Row>
    return result;
  }
  const result = ({ children, ...restProps }: any) => <Draggable
    key={passcard.id}
    dropToKey="passcard"
    onDraggingEnd={onDraggingEndHandler(passcard)}
    renderDraggableElement={() => getPlaceholderRow(passcard)}
  >
    <Row key={undefined} {...restProps}>{children}</Row>
  </Draggable>

  return result;
}

const getPasscardRows = (
  passcards: Array<Passcard>,
  onBlur?: (value: string, fieldName: string, passcard: Passcard) => void,
  onKeyDown?: (event: any, fieldName: string, passcard: Passcard) => void,
  onDeleteFromDirectory?: (value: Passcard) => void,
  onDeleteFromAllDirectory?: (value: Passcard) => void,
  onMoveButtonClick?: (value: Passcard) => void,
  canMove?: (passcard: Passcard) => boolean,
  onMoveToDirectory?: (directoryId: number, passcard: Passcard) => void,
  availableDragAndDrop?: boolean,
  canDeletePasscardFromAllDirectory?: (passcard: Passcard) => boolean,
  canDeletePasscardFromDirectory?: (passcard: Passcard) => boolean,
  onFavoriteClick?: (value: Passcard) => void,
  canFavorites?: (passcard: Passcard) => boolean,
  canEdit?: (passcard: Passcard) => boolean
) => {
  const onDraggingEndHandler = (passcard: Passcard) => async (from: HTMLElement, to: HTMLElement | undefined) => {
    if (!to) return;
    const directoryId = +to.dataset.directoryid!

    onMoveToDirectory?.(directoryId, passcard)
  }

  return passcards.map((x) => {
    const result: TableRowProps<BodyLineCell> = {
      id: x.id,
      render: getRowWrapper(x, Boolean(availableDragAndDrop), onDraggingEndHandler),
      cells: getCells(x, canEdit, onBlur, onKeyDown)
    }

    const canFavoritesResult = canFavorites?.(x)
    const canMoveResult = canMove?.(x)
    const canDeletePasscardFromDirectoryResult = canDeletePasscardFromDirectory?.(x)
    const canDeletePasscardFromAllDirectoryResult = canDeletePasscardFromAllDirectory?.(x)

    if (canFavoritesResult || canMoveResult || canDeletePasscardFromDirectoryResult || canDeletePasscardFromAllDirectoryResult) {
      result.cells.push({
        id: 6,
        value: undefined,
        renderValue: <div style={{ display: 'flex' }}>
          {canFavoritesResult && <IconButton
            title="Удалить пароль из папки"
            onClick={() => onFavoriteClick?.(x)}
          >
            {x.isFavorite ? <Star color="info" /> : <StarBorderOutlined color="info" />}
          </IconButton>
          }
          {canMoveResult && <IconButton
            title="Расположение пароля"
            onClick={() => onMoveButtonClick?.(x)}
          >
            <DriveFolderUploadOutlined />
          </IconButton>
          }
          {canDeletePasscardFromDirectoryResult && <IconButton
            title="Удалить пароль из папки"
            onClick={() => onDeleteFromDirectory?.(x)}
          >
            <CloseOutlined color="error" />
          </IconButton>
          }
          {canDeletePasscardFromAllDirectoryResult && <IconButton
            title="Удалить пароль со всех папок"
            onClick={() => onDeleteFromAllDirectory?.(x)}
          >
            <DeleteOutlined color="error" />
          </IconButton>
          }
        </div>,
      })
    }

    return result;
  })
}

const headLinesOrigin: Readonly<Array<TableRowProps<HeadLineCell>>> = Object.freeze([
  {
    id: 1,
    cells: [
      {
        id: 1,
        value: 'Название',
        renderValue: 'Название',
        config: {
          searchable: true
        }
      },
      {
        id: 2,
        value: 'Адрес',
        renderValue: 'Адрес',
        config: {
          searchable: true
        }
      },
      {
        id: 3,
        value: 'Логин',
        renderValue: 'Логин',
        config: {
          searchable: true
        }
      },
      {
        id: 4,
        value: 'Пароль',
        renderValue: 'Пароль',
        config: {
          searchable: true
        }
      },
      {
        id: 5,
        value: 'Описание',
        renderValue: 'Описание',
        config: {
          searchable: true
        }
      }
    ]
  }
]);

function PasscardTable(props: PasscardTableProps) {
  const {
    onDeleteFromAllDirectory, onDeleteFromDirectory, onMoveButtonClick, passcards,
    canEdit, canMove, onMoveToDirectory, availableDragAndDrop,
    canDeletePasscardFromAllDirectory, canDeletePasscardFromDirectory, canFavorites,
    onFavoriteClick, canCreate
  } = props;

  const onBlur = (value: string, fieldName: string, passcard: Passcard) => {
    if (value === '' || value === (passcard as any)[fieldName]) return

    const newValue = {
      ...passcard,
      [fieldName]: value,
    }

    const newPasscard = new Passcard(
      newValue.id,
      newValue.parentIds,
      newValue.name,
      newValue.url,
      newValue.login,
      newValue.password,
      newValue.description,
      newValue.isFavorite,
      passcard.storageIds,
    )

    props.onChange?.(newPasscard);
  }

  const onKeyDown = (event: any, fieldName: string, passcard: Passcard) => {
    if (event.key !== 'Enter') return
    const { value } = event.target

    if (value === '' || value === (passcard as any)[fieldName]) return

    const newValue = {
      ...passcard,
      [fieldName]: value,
    }

    const newPasscard = new Passcard(
      newValue.id,
      newValue.parentIds,
      newValue.name,
      newValue.url,
      newValue.login,
      newValue.password,
      newValue.description,
      newValue.isFavorite,
      passcard.storageIds,
    )

    props.onChange?.(newPasscard);
  }

  const onePasscardHasAction = useMemo(() => {
    if (!canFavorites
      && !canMove
      && !canDeletePasscardFromDirectory
      && !canDeletePasscardFromAllDirectory
    ) return false;

    return passcards.some((x) => canFavorites?.(x) || canMove?.(x) || canDeletePasscardFromDirectory?.(x) || canDeletePasscardFromAllDirectory?.(x))
  }, [passcards, canFavorites, canMove, canDeletePasscardFromDirectory, canDeletePasscardFromAllDirectory])

  const headLines = useMemo(() => {
    const result = Array.from(headLinesOrigin);

    if (onePasscardHasAction) {
      if (!result[0].cells.find((x) => x.id === 6)) {
        result[0].cells.push({
          id: 6,
          value: '',
          renderValue: '',
        })
      }
    } else {
      if (result[0].cells.find((x) => x.id === 6)) {
        result[0].cells.pop()
      }
    }

    return result;
  }, [passcards, onePasscardHasAction])

  const bodyLines = useMemo(() => getPasscardRows(passcards, onBlur, onKeyDown,
    onDeleteFromDirectory, onDeleteFromAllDirectory, onMoveButtonClick,
    canMove, onMoveToDirectory, availableDragAndDrop, canDeletePasscardFromAllDirectory,
    canDeletePasscardFromDirectory, onFavoriteClick, canFavorites, canEdit),
    [passcards, onBlur, onKeyDown, onDeleteFromDirectory, onDeleteFromAllDirectory, onMoveButtonClick, canMove, onMoveToDirectory, availableDragAndDrop, canDeletePasscardFromAllDirectory, onFavoriteClick, canFavorites, canEdit]
  )

  if (passcards.length === 0 && canCreate) {
    return <CreatePasscard />
  }

  return <div className="passcard-table">
    <DataTable
      headLines={headLines}
      bodyLines={bodyLines}
    />
  </div>
}

const DefaultPasscardTable = memo(PasscardTable)

export default DefaultPasscardTable