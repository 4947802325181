import { useMutation } from "react-query";
import RegistrationDto from "../../../Domain/Dtos/Authorization/RegistrationDto";
import AuthorizationService from "../../../Domain/Services/AuthorizationService/AuthorizationService";

export default function useRegistration(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (data: RegistrationDto) => AuthorizationService.register(data),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}