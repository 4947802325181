// QK = queryKey
export const DIRECTORY_GET_ALL_QK = 'DIRECTORY_GET_ALL_QK';
export const DIRECTORY_GET_ALL_SHORT_QK = 'DIRECTORY_GET_ALL_SHORT_QK';
export const DIRECTORY_GET_BY_ID_QK = 'DIRECTORY_GET_BY_ID_QK';
export const DIRECTORY_GET_SHORT_QK = 'DIRECTORY_GET_SHORT_QK';

export const PASSCARD_GET_ALL_QK = 'PASSCARD_GET_ALL_QK';
export const PASSCARD_GET_BY_ID_QK = 'PASSCARD_GET_BY_ID_QK';
export const PASSCARD_GET_PASSWORD_BY_PASSCARD_ID_QK = 'PASSCARD_GET_PASSWORD_BY_PASSCARD_ID_QK';

export const PROFILE_GET_QK = 'PROFILE_GET_QK';
export const PROFILE_LOGOUT_QK = 'PROFILE_LOGOUT_QK';

export const USER_GET_QK = 'USER_GET_QK';
export const USER_GET_SHORT_QK = 'USER_GET_SHORT_QK';

export const USER_GROUP_GET_QK = 'USER_GROUP_GET_QK';

export const USER_GROUP_MATRIX_GET_QK = 'USER_GROUP_MATRIX_GET_QK';
export const USER_GROUP_MATRIX_GET_GROUPED_BY_DIRECTOY_QK = 'USER_GROUP_MATRIX_GET_GROUPED_BY_DIRECTOY_QK';

export const AUTHORIZATION_CHECK_INVITE_CODE_QK = 'AUTHORIZATION_CHECK_INVITE_CODE_QK';
export const AUTHORIZATION_CHECK_RESTORE_CODE_QK = 'AUTHORIZATION_CHECK_RESTORE_CODE_QK';

export const INVITE_CODES_GET_ALL_QK = 'INVIRE_CODES_GET_ALL_QK';

export const GET_EXPORT_FILE_WITH_PASSCARDS_QK = 'GET_EXPORT_FILE_WITH_PASSCARDS_QK';

export const PASSCARD_FAVORITES_GET_ALL_QK = 'PASSCARD_FAVORITES_GET_ALL_QK';

export const STORAGE_GET_ALL_QK = 'STORAGE_GET_ALL_QK';
export const STORAGE_GET_ALL_SHORT_QK = 'STORAGE_GET_ALL_SHORT_QK';
export const STORAGE_GET_USER_STORAGE_QK = 'STORAGE_GET_USER_STORAGE_QK';