import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useState } from "react";
import CapsLockDetect from "../../../Common/Components/CapsLockDetect/CapsLockDetect";
import { apiErrorParser } from "../../../Common/utils";
import RestoreDto from "../../../Domain/Dtos/Authorization/RestoreDto";
import useStartRestore from "../../_hooks/Authorization/useStartRestore";
import AuthorizationForm from "./RestoreForm";

export default function RestorePage() {
  const [isRestored, setIsRestored] = useState(false)
  const startRestoreHook = useStartRestore();

  const onSubmit = (
    formData: { login: string },
    form: any,
    showErrors: (errors: any) => void
  ) => {
    return startRestoreHook.mutateAsync(RestoreDto.fromObject(formData))
      .then(() => setIsRestored(true))
      .catch((errors: any) => {
        const apiErrors = errors?.status === 404
          ? { common: 'Пользователь не найден' }
          : apiErrorParser(errors)
        showErrors(apiErrors)
      })
  }
  return <div className="restore-page">
    {!isRestored && (
      <Card>
        <CardContent>
          <Typography>Необходимо указать электронную почту или имя пользователя(никнейм), для которого происходит восстановление пароля</Typography>
          <Typography>Если электронная почта или имя пользователя(никнейм) существуют, то на электронную почту будет отправлено письмо с инструкцией по восстановлению пароля</Typography>
        </CardContent>
      </Card>
    )}
    <Card>
      <CardContent>
        {!isRestored
          ? <AuthorizationForm onSubmit={onSubmit} />
          : <>
            <Typography>На электронную почту отправлено письмо с инструкцией по восстановлению пароля</Typography>
            <Typography>Письмо придет в течение 5 минут</Typography>
          </>
        }
      </CardContent>
    </Card>
    <CapsLockDetect />
  </div>
}