import UserCreateDto from "../../../Domain/Dtos/User/UserCreateDto";
import UserUpdateDto from "../../../Domain/Dtos/User/UserUpdateDto";
import User from "../../../Domain/Entities/User/User";
import UserShort from "../../../Domain/Entities/User/UserShort";
import { Api } from "../../Api/Api";
import IUserRepository from "./IUserRepository";

class UserRepository implements IUserRepository {
  constructor(readonly api: typeof Api) { }

  async getAll() {
    return this.api.getUsers()
      .then((x) => x.map(User.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async getAllShort() {
    return this.api.getUsersShort()
      .then((x) => x.map(UserShort.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async create(data: UserCreateDto) {
    return this.api.createUser(data)
      .then(User.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: UserUpdateDto) {
    return this.api.updateUser(data)
      .then(User.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async delete(id: number) {
    return this.api.deleteUser(id)
      .catch((error) => {
        throw error.response;
      });
  }

  async enabledUser(id: number) {
    return this.api.enabledUser(id)
      .catch((error) => {
        throw error.response;
      });
  }

  async disabledUser(id: number) {
    return this.api.disabledUser(id)
      .catch((error) => {
        throw error.response;
      });
  }

  async dropUserPassword(id: number) {
    return this.api.dropUserPassword(id)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new UserRepository(Api);