import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import { ReactNode, useState, HTMLProps } from "react";
import classnames from 'classnames';

export type CopyProps = HTMLProps<HTMLDivElement> & {
  children: ReactNode,
  value: string
}

function fallbackCopyTextToClipboard(text: string) {
  return new Promise<void>((resolve, reject) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      resolve();
    } catch (err) {
      reject();
    }

    document.body.removeChild(textArea);
  })

}

enum COPY_STATUS {
  NONE,
  SUCCESS,
  ERROR
}

const copyToClipboard = (value: string) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(value)
  }
  return navigator.clipboard.writeText(value)
}

export default function Copy(props: CopyProps) {
  const { value, children, className: propsClassName, ...rest } = props
  const [isCopiedSuccessful, setIsCopiedSuccessful] = useState<COPY_STATUS>(COPY_STATUS.NONE);

  const copySuccessful = () => {
    setIsCopiedSuccessful(COPY_STATUS.SUCCESS)
    setTimeout(() => setIsCopiedSuccessful(COPY_STATUS.NONE), 5000)
  }

  const copyError = () => {
    setIsCopiedSuccessful(COPY_STATUS.ERROR)
    setTimeout(() => setIsCopiedSuccessful(COPY_STATUS.NONE), 5000)
  }

  const onCopy = () => {
    copyToClipboard(value)
      .then(copySuccessful)
      .catch(copyError)
  }

  const getTitle = () => {
    switch (true) {
      case isCopiedSuccessful === COPY_STATUS.SUCCESS:
        return "Скопировано в буфер обмена"
      case isCopiedSuccessful === COPY_STATUS.ERROR:
        return "Произошла ошибка при копировании в буфер обмена"
      default:
        return "Копировать в буфер обмена"
    }
  }

  const className = classnames('copy', propsClassName)

  return <div className={className} {...rest}>
    <span className="copy__children">{children}</span>
    <div className="copy__button-wrapper">
      <IconButton onClick={onCopy} title={getTitle()} >
        {isCopiedSuccessful === COPY_STATUS.NONE && <ContentCopyOutlined />}
        {isCopiedSuccessful === COPY_STATUS.SUCCESS && <CheckOutlined color="success" />}
        {isCopiedSuccessful === COPY_STATUS.ERROR && <CloseOutlined color="error" />}
      </IconButton>
    </div>
  </div>
}