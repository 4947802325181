import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import ConfirmationDelete from "../../Common/Components/ConfirmationDelete/ConfirmationDelete";
import Loader from "../../Common/Components/Loader";
import { apiErrorParser } from "../../Common/utils";
import User from "../../Domain/Entities/User/User";
import { useModal } from "../../System/hooks/useModal";
import { useProfile } from "../../System/hooks/useProfile";
import { USER_GET_QK } from "../_hooks/Constants";
import useDeleteUser from "../_hooks/User/useDeleteUser";
import useDisableUser from "../_hooks/User/useDisableUser";
import useDropUserPassword from "../_hooks/User/useDropUserPassword";
import useEnableUser from "../_hooks/User/useEnableUser";
import useGetAllUsers from "../_hooks/User/useGetAllUsers";
import UserList from "./List/UserList";

const updateUserInCache = (cache: User[], updatingUser: User) => {
  const newData = cache;
  const index = newData.findIndex((x) => x.id === updatingUser?.id)

  newData[index] = updatingUser
  return newData;
}

export default function UserPage() {
  const getUsersHook = useGetAllUsers();
  const disableUserHook = useDisableUser();
  const enableUserHook = useEnableUser();
  const dropUserPasswordHook = useDropUserPassword();
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  const modal = useModal();
  const profile = useProfile();

  useEffect(() => {
    modal.changeConfirmationProps({
      isLoading: dropUserPasswordHook.isLoading,
      disabled: dropUserPasswordHook.isLoading,
    })
  }, [dropUserPasswordHook.isLoading])

  if (getUsersHook.isLoading || getUsersHook.isFetching) {
    return <div className="user-page">
      <Loader />
    </div>
  }

  const canDelete = (user: User) => user.id !== profile.profile?.id
  const canUpdate = (user: User) => true
  const canBlock = (user: User) => user.id !== profile.profile?.id
  const canResetPassword = (user: User) => user.id !== profile.profile?.id

  const onUpdateClick = async (user: User) => {
    navigate(`/users/${user.id}/update`, {
      state: {
        modalProps: { modalTitle: "Редактирование пользователя" },
        user,
        canBlock: canBlock(user)
      }
    })
  }

  const onCreateClick = async () => {
    navigate(`/users/create`, {
      state: {
        modalProps: { modalTitle: "Создание пользователя" },
      }
    })
  }

  const onUserBlockClick = (newValue: boolean, user: User) => {
    const newUser = User.fromObject({ ...user, role: user.role.role, isBlocked: newValue })
    const newData = updateUserInCache(getUsersHook.data ?? [], newUser)
    queryClient.setQueryData(USER_GET_QK, newData)
    const hook = newValue ? disableUserHook : enableUserHook
    return hook.mutateAsync(user.id)
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Блокировка пользователя',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onAccepteResetPassword = (user: User) => {
    dropUserPasswordHook.mutateAsync(user.id)
      .then(() => modal.closeModal())
      .then(() => {
        modal.showInformationModal({
          title: `Сброс пароля для ${user.fullName}`,
          contentText: 'Пользователю отправлено письмо с инструкцией по восстановлению пароля',
          onAccepte: modal.closeModal
        })
      })
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: `Сброс пароля для ${user.fullName}`,
          contentText: apiErrorParser(errors).common || "Произошла ошибка",
          onAccepte: modal.closeModal,
        })
      })
  }

  const onResetPasswordClick = (user: User) => {
    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => onAccepteResetPassword(user),
      title: `Сброс пароля для ${user.fullName}`,
      contentText: `Вы точно хотите сбросить пароль для ${user.fullName}?`,
    })
  }

  const onSuccessDeleteUser = (user: User) => {
    const updater = (userList: Array<User> | undefined | void = []) => {
      const newUserList = userList.filter((x) => x.id !== user.id)

      return newUserList
    }

    queryClient.setQueryData(USER_GET_QK, updater)
  }


  return <div className="user-page">
    <ConfirmationDelete<User>
      useDeleteHook={useDeleteUser}
      confirmationText="Вы точно хотите удалить данного прользователя?"
      title={(user) => user.fullName}
      successText="Пользователь успешно удален"
      dataToHook={(user) => user.id}
      onSuccessInformationModalClick={onSuccessDeleteUser}
    >
      <UserList
        users={getUsersHook.data ?? []}
        onUpdateClick={onUpdateClick}
        onCreateClick={onCreateClick}
        onUserBlockClick={onUserBlockClick}
        onResetPasswordClick={onResetPasswordClick}
        canDelete={canDelete}
        canUpdate={canUpdate}
        canCreate
        canBlock={canBlock}
        canResetPassword={canResetPassword}
      />
    </ConfirmationDelete>
    <Outlet />
  </div>
}