import { WarningAmberOutlined } from "@mui/icons-material"
import { memo, useCallback } from "react"
import { Body, Cell, Row } from "../../Common/Components/Table"
import PermissionToggleButtom from "../../Common/Components/PermissionToggleButton/PermissionToggleButton"
import Directory from "../../Domain/Entities/Directory/Directory"
import UserGroupShort from "../../Domain/Entities/UserGroup/UserGroupShort"
import { PERMISSIONS } from "../../Domain/Enums/Permissions"
import classnames from 'classnames';

export type MatrixTableBodyProps = {
  directories: Array<Directory>
  userGroups: Array<UserGroupShort>
  groupedDirectoryUserGroupPermission: Map<number, Map<number, number>>
  onPermissionChange?: (event: any, directory: Directory, userGroup: UserGroupShort, permission: PERMISSIONS) => void
}

const getRow = (
  directory: Directory,
  userGroups: Array<UserGroupShort>,
  groupedDirectoryUserGroupPermission: Map<number, Map<number, number>>,
  onPermissionChange: (directory: any, userGroup: any) => (event: any, value: any) => void,
  indent: number,
  path: string,
) => {
  const value = groupedDirectoryUserGroupPermission.get(directory.id)
  const newIndent = indent > 5 ? 5 : indent
  const className = classnames(`matrix-table__title-indent--${newIndent}`, {
    'matrix-table__title--other-parent': indent > 5
  })
  return (
    <Row key={directory.id}>
      <Cell component="th" title={path.concat(directory.name)}>
        <div className={className} >
          <span>
            {directory.name}
          </span>
          {
            indent > 5 && <span
              className="matrix-table__title-icon"
              title="Данная папка находится в другой папке"
            >
              <WarningAmberOutlined color="warning" />
            </span>
          }

        </div>
      </Cell>
      {
        userGroups.map((userGroup) => <Cell key={userGroup.id}>
          <PermissionToggleButtom
            initialValue={value!.get(userGroup.id)!}
            onChange={onPermissionChange(directory, userGroup)}
          />
        </Cell>)
      }
    </Row >
  )
}

const getRows = (
  directories: Array<Directory>,
  userGroups: Array<UserGroupShort>,
  groupedDirectoryUserGroupPermission: Map<number, Map<number, number>>,
  onPermissionChange: (directory: any, userGroup: any) => (event: any, value: any) => void,
  depth: number,
  path: string,
) => {
  return directories.flatMap((directory) => {
    const children: any = getRows(directory.childrens, userGroups, groupedDirectoryUserGroupPermission, onPermissionChange, depth + 1, path.concat(directory.name, ' / '));

    return [
      getRow(directory, userGroups, groupedDirectoryUserGroupPermission, onPermissionChange, depth, path),
      ...children
    ]
  })
}

function MatrixTableBody(props: MatrixTableBodyProps) {
  const { directories, groupedDirectoryUserGroupPermission, userGroups, onPermissionChange } = props;

  if (directories.length === 0 || userGroups.length === 0) {
    return <h3 className="empty-list">Список пуст</h3>
  }

  const callback = useCallback((directory: any, userGroup: any) => (event: any, value: any) => {
    onPermissionChange?.(event, directory, userGroup, +value)
  }, [])

  return <Body>
    {
      getRows(directories, userGroups, groupedDirectoryUserGroupPermission, callback, 0, '').map((x) => x)
    }
  </Body>
}

export default memo(MatrixTableBody)