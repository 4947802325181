import { useQuery } from "react-query";
import AuthorizationService from "../../../Domain/Services/AuthorizationService/AuthorizationService";
import { AUTHORIZATION_CHECK_INVITE_CODE_QK } from "../Constants";

export default function useCheckInviteCode(
  inviteCode: string,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    [AUTHORIZATION_CHECK_INVITE_CODE_QK, inviteCode],
    () => AuthorizationService.checkInviteCode(inviteCode),
    {
      enabled: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}