import FolderOpen from '@mui/icons-material/FolderOpen';
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import classnames from "classnames";
import { forwardRef, useCallback } from "react";
import Storage from '../../../Domain/Entities/Storage/Storage';

export type UserStorageProps = {
  storage: Storage
  className?: string;
  onClick?: (event: any, storage: Storage) => void;
  isSelected?: boolean,
}

function AdminUserStorage(props: UserStorageProps, ref: any) {
  const { onClick, isSelected, storage } = props;

  const className = classnames(
    'user-storage',
    { "user-storage--selected": isSelected },
    props.className
  )

  const onClickHandler = useCallback((event: any) => {
    if (!storage) return;

    onClick?.(event, storage)
  }, [onClick, storage])

  return <span
    ref={ref}
    className={className}
    onClick={onClickHandler}
  >
    {isSelected ? <FolderOpen /> : <FolderOutlined />}
    <span className="user-storage__content">
      <span className="user-storage__name">
        {storage.nameWithUserName}
      </span>
    </span>
  </span >
}

export default forwardRef(AdminUserStorage);