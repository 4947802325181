import { useQueryClient } from "react-query";
import Loader from "../../../Common/Components/Loader";
import { apiErrorParser } from "../../../Common/utils";
import UserCreateDto from "../../../Domain/Dtos/User/UserCreateDto";
import User from "../../../Domain/Entities/User/User";
import { getRole, ROLES } from "../../../Domain/Enums/Roles";
import { useModal } from "../../../System/hooks/useModal";
import { useModalPage } from "../../ModalPage/ModalPage";
import { USER_GET_QK } from "../../_hooks/Constants";
import useCreateUser from "../../_hooks/User/useCreateUser";
import useGetAllUserGroup from "../../_hooks/UserGroup/useGetAllUserGroup";
import UserCreateForm, { UserCreateFormSubmitData } from "./UserCreateForm";

const initialValuesForm = {
  firstName: undefined,
  lastName: undefined,
  patronymicName: undefined,
  nickname: undefined,
  email: undefined,
  role: getRole(ROLES.USER),
  isBlocked: false,
  userGroups: []
}

export default function UserCreateFormPage() {
  const userGroups = useGetAllUserGroup();
  const queryClient = useQueryClient()
  const createUserHook = useCreateUser();
  const modalPage = useModalPage();
  const modal = useModal();

  const onSubmit = (formValue: UserCreateFormSubmitData, form: any, showErrors: (errors: any) => void) => {
    const updater = (createdUser: User) => (userList: Array<User> | undefined | void = []) => {
      const newUserList = [...userList, createdUser].sort(User.compare)

      return newUserList
    }

    return createUserHook.mutateAsync(UserCreateDto.fromObject({
      ...formValue,
      userGroupIds: formValue.userGroups.map((x) => x.id),
      role: formValue?.role?.role,
    }))
      .then((user) => {
        modal.showInformationModal({
          title: 'Создание пользователя',
          contentText: 'Пользователь создан',
          onAccepte: () => {
            modal.closeModal()
            modalPage.onClose()
            queryClient.setQueryData(USER_GET_QK, updater(user))
          }
        })
      })
      .catch((errors: any) => showErrors(apiErrorParser(errors)))
  }

  if (userGroups.isLoading || userGroups.isFetching) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader />
    </div>
  }

  return <div className="user-form-page">
    <UserCreateForm
      initialValues={initialValuesForm}
      onSubmit={onSubmit}
      userGroups={userGroups.data || []}
      onCancel={modalPage.onClose}
    />
  </div>
}