import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CapsLockDetect from "../../../Common/Components/CapsLockDetect/CapsLockDetect";
import PageNotFound from "../../../Common/Pages/PageNotFound";
import { apiErrorParser } from "../../../Common/utils";
import RegistrationDto from "../../../Domain/Dtos/Authorization/RegistrationDto";
import { useProfile } from "../../../System/hooks/useProfile";
import useCheckInviteCode from "../../_hooks/Authorization/useCheckInviteCode";
import AuthorizationForm from "./RegistrationForm";

export default function RegistrationPage() {
  const profileHook = useProfile();
  const navigateHook = useNavigate();
  const [isInviteCodeValid, setIsInviteCodeValid] = useState(false)
  const searchParams = useSearchParams()
  const inviteCode = searchParams[0].get('key') || ''

  const checkInviteCodeHook = useCheckInviteCode(
    inviteCode,
    () => setIsInviteCodeValid(true),
    () => setIsInviteCodeValid(false)
  );

  useEffect(() => {
    checkInviteCodeHook.refetch()
  }, [])

  if (!checkInviteCodeHook.isSuccess && !checkInviteCodeHook.isError) return <></>

  if (!isInviteCodeValid) return <PageNotFound />

  const onSubmit = (
    formData: RegistrationDto,
    form: any,
    showErrors: (errors: any) => void
  ) => {
    const registrationDto = RegistrationDto.fromObject({
      ...formData,
      inviteCode
    })
    return profileHook.registration(registrationDto)
      .then(() => navigateHook('/', { replace: true }))
      .catch((errors) => showErrors(apiErrorParser(errors)))
  }

  return <div className="registration-page">
    <Card>
      <CardContent>
        <AuthorizationForm onSubmit={onSubmit} />
      </CardContent>
    </Card>
    <CapsLockDetect />
  </div>
}