import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";

export type UserCardListItemCreateProps = {
  onClick?: (event: any) => void
}

export default function UserCardListItemCreate(props: UserCardListItemCreateProps) {
  const { onClick } = props;
  return <Card
    sx={{ display: 'flex', mr: 1, mb: 1 }}
    className="user-card-list-item user-card-list-item-create"
    onClick={onClick}
  >
    <CardContent sx={{ px: 1, py: 1 }} className="user-card-list-item-create__content">
      <AddCircleOutlineOutlined fontSize="large" />
      <div>Добавить пользователя</div>
    </CardContent>
  </Card>
}