import classnames from "classnames"
import { memo } from "react"
import { PERMISSIONS } from "../../Domain/Enums/Permissions"
import DirectoryPermissionRelationListItem, { DirectoryPermissionRelationValue } from "./DirectoryPermissionRelationListItem"

export type DirectoryPermissionRelationListProps = {
  list: Array<DirectoryPermissionRelationValue>
  onChange: (value: DirectoryPermissionRelationValue, newPermission: PERMISSIONS) => void
  className?: string,
  striped?: boolean,
}

function DirectoryPermissionRelationList(props: DirectoryPermissionRelationListProps) {
  const { list, onChange, striped } = props;
  const className = classnames('directory-permission-relation-list',
    props.className,
    {
      "directory-permission-relation-list--striped": striped,
    }
  )

  return <div className={className}>
    {list.map((x) => <DirectoryPermissionRelationListItem key={x.directory.id} value={x} onChange={onChange} />)}
  </div>
}

export default memo(DirectoryPermissionRelationList)