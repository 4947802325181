import Stack from "@mui/material/Stack";
import { Field, Form, FormRenderProps } from "react-final-form";
import Button from "../../../Common/Components/Button";
import PasswordField from "../../../Common/Components/PasswordField";
import FormErrorMessage from "../../../Common/Components/ReactFinalForm/FormErrorMessage";
import FormFieldWrapper from "../../../Common/Components/ReactFinalForm/FormFieldWrapper";
import { isRequired } from "../../../Common/validators";

export type NewPasswordFormProps = FormRenderProps & {
  disabled?: boolean;
}

export type NewPasswordFormSubmitData = {
  newPassword: string,
  confirmPassword: string,
}

const PasswordFieldWrapped = FormFieldWrapper(PasswordField)

function NewPasswordForm(props: NewPasswordFormProps) {
  const { submitting, disabled, handleSubmit } = props;
  return <form onSubmit={handleSubmit} className="authorization-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Новый пароль"
        name="newPassword"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Подтвердите пароль"
        name="confirmPassword"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Button
        isLoading={submitting}
        type="submit"
        variant="contained"
        disabled={disabled || submitting}
      >
        Сохранить
      </Button>
      <FormErrorMessage name="common" />
    </Stack>
  </form >
}

const DefaultNewPasswordForm = (props: any) => <Form {...props} render={NewPasswordForm} />

export default DefaultNewPasswordForm;