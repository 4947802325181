import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useProfile } from "../../System/hooks/useProfile";
import Loader from "../Components/Loader";

export default function IsAuthorized(props: { children: React.ReactElement }) {
  const { isAuthenticated, isAuthenticating, isBlocked } = useProfile();
  const location = useLocation();

  if (isAuthenticating) return <div className="is-authorized__loader"><Loader /></div>

  if (isBlocked) {
    return <Navigate to="/access-denied" />
  }

  return isAuthenticated ? props.children : <Navigate to="/authorization" state={{ prevLocation: location.pathname }} />
}