import { useState, useCallback } from "react";
import { QueryClient, useQueryClient } from "react-query";
import ConfirmationDelete from "../../Common/Components/ConfirmationDelete/ConfirmationDelete";
import Sidebar from "../../Common/Components/Sidebar/Sidebar";
import UserGroupCreateDto from "../../Domain/Dtos/UserGroup/UserGroupCreateDto";
import UserGroupMatrixUpdateDto from "../../Domain/Dtos/UserGroupMatrix/UserGroupMatrixUpdateDto";
import UserGroupUpdateDto from "../../Domain/Dtos/UserGroup/UserGroupUpdateDto";
import DirectoryShort from "../../Domain/Entities/Directory/DirectoryShort";
import UserShort from "../../Domain/Entities/User/UserShort";
import UserGroup from "../../Domain/Entities/UserGroup/UserGroup";
import UserGroupEntity from "../../Domain/Entities/UserGroup/UserGroup";
import UserGroupDirectoryPermissionRelation from "../../Domain/Entities/UserGroupRelation/UserGroupDirectoryPermissionRelation";
import { PERMISSIONS } from "../../Domain/Enums/Permissions";
import { USER_GROUP_GET_QK } from "../_hooks/Constants";
import useGetAllUserShort from "../_hooks/User/useGetAllUsersShort";
import useCreateUserGroup from "../_hooks/UserGroup/useCreateUserGroup";
import useDeleteUserGroup from "../_hooks/UserGroup/useDeleteUserGroup";
import useGetAllUserGroup from "../_hooks/UserGroup/useGetAllUserGroup";
import useUpdateUserGroup from "../_hooks/UserGroup/useUpdateUserGroup";
import useUpdateUserGroupMatrix from "../_hooks/UserGroupMatrix/useUpdateUserGroupMatrix";
import UserGroupDetails from "./UserGroupDetails";
import UserGroupList from "./UserGroupList";
import { useModal } from "../../System/hooks/useModal";
import { apiErrorParser } from "../../Common/utils";
import useGetAllDirectory from "../_hooks/Directory/useGetAllDirectory";

const updateUserGroupPermissionsInCache = (cache: any[], selectedUserGroup: UserGroup | undefined, directory: DirectoryShort, newPermission: PERMISSIONS) => {
  const newData = cache;
  const userGroupIndex = newData.findIndex((x) => x.id === selectedUserGroup?.id)
  const userGroup = newData[userGroupIndex]
  const relationIndex = userGroup?.relations?.findIndex((x: any) => x.directoryId === directory.id)

  let newUserGroup: UserGroup | undefined = undefined;

  if (relationIndex === -1) {
    const relations = [...userGroup.relations, new UserGroupDirectoryPermissionRelation(directory.id, newPermission)]
    newUserGroup = UserGroup.fromObject({ ...userGroup, relations })
  } else {
    userGroup.relations[relationIndex] = new UserGroupDirectoryPermissionRelation(directory.id, newPermission)
    newUserGroup = UserGroup.fromObject({ ...userGroup })
  }

  newData[userGroupIndex] = newUserGroup
  return newData;
}

const updateUserGroupPermittedUsersInCache = (cache: UserGroup[], selectedUserGroup: UserGroup | undefined, users: Array<UserShort>) => {
  const newData = cache;
  const userGroupIndex = newData.findIndex((x) => x.id === selectedUserGroup?.id)
  const userGroup = newData[userGroupIndex]

  const newUserGroup = UserGroup.fromObject({ ...userGroup, userIds: users.map((x) => x.id) })

  newData[userGroupIndex] = newUserGroup
  return newData;
}

const createOrUpdateUserGroupNameInCache = (queryClient: QueryClient, cache: UserGroup[], userGroup: UserGroup) => {
  const newData = cache;
  const userGroupIndex = newData.findIndex((x) => x.id === userGroup.id)

  if (userGroupIndex === -1) {
    newData.push(userGroup)
    newData.sort(UserGroup.compare)
    return queryClient.setQueryData(USER_GROUP_GET_QK, newData)
  }

  newData[userGroupIndex] = userGroup
  newData.sort(UserGroup.compare)
  return queryClient.setQueryData(USER_GROUP_GET_QK, newData);
}

export default function UserGroupPage() {
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroupEntity | undefined>(undefined);
  const queryClient = useQueryClient()
  const getUserGroupsHook = useGetAllUserGroup((userGroups: Array<UserGroup>) => {
    if (userGroups.length > 0 && !selectedUserGroup) setSelectedUserGroup(userGroups[0])
  });
  const getUsersShortHook = useGetAllUserShort()
  const createUserGroupHook = useCreateUserGroup();
  const updateUserGroupHook = useUpdateUserGroup();
  const getAllDirectoryHook = useGetAllDirectory();
  const updateUserGroupMatrixHook = useUpdateUserGroupMatrix();
  const modal = useModal();

  const setSelectedUserGroupCallback = useCallback((event: any, userGroup: UserGroupEntity) => {
    setSelectedUserGroup(userGroup)
  }, [setSelectedUserGroup])

  const onCreateUserGroup = (name: string) => {
    return createUserGroupHook
      .mutateAsync(new UserGroupCreateDto(name, [], []))
      .then((x) => createOrUpdateUserGroupNameInCache(queryClient, getUserGroupsHook.data ?? [], x))
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Создание группы пользователей',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onUpdateUserGroupName = (userGroup: UserGroup, newName: string) => {
    return updateUserGroupHook
      .mutateAsync(new UserGroupUpdateDto(userGroup.id, newName, userGroup.userIds))
      .then((x) => {
        const newData = createOrUpdateUserGroupNameInCache(queryClient, getUserGroupsHook.data ?? [], x)
        return [x, newData] as const
      })
      .then(([x, newData]) => setSelectedUserGroup(newData.find((newD) => newD.id === x?.id)))
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Переименовывание группы пользователей',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onDirectoryPermissionChange = useCallback((directory: DirectoryShort, newPermission: PERMISSIONS) => {
    const newData = updateUserGroupPermissionsInCache(getUserGroupsHook.data ?? [], selectedUserGroup, directory, newPermission)
    queryClient.setQueryData(USER_GROUP_GET_QK, newData)

    if (!selectedUserGroup) throw new Error('selectedUserGroup not found')

    updateUserGroupMatrixHook
      .mutateAsync(new UserGroupMatrixUpdateDto(selectedUserGroup.id, directory.id, newPermission))
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Установка прав',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }, [selectedUserGroup, modal])

  const onSuccessInformationModalClick = useCallback((userGroup: UserGroup) => {
    getUserGroupsHook.refetch()
      .then((x) => {
        if (!x.data || x.data?.length < 1) return
        if (x.data?.find((x) => x.id === userGroup.id)) return
        setSelectedUserGroup(x.data[0])
      })
  }, [])

  const onPermittedUsersChange = useCallback((users: Array<UserShort>) => {
    if (!selectedUserGroup) throw new Error('selectedUserGroup not found')
    const newData = updateUserGroupPermittedUsersInCache(getUserGroupsHook.data ?? [], selectedUserGroup, users)
    queryClient.setQueryData(USER_GROUP_GET_QK, newData)

    updateUserGroupHook.mutateAsync(new UserGroupUpdateDto(selectedUserGroup?.id, selectedUserGroup?.name, users.map((x) => x.id)))
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Добавление пользователей в группу',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }, [selectedUserGroup, modal])

  return <div className="user-group-page" style={{ display: 'flex' }}>
    <div className="user-group-page__sidebar">
      <Sidebar>
        <ConfirmationDelete<UserGroup>
          useDeleteHook={useDeleteUserGroup}
          confirmationText="Вы точно хотите удалить данную группу прользователей?"
          title="name"
          successText="Группа пользователей успешно удалена"
          dataToHook="id"
          onSuccessInformationModalClick={onSuccessInformationModalClick}
        >
          <UserGroupList
            userGroups={getUserGroupsHook.data ?? []}
            selectedUserGroup={selectedUserGroup}
            onSelectedUserGroup={setSelectedUserGroupCallback}
            canCreateDirectory
            canUpdateDirectory
            canDeleteDirectory
            onCreate={onCreateUserGroup}
            onUpdate={onUpdateUserGroupName}
          />
        </ConfirmationDelete>
      </Sidebar>
    </div>
    <div className="user-group-page__content">
      {selectedUserGroup && (
        <UserGroupDetails
          directories={getAllDirectoryHook.data ?? []}
          userGroup={selectedUserGroup}
          canUpdateDirectory
          canDeleteDirectory
          users={getUsersShortHook.data ?? []}
          onDirectoryPermissionChange={onDirectoryPermissionChange}
          onPermittedUsersChange={onPermittedUsersChange}
        />
      )}
    </div>
  </div >
}