import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom"
import Loader from "../../../Common/Components/Loader";
import { apiErrorParser } from "../../../Common/utils";
import UserUpdateDto from "../../../Domain/Dtos/User/UserUpdateDto";
import User from "../../../Domain/Entities/User/User";
import { useModalPage } from "../../ModalPage/ModalPage";
import { USER_GET_QK } from "../../_hooks/Constants";
import useUpdateUser from "../../_hooks/User/useUpdateUser";
import useGetAllUserGroup from "../../_hooks/UserGroup/useGetAllUserGroup";
import UserUpdateForm, { UserUpdateFormSubmitData } from "./UserUpdateForm"

export default function UserUpdateFormPage() {
  const { state } = useLocation();
  const user = (state as any).user
  const canBlock = (state as any).canBlock
  const userGroups = useGetAllUserGroup();
  const queryClient = useQueryClient()
  const updateUserHook = useUpdateUser();
  const modalPage = useModalPage();

  const onSubmit = (formValue: UserUpdateFormSubmitData, form: any, showErrors: (errors: any) => void) => {
    const updater = (updatedUser: User) => (userList: Array<User> | undefined | void = []) => {
      const newUserList = [...userList]
      const index = newUserList.findIndex((x) => x.id === updatedUser.id)

      if (index === -1) return newUserList

      newUserList[index] = updatedUser
      return newUserList
    }

    return updateUserHook.mutateAsync(UserUpdateDto.fromObject({
      ...formValue,
      id: user.id,
      userGroupIds: formValue.userGroups.map((x) => x.id),
      role: formValue?.role?.role,
    }))
      .then((user) => queryClient.setQueryData(USER_GET_QK, updater(user)))
      .then(() => modalPage.onClose())
      .catch((errors: any) => showErrors(apiErrorParser(errors)))
  }

  if (userGroups.isLoading || userGroups.isFetching) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader />
    </div>
  }

  return <div className="user-form-page">
    <UserUpdateForm
      initialValues={user}
      onSubmit={onSubmit}
      userGroups={userGroups.data || []}
      onCancel={modalPage.onClose}
      canBlock={canBlock}
    />
  </div>
}