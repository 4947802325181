import DeleteOutlined from "@mui/icons-material/DeleteOutlined"
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Copy from "../../Common/Components/Copy/Copy"
import InviteCodeEntity from "../../Domain/Entities/InviteCode/InviteCode"
import classnames from "classnames";
import DataTable from "../../Common/Components/Table/DataTable"

export type InviteCodeListProps = {
  onChange?: (value: InviteCodeEntity) => void,
  onDeleteButtonClick?: (value: InviteCodeEntity) => void,
  onEditButtonClick?: (value: InviteCodeEntity) => void,
  list: Array<InviteCodeEntity>,
  onInviteCodeStopClick?: (event: any, user: InviteCodeEntity) => void
}

export default function InviteCodeList(props: InviteCodeListProps) {
  const { onDeleteButtonClick, onEditButtonClick, onInviteCodeStopClick, list } = props;

  return <div className="invite-code-list">
    <DataTable
      headLines={[
        {
          id: 1,
          cells: [
            {
              id: 1,
              value: 'Название',
              renderValue: 'Название',
              config: {
                searchable: true,
                rowSpan: 2,
              }
            },
            {
              id: 2,
              value: 'Код',
              renderValue: 'Код',
              config: {
                searchable: true,
                rowSpan: 2,
              }
            },
            {
              id: 3,
              value: 'Приостановлен',
              renderValue: 'Приостановлен',
              config: {
                rowSpan: 2,
              }
            },
            {
              id: 8,
              value: 'Действителен до',
              renderValue: 'Действителен до',
              config: {
                colSpan: 2,
              }
            },
            {
              id: 9,
              value: 'Назначаются',
              renderValue: 'Назначаются',
              config: {
                colSpan: 2,
              }
            },
            {
              id: 10,
              value: '',
              renderValue: '',
              config: {
                rowSpan: 2,
                width: 80
              }
            },
          ]
        },
        {
          id: 2,
          cells: [
            {
              id: 4,
              value: 'Дата',
              renderValue: 'Дата',
              config: {
                searchable: true,
                width: 40,
                className: "invite-code-list__alive-before",
              }
            },
            {
              id: 5,
              value: 'Время',
              renderValue: 'Время',
              config: {
                searchable: true,
              }
            },
            {
              id: 6,
              value: 'Роль',
              renderValue: 'Роль',
              config: {
                searchable: true,
              }
            },
            {
              id: 7,
              value: 'Группы пользователей',
              renderValue: 'Группы пользователей',
              config: {
                searchable: true,
              }
            },
          ]
        }
      ]}
      bodyLines={
        list.map((inviteCode) => ({
          id: inviteCode.id,
          config: {
            className: classnames({
              'invite-code--is-active': inviteCode.isActive
            }),
          },
          cells: [
            {
              id: 1,
              value: inviteCode.name,
              renderValue: inviteCode.name,
            },
            {
              id: 2,
              value: inviteCode.name,
              renderValue: <Copy value={`${window.location.origin}/registration?key=${inviteCode.code}`}>
                {`${window.location.origin}/registration?key=${inviteCode.code}`}
              </Copy>,
              config: {
                className: "text-align-center"
              }
            },
            {
              id: 3,
              value: inviteCode.name,
              renderValue: <Checkbox
                onChange={(event, checked) => onInviteCodeStopClick?.(checked, inviteCode)}
                checked={inviteCode.isStopped}
              />,
              config: {
                className: "text-align-center"
              }
            },
            {
              id: 4,
              value: inviteCode.aliveBeforeDate,
              renderValue: inviteCode.aliveBeforeDate,
              config: {
                className: "text-align-center"
              }
            },
            {
              id: 5,
              value: inviteCode.aliveBeforeTime,
              renderValue: inviteCode.aliveBeforeTime,
              config: {
                className: "text-align-center"
              }
            },
            {
              id: 6,
              value: inviteCode.role.name,
              renderValue: inviteCode.role.name,
              config: {
                className: "text-align-center"
              }
            },
            {
              id: 7,
              value: inviteCode.userGroups.map((x) => x.name).join(', '),
              renderValue: inviteCode.userGroups.map((x) => x.name).join(', '),
            },
            {
              id: 8,
              value: undefined,
              config: {
                width: 80,
              },
              renderValue: (Boolean(onEditButtonClick) || Boolean(onDeleteButtonClick)) && <div style={{ display: 'flex' }}>
                <IconButton title="Изменить инвайт-код" onClick={() => onEditButtonClick?.(inviteCode)}>
                  <ModeEditOutlineOutlined />
                </IconButton>
                <IconButton title="Удалить инвайт-код" onClick={() => onDeleteButtonClick?.(inviteCode)}>
                  <DeleteOutlined color="error" />
                </IconButton>
              </div>,
            },
          ]
        }))
      }
    />
  </div >
}
