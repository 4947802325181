import { useQuery } from "react-query";
import UserGroupService from "../../../Domain/Services/UserGroupService/UserGroupService";
import { USER_GROUP_GET_QK } from "../Constants";

export default function useGetAllUserGroup(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    USER_GROUP_GET_QK,
    () => UserGroupService.getAll(),
    {
      initialData: [],
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}