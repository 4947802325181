import { useMutation } from "react-query";
import InviteCodeService from "../../../Domain/Services/InviteCodeService/InviteCodeService";

export default function useDeleteInviteCode(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (id: number) => InviteCodeService.delete(id),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}