import { useEffect, cloneElement, useCallback, ReactElement } from "react";
import { useModal } from "../../../System/hooks/useModal";
import { apiErrorParser, primitiveOrFunction } from "../../utils";

export type ConfirmationDeleteProps<T> = {
  children: ReactElement,
  useDeleteHook: any,
  title: ((value: T) => string) | string,
  confirmationText: string,
  successText: string,
  errorTextFallback?: string,
  dataToHook: ((value: T) => any) | string
  onDeleteSuccess?: (value: T) => void
  onDeleteFailure?: (value: T, errors: Array<any>) => void
  onSuccessInformationModalClick?: (value: T) => void
  propsName?: string,
}
export default function ConfirmationDelete<T>(props: ConfirmationDeleteProps<T>) {
  const {
    children,
    useDeleteHook,
    title,
    errorTextFallback = 'Произошла ошибка',
    confirmationText,
    successText,
    dataToHook,
    onDeleteFailure,
    onDeleteSuccess,
    onSuccessInformationModalClick,
    propsName = 'onDeleteClick'
  } = props;
  const modal = useModal();
  const deleteHook = useDeleteHook();

  useEffect(() => {
    modal.changeConfirmationProps({
      isLoading: deleteHook.isLoading,
      disabled: deleteHook.isLoading,
    })
  }, [deleteHook.isLoading])

  const onAccepteDelete = useCallback((value: T) => {
    deleteHook.mutateAsync(primitiveOrFunction(dataToHook, value))
      .then(() => modal.closeModal())
      .then(() => {
        modal.showInformationModal({
          title: primitiveOrFunction(title, value),
          contentText: successText,
          onAccepte: () => {
            modal.closeModal()
            onSuccessInformationModalClick?.(value)
          }
        })
      })
      .then(() => onDeleteSuccess?.(value))
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: primitiveOrFunction(title, value),
          contentText: apiErrorParser(errors).common || errorTextFallback,
          onAccepte: modal.closeModal,
        })
        onDeleteFailure?.(value, errors)
      })
  }, []);

  const onDeleteClick = useCallback((value: T) => {
    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => onAccepteDelete(value),
      title: primitiveOrFunction(title, value),
      contentText: confirmationText,
    })
  }, [])

  return cloneElement(children, { [propsName]: onDeleteClick })
}