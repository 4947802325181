import { useQuery } from "react-query";
import PasscardFavoritesService from "../../../Domain/Services/PasscardFavoritesService/PasscardFavoritesService";
import { PASSCARD_FAVORITES_GET_ALL_QK } from "../Constants";

export default function useGetAllPasscardFavorites(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    PASSCARD_FAVORITES_GET_ALL_QK,
    () => PasscardFavoritesService.getAll(),
    {
      initialData: [],
      retry: false,
      placeholderData: [],
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}