import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress/CircularProgress";

export type LoaderProps = CircularProgressProps;

export default function Loader(props: LoaderProps) {
  const {
    disableShrink = true,
    ...rest
  } = props;
  return <CircularProgress className="loader" style={{ alignSelf: 'center' }} disableShrink={disableShrink} {...rest} />
}
