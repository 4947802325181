import { useProfile } from "../../System/hooks/useProfile";

export default function Welcome() {
  const profile = useProfile();

  return <div className="welcome">
    <h4>Добро пожаловать в Passman</h4>
    <p>Данный сервис является корпоративным менеджером паролей</p>
    {!profile.profile?.isAdministator && <p>Чтобы иметь возможность работать с паролями, обратитесь к администатору системы для получения доступа</p>}
  </div>
}