import moment, { HTML5_FMT } from "moment";
import { HUMAN_DATE_FORMAT } from "../../../Common/const";
import { getRole, Role } from "../../Enums/Roles";
import UserGroupShort from "../UserGroup/UserGroupShort";

export default class InviteCode {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly code: string,
    readonly isStopped: boolean,
    readonly aliveBefore: string,
    readonly role: Role,
    readonly userGroups: Array<UserGroupShort>,
  ) { }

  get isActive() {
    return !this.isStopped && moment(this.aliveBefore).utc().isAfter(moment().utc())
  }

  static fromApi(obj: any) {
    return new InviteCode(
      obj.id,
      obj.name,
      obj.code,
      obj.isStopped,
      obj.aliveBefore,
      getRole(obj.role),
      obj.userGroups.map(UserGroupShort.fromApi)
    )
  }

  static fromObject(obj: any) {
    return new InviteCode(
      obj.id,
      obj.name,
      obj.code,
      obj.isStopped,
      obj.aliveBefore,
      getRole(obj.role),
      obj.userGroups.map(UserGroupShort.fromApi)
    )
  }

  get aliveBeforeDate() {
    return moment(this.aliveBefore).format(HUMAN_DATE_FORMAT.DATE)
  }

  get aliveBeforeTime() {
    return moment(this.aliveBefore).format(HTML5_FMT.TIME)
  }
}