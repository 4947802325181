import { useQuery } from "react-query";
import UserGroupMatrixService from "../../../Domain/Services/UserGroupMatrixService/UserGroupMatrixService";
import { USER_GROUP_MATRIX_GET_GROUPED_BY_DIRECTOY_QK } from "../Constants";

export default function useGetAllGroupedUserGroupMatrixByDirectory(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    USER_GROUP_MATRIX_GET_GROUPED_BY_DIRECTOY_QK,
    () => UserGroupMatrixService.getAllGroupedByDirectory(),
    {
      initialData: [[], [], new Map()],
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}