import { Children, useEffect, useRef, useState } from "react";
import DirectoryEntity from "../../../Domain/Entities/Directory/Directory";
import Directory from "../Directory";
import ChevronRight from '@mui/icons-material/ChevronRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import classnames from 'classnames';
import DirectoryCreate from "../DirectoryCreate";
import DirectoryUpdate from "../DirectoryUpdate";
import { useModal } from "../../../System/hooks/useModal";
import useDeleteDirectory from "../../_hooks/Directory/useDeleteDirectory";
import { apiErrorParser } from "../../../Common/utils";
import Droppable from "../../../Common/Components/DragAndDrop/Droppable";

export type DirectoryProps = {
  directory: DirectoryEntity;
  children?: React.ReactNode;
  onSelect?: (event: any, directory: DirectoryEntity) => void;
  isSelected?: boolean,
  canCreate?: boolean,
  canUpdate?: boolean,
  canDelete?: boolean,
  onReloadTree?: () => Promise<any>;
  onMouseDown?: (event: any) => void;
  className?: string,
}

enum OPERATION_TYPE {
  VIEW,
  CREATE,
  UPDATE
}

export default function DirectoryTreeItem(props: DirectoryProps) {
  const { onSelect, children, directory, isSelected, canCreate, onReloadTree, canUpdate, canDelete, onMouseDown } = props;
  const [open, setOpen] = useState(false);
  const [operation, setOperation] = useState(OPERATION_TYPE.VIEW);
  const ref = useRef<HTMLDivElement | null>(null);
  const deleteDirectoryHook = useDeleteDirectory();
  const modal = useModal();
  const childrenCount = Children.count(children)
  const className = classnames('directory-tree-item__content', {
    'directory-tree-item__content--no-children': childrenCount === 0,
  });
  const containerClassName = classnames('directory-tree-item', props.className)

  const onCreateClick = () => {
    setOperation(OPERATION_TYPE.CREATE)
  }

  const onUpdateClick = () => {
    setOperation(OPERATION_TYPE.UPDATE)
  }

  useEffect(() => {
    modal.changeConfirmationProps({
      isLoading: deleteDirectoryHook.isLoading,
      disabled: deleteDirectoryHook.isLoading,
    })
  }, [deleteDirectoryHook.isLoading])

  const onAccepteDelete = (row: any) => {
    deleteDirectoryHook.mutateAsync(row.id)
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: row.name,
          contentText: 'Папка успешно удалена',
          onAccepte: modal.closeModal,
        })
      })
      .then(() => {
        onReloadTree?.().then((x) => {
          if (!isSelected || !x.data || x.data.length < 1) return
          onSelect?.(undefined, x.data[0])
        })
      })
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: row.name,
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onDeleteClick = () => {
    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => onAccepteDelete(directory),
      title: directory.name,
      contentText: 'Все пароли в папке будут удалены! Удалить данную папку? ',
    });
  }

  const scrollIntoView = async () => {
    const sidebar = document.querySelector(".sidebar");
    if (!ref.current || !sidebar) return

    const boundRect = (sidebar as HTMLElement).getBoundingClientRect();
    const refBoundRect = ref.current.getBoundingClientRect();

    if (!(refBoundRect.right > boundRect.right)) return;

    ref.current?.scrollIntoView({ behavior: "smooth" })
  }

  return <div
    className={containerClassName}
    onMouseDown={onMouseDown}
    data-directoryid={directory.id}
  >
    <Droppable dropKey="directory-tree passcard" key={directory.id} dropBeforeOrAfter={!directory.parentId}>
      <div className={className} data-directoryid={directory.id}>
        {childrenCount !== 0 && (
          <div className="directory-tree-item__toggle-button" onClick={() => setOpen(x => !x)}>
            {open ? <KeyboardArrowDown /> : <ChevronRight />}
          </div>
        )}
        {(operation === OPERATION_TYPE.VIEW || operation === OPERATION_TYPE.CREATE) && (
          <Directory
            ref={ref}
            directory={directory}
            onClick={onSelect}
            isSelected={isSelected}
            canCreate={canCreate}
            canUpdate={canUpdate}
            canDelete={canDelete}
            onCreateClick={onCreateClick}
            onUpdateClick={onUpdateClick}
            onDeleteClick={onDeleteClick}
            onMouseDown={onMouseDown}
            onMouseEnter={scrollIntoView}
          />
        )}
        {operation === OPERATION_TYPE.UPDATE && (
          <DirectoryUpdate
            directory={directory}
            onCancel={() => setOperation(OPERATION_TYPE.VIEW)}
            onUpdated={() => {
              setOperation(OPERATION_TYPE.VIEW)
              onReloadTree?.();
            }}
          />
        )}
      </div>
    </Droppable>
    <div className="directory-tree-item__children">
      {open && props.children}
      {operation === OPERATION_TYPE.CREATE && (
        <DirectoryCreate
          parent={directory}
          onCancel={() => setOperation(OPERATION_TYPE.VIEW)}
          onCreated={() => {
            setOperation(OPERATION_TYPE.VIEW)
            setOpen(true);
            onReloadTree?.();
          }}
        />
      )}
    </div>
  </div >
}