import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useState } from "react";
import Button from "../../Common/Components/Button";
import SelectList from "../../Common/Components/SelectList/SelectList";
import { apiErrorParser } from "../../Common/utils";
import ImportPasscardsService from "../../Domain/Services/ImportPasscardsService/ImportPasscardsService";
import { useModal } from "../../System/hooks/useModal";

type System = {
  id: number
  name: string
  supportedFileTypes: string
  importService: (file: File) => Promise<void>
  modalText: (file: File) => string
};

const systemList: Array<System> = [
  {
    id: 0,
    name: 'Не выбрано',
    supportedFileTypes: ".csv,.xlsx,.xls",
    importService: (file: File) => ImportPasscardsService.importPasscards(file),
    modalText: (file: File) => `Пароли успешно импортированы.  Вы их найдете в
      папке с названием ${file.name} и временем импортирования`
  },
  {
    id: 1,
    name: 'Passman',
    supportedFileTypes: ".json",
    importService: (file: File) => ImportPasscardsService.importPasscardsFromPassmanWithHierarchy(file),
    modalText: () => `Пароли из системы Passman успешно импортированы`
  },
  {
    id: 2,
    name: 'Bitwarden',
    supportedFileTypes: ".json",
    importService: (file: File) => ImportPasscardsService.importPasscardsFromBitwardenWithHierarchy(file),
    modalText: () => `Пароли из системы Bitwarden успешно импортированы`
  },
]

export default function ImportPage() {
  const [file, setFile] = useState<File | null>();
  const [selectedSystem, setSelectedSystem] = useState<System>(systemList[0]);
  const [isLoading, setIsLoading] = useState(false);
  const modal = useModal();

  const onImportButtonClick = useCallback(() => {
    if (!file) return;

    setIsLoading(true)

    return selectedSystem.importService(file)
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Импорт паролей',
          contentText: selectedSystem.modalText(file),
          onAccepte: () => {
            modal.closeModal()
          }
        })
      })
      .catch((errors: any) => {
        modal.showInformationModal({
          title: 'Импорт паролей',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
      .finally(() => setIsLoading(false));
  }, [file, modal, selectedSystem])

  const onChange = useCallback((event: any) => {
    const files = event.target.files;

    if (files?.length === 0) setFile(null);

    setFile(files[0])
  }, [])

  const onSelectSystem = useCallback((system: any) => {
    setSelectedSystem(system)
    setSelectedSystem(system)
    setFile(null)
  }, [])

  return <div className="import-page">
    <div className="import-page__title">
      <h3>Импорт паролей</h3>
    </div>
    <div className="import-page__content">
      <FormControl sx={{ mb: 1.25 }} >
        <SelectList
          selectList={systemList}
          value={selectedSystem}
          onChange={onSelectSystem}
          label="Из системы"
          disableClearable
        />
      </FormControl>
      <TextField
        key={selectedSystem.id}
        type="file"
        inputProps={{
          accept: selectedSystem.supportedFileTypes
        }}
        onChange={onChange}
      />
      <Button
        variant="contained"
        onClick={onImportButtonClick}
        isLoading={isLoading}
        disabled={!file}
      >
        Импортировать
      </Button>
    </div>
  </div >
}