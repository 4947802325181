import { compareByAlphabetically } from "../../../Common/utils";
import UserGroupDirectoryPermissionRelation from "../UserGroupRelation/UserGroupDirectoryPermissionRelation";

export default class UserGroupMatrix {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly relations: Array<UserGroupDirectoryPermissionRelation>,
  ) { }

  static fromApi(obj: UserGroupMatrix) {
    return new UserGroupMatrix(
      obj.id,
      obj.name,
      obj.relations.map(UserGroupDirectoryPermissionRelation.fromApi),
    )
  }
}

export const compare = (a: UserGroupMatrix, b: UserGroupMatrix) => compareByAlphabetically(a, b, 'name')