import { useMutation } from "react-query";
import DirectoryMovingDto from "../../../Domain/Dtos/Directory/DirectoryMovingDto";
import DirectoryService from "../../../Domain/Services/DirectoryService/DirectoryService";

export default function useMoveDirectory(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (data: DirectoryMovingDto) => DirectoryService
      .move(data),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}