import { useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";
import Loader from '../../../Common/Components/Loader';
import { apiErrorParser } from "../../../Common/utils";
import PasscardCreateDto from "../../../Domain/Dtos/Passcard/PasscardCreateDto";
import Directory from '../../../Domain/Entities/Directory/Directory';
import DirectoryShort from '../../../Domain/Entities/Directory/DirectoryShort';
import Storage from '../../../Domain/Entities/Storage/Storage';
import StorageShort from '../../../Domain/Entities/Storage/StorageShort';
import { PERMISSIONS } from '../../../Domain/Enums/Permissions';
import { useProfile } from '../../../System/hooks/useProfile';
import { useModalPage } from "../../ModalPage/ModalPage";
import useGetAllShortDirectory from '../../_hooks/Directory/useGetAllShortDirectory';
import useCreatePasscard from "../../_hooks/Passcard/useCreatePasscard";
import useGetAllPasscard from '../../_hooks/Passcard/useGetAllPasscard';
import useGetAllStorageShort from '../../_hooks/Storage/useGetAllStorageShort';
import CreatePasscardForm from "./CreatePasscardForm";

type FormValue = {
  name: string,
  url: string,
  login: string,
  password: string,
  description: string,
  directories: Array<DirectoryShort | StorageShort>,
}

const defaultInitialValues = {
  name: '',
  url: '',
  login: '',
  password: '',
  description: '',
  directories: [],
}

export default function CreatePasscardPage() {
  const { state } = useLocation();
  const { profile } = useProfile();

  const selectedDirectory: Directory | undefined = (state as any).selectedDirectory
  const selectStorage: Storage | undefined = (state as any).selectStorage
  const [defaultFormState, setDefaultFormState] = useState<FormValue>(defaultInitialValues)

  const setDirectoriesToDefaultFormState = (directories: Array<DirectoryShort | StorageShort>) => {
    let selectedDirectoryShort: DirectoryShort | StorageShort | undefined = undefined

    if (selectedDirectory) {
      selectedDirectoryShort = directories?.filter((x) => x instanceof DirectoryShort).find((x) => x.id === selectedDirectory?.id)
    }

    if (selectStorage) {
      selectedDirectoryShort = directories?.filter((x) => x instanceof StorageShort).find((x) => x.id === selectStorage?.id)
    }

    setDefaultFormState({
      ...defaultFormState,
      directories: [
        ...defaultFormState.directories,
        ...(selectedDirectoryShort ? [selectedDirectoryShort] : [])
      ]
    })
  }

  const directoryShorts = useGetAllShortDirectory(setDirectoriesToDefaultFormState);
  const storageShorts = useGetAllStorageShort(setDirectoriesToDefaultFormState);

  const directories = useMemo(() => {
    return [
      ...(directoryShorts.data ?? []).filter((x) => x.permission === PERMISSIONS.FULL_ACCESS),
      ...(storageShorts.data ?? [])
    ]
  }, [directoryShorts.data, storageShorts.data])

  const modalPage = useModalPage();
  // ToDo: hack. Переделать на изменение кэша useGetAllPasscard
  const createPasscard = useCreatePasscard(useGetAllPasscard(selectedDirectory?.id, '', selectStorage?.id).refetch);

  function onSubmit(
    formValues: FormValue,
    form: any,
    showErrors: (errors: any) => void
  ) {
    const movingDto: PasscardCreateDto = PasscardCreateDto.fromObject({
      ...formValues,
      parentIds: formValues.directories.filter((x) => x instanceof DirectoryShort).map((x) => x.id),
      storageIds: formValues.directories.filter((x) => x instanceof StorageShort).map((x) => x.id),
    });

    return createPasscard.mutateAsync(movingDto)
      .then(() => modalPage.onClose())
      .catch((errors) => showErrors(apiErrorParser(errors)));
  }

  if (directoryShorts.isLoading || directoryShorts.isFetching || storageShorts.isLoading || storageShorts.isFetching) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}><Loader /></div>
  }

  return <CreatePasscardForm
    initialValues={defaultFormState}
    onSubmit={onSubmit}
    onCancel={modalPage.onClose}
    directories={directories}
    isAdministator={profile?.isAdministator}
  />
}