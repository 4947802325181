import FolderOpen from '@mui/icons-material/FolderOpen';
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import classnames from "classnames";
import { forwardRef, useCallback } from "react";
import HelpIcon from "../../Common/Components/HelpIcon/HelpIcon";
import Loader from "../../Common/Components/Loader";
import Storage from "../../Domain/Entities/Storage/Storage";
import useGetUserStorage from "../_hooks/Storage/useGetUserStorage";

export type UserStorageProps = {
  className?: string;
  onClick?: (event: any, storage: Storage) => void;
  isSelected?: boolean,
  showHelp?: boolean,
}

function UserStorage(props: UserStorageProps, ref: any) {
  const { onClick, isSelected, showHelp = true } = props;

  const className = classnames(
    'user-storage',
    { "user-storage--selected": isSelected },
    props.className
  )

  const storage = useGetUserStorage();

  const onClickHandler = useCallback((event: any) => {
    if (!storage.data) return;

    onClick?.(event, storage.data)
  }, [onClick, storage.data])

  return <span
    ref={ref}
    className={className}
    onClick={onClickHandler}
  >
    {isSelected ? <FolderOpen /> : <FolderOutlined />}
    <span className="user-storage__content">
      <span className="user-storage__name">
        {storage.isLoading ? <Loader size={18} /> : storage.data?.name}
      </span>
    </span>
    {showHelp && <HelpIcon>
      Администраторы могут иметь доступ к паролям в данной папке
    </HelpIcon>
    }
  </span >
}

export default forwardRef(UserStorage);