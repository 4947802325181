import { Form, Field, FormRenderProps } from "react-final-form";
import Button from "../../../Common/Components/Button";
import FormFieldWrapper, { FormFieldWrapperProps } from "../../../Common/Components/ReactFinalForm/FormFieldWrapper";
import TextField from "../../../Common/Components/TextField";
import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import SelectList from "../../../Common/Components/SelectList/SelectList";
import UserGroup from "../../../Domain/Entities/UserGroup/UserGroup";
import { getRoles, Role, ROLES } from "../../../Domain/Enums/Roles";
import UserGroupShort from "../../../Domain/Entities/UserGroup/UserGroupShort";
import DateTimePicker from "../../../Common/Components/DateTimePicker/DateTimePicker";
import { isRequired } from "../../../Common/validators";

export type InviteCodeFormProps = FormRenderProps & {
  disabled?: boolean;
  onCancel?: () => void;
  userGroups: Array<UserGroup>
  isCreate?: boolean
}

export type InviteCodeFormSubmitData = {
  name: string,
  aliveBefore: string,
  isStopped: boolean,
  role?: Role,
  userGroups?: Array<UserGroupShort>,
}

const TextFieldWrapped = FormFieldWrapper(TextField)
const DateTimePickerWrapped = FormFieldWrapper(DateTimePicker)
const SelectListWrapped = FormFieldWrapper(SelectList)
const CheckboxWrapped = FormFieldWrapper(({ label, isInvalid, ...rest }: FormFieldWrapperProps) => <FormGroup>
  <FormControlLabel control={<Checkbox {...rest} />} label={label} />
</FormGroup>)

const roles = getRoles();

function InviteCodeForm(props: InviteCodeFormProps) {
  const { submitting, disabled, handleSubmit, onCancel, userGroups, isCreate, form } = props;
  const selectedRole = form.getState().values.role
  return <form onSubmit={handleSubmit} className="create-passcard-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Название"
        name="name"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Действителен до"
        name="aliveBefore"
        component={DateTimePickerWrapped}
        disabled={disabled || submitting}
      />
      {isCreate && <Field
        label="Роль"
        name="role"
        component={SelectListWrapped}
        selectList={roles}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      }
      {isCreate && selectedRole?.role === ROLES.USER && <Field
        label="Группы пользователей"
        name="userGroups"
        component={SelectListWrapped}
        multiple
        selectList={userGroups}
        disabled={disabled || submitting}
      />
      }
      <Field
        label="Приостановлен"
        name="isStopped"
        type="checkbox"
        component={CheckboxWrapped}
        disabled={disabled || submitting}
      />
      <Button type="submit" disabled={disabled || submitting}>Сохранить</Button>
      <Button
        color="secondary"
        onClick={onCancel}
        disabled={disabled || submitting}
      >
        Отмена
      </Button>
    </Stack>
  </form >
}

const DefaultInviteCodeForm = (props: any) => <Form {...props} render={InviteCodeForm} />

export default DefaultInviteCodeForm;