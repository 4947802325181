import { compareByAlphabetically } from "../../../Common/utils"
import PasscardUpdateDto from "../../Dtos/Passcard/PasscardUpdateDto"

export default class Passcard {
  constructor(
    readonly id: number,
    readonly parentIds: number[],
    readonly name: string,
    readonly url: string,
    readonly login: string,
    readonly password: string,
    readonly description: string,
    readonly isFavorite: boolean,
    readonly storageIds: number[],
  ) { }

  static fromApi(obj: Passcard | any) {
    return new Passcard(
      obj.id,
      obj.parentIds,
      obj.name,
      obj.url,
      obj.login,
      obj.password == null || typeof obj.password === "string"
        ? obj.password
        : JSON.stringify(obj.password),
      obj.description,
      obj.isFavorite,
      obj.storageIds,
    )
  }

  toUpdateDto() {
    return new PasscardUpdateDto(
      this.id,
      this.parentIds,
      this.name,
      this.url,
      this.login,
      this.password,
      this.description,
      this.storageIds,
    )
  }

  static compare = (a: Passcard, b: Passcard) => compareByAlphabetically(a, b, 'name')
}