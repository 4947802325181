import { useMutation } from "react-query";
import ChangePasswordDto from "../../../Domain/Dtos/Profile/ChangePasswordDto";
import ProfileService from "../../../Domain/Services/ProfileService/ProfileService";

export default function useChangePassword(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (data: ChangePasswordDto) => ProfileService.changePassword(data),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}