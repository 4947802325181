import { compareByAlphabetically } from "../../../Common/utils"

export default class UserGroupShort {
  constructor(
    readonly id: number,
    readonly name: string
  ) { }

  static fromApi(obj: UserGroupShort) {
    return new UserGroupShort(
      obj.id,
      obj.name
    )
  }

  static compare = (a: UserGroupShort, b: UserGroupShort) => compareByAlphabetically(a, b, 'name')
}