import AdapterMoment from '@mui/lab/AdapterMoment';
import MuiDateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '../TextField';
import { Moment } from 'moment';

declare type DatePickerView = 'year' | 'day' | 'month';
declare type DatePickerInputSize = 'small' | 'medium';

export interface IDateTimePickerProps {
  onChange: (value: string | null) => void;
  value: string | null;
  label?: string;
  cancelText?: string;
  className?: string;
  clearable?: boolean;
  clearText?: string;
  disabled?: boolean;
  disableHighlightToday?: boolean;
  loading?: boolean;
  maxDate?: string;
  minDate?: string;
  okText?: string;
  onAccept?: () => void;
  onClose?: () => void;
  onError?: () => void;
  onMonthChange?: () => void;
  onYearChange?: () => void;
  open?: boolean;
  showTodayButton?: boolean;
  todayText?: string;
  toolbarTitle?: string;
  views?: DatePickerView[];
  inputSize?: DatePickerInputSize;
  isInvalid?: boolean;
  ampm?: boolean;
}

export default function DateTimePicker(props: IDateTimePickerProps) {
  const {
    label,
    onChange: onChangeFromProps,
    value,
    ampm = false,
    disabled,
    isInvalid
  } = props;

  const onChange = (value: Moment | null | undefined) => {
    if (value == null) {
      onChangeFromProps(null)
      return
    }

    onChangeFromProps(value.utc().format())
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale='ru'>
      <MuiDateTimePicker
        renderInput={(props) => <TextField
          {...props}
          autoComplete="off"
          isInvalid={isInvalid}
        />}
        label={label ?? 'Введите дату'}
        value={value == null ? null : value}
        onChange={onChange}
        ampm={ampm}
        mask={'__.__.____ __:__'}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}

DateTimePicker.defaultProps = {
  label: "Дата",
  cancelText: "Отменить",
  clearText: "Очистить",
  disabled: false,
  disableHighlightToday: false,
  okText: "OK",
  showTodayButton: true,
  todayText: "Сегодня",
  toolbarTitle: "Выберите дату",
  inputSize: 'small',
}