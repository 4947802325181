import Passcard from "../../../Domain/Entities/Passcard/Passcard";
import { Api } from "../../Api/Api";
import IPasscardFavoritesRepository from "./IPasscardFavoritesRepository";

class PasscardFavoritesRepository implements IPasscardFavoritesRepository {
  constructor(readonly api: typeof Api) { }

  async getAll() {
    return this.api.getPasscardFavorites()
      .then((x) => x.map(Passcard.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async addToFavorites(id: number) {
    return this.api.addPasscardToFavorites(id)
      .catch((error) => {
        throw error.response;
      });
  }

  async removeFromFavorites(id: number) {
    return this.api.removePasscardFromFavorites(id)
      .catch((error) => {
        throw error.response;
      });
  }

}

export default new PasscardFavoritesRepository(Api);