export default class UserUpdateDto {
  constructor(
    readonly id: number,
    readonly firstName: string,
    readonly lastName: string,
    readonly patronymicName: string,
    readonly nickname: string,
    readonly email: string,
    readonly role: string,
    readonly isBlocked: boolean,
    readonly userGroupIds: Array<number>
  ) { }

  static fromObject(obj: any) {
    return new UserUpdateDto(
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.patronymicName,
      obj.nickname,
      obj.email,
      obj.role,
      obj.isBlocked,
      obj.userGroupIds,
    )
  }
}