import LoginDto from "../../../Domain/Dtos/Authorization/LoginDto";
import NewPasswordDto from "../../../Domain/Dtos/Authorization/NewPasswordDto";
import RegistrationDto from "../../../Domain/Dtos/Authorization/RegistrationDto";
import RestoreDto from "../../../Domain/Dtos/Authorization/RestoreDto";
import Profile from "../../../Domain/Entities/Profile/Profile";
import { Api } from "../../Api/Api";
import IAuthorizationRepository from "./IAuthorizationRepository";

class AuthorizationRepository implements IAuthorizationRepository {
  constructor(readonly api: typeof Api) { }

  async login(data: LoginDto) {
    return this.api.login(data)
      .then(Profile.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async logout() {
    return this.api.logout()
      .catch((error) => {
        throw error.response;
      });
  }

  async register(data: RegistrationDto) {
    return this.api.register(data)
      .then(Profile.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async startRestore(data: RestoreDto) {
    return this.api.startRestore(data)
      .catch((error) => {
        throw error.response;
      });
  }

  async checkRestoreCode(data: string) {
    return this.api.checkRestoreCode(data)
      .catch((error) => {
        throw error.response;
      });
  }

  async setNewPassword(data: NewPasswordDto) {
    return this.api.setNewPassword(data)
      .catch((error) => {
        throw error.response;
      });
  }

  async checkInviteCode(data: string) {
    return this.api.checkInviteCode(data)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new AuthorizationRepository(Api);