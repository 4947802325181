import { useQuery } from "react-query";
import PasscardService from "../../../Domain/Services/PasscardService/PasscardService";
import { PASSCARD_GET_ALL_QK } from "../Constants";

export default function useGetAllPasscard(
  directoryId?: number,
  search?: string,
  storageId?: number,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    [PASSCARD_GET_ALL_QK, directoryId, search, storageId],
    () => PasscardService.getAll(directoryId, search, storageId),
    {
      enabled: false,
      initialData: [],
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}