import IPasscardRepository from "../../../Data/Repositories/PasscardRepository/IPasscardRepository";
import PasscardRepository from "../../../Data/Repositories/PasscardRepository/PasscardRepository";
import PasscardCreateDto from "../../Dtos/Passcard/PasscardCreateDto";
import PasscardUpdateDto from "../../Dtos/Passcard/PasscardUpdateDto";
import Passcard from "../../Entities/Passcard/Passcard";
import IPasscardService from "./IPasscardService";

class PasscardService implements IPasscardService {
  constructor(readonly repository: IPasscardRepository) { }

  async getAll(directoryId?: number, search?: string, storageId?: number) {
    return this.repository.getAll(directoryId, search, storageId)
      .then((x) => x.sort(Passcard.compare))
  }

  async create(data: PasscardCreateDto) {
    return this.repository.create(data);
  }

  async update(data: PasscardUpdateDto) {
    return this.repository.update(data);
  }

  async move(id: number, data: PasscardUpdateDto) {
    return this.repository.move(id, data);
  }

  async delete(id: number) {
    return this.repository.delete(id);
  }
}

export default new PasscardService(PasscardRepository);