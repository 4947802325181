import DirectoryCreateDto from "../../../Domain/Dtos/Directory/DirectoryCreateDto";
import DirectoryMovingDto from "../../../Domain/Dtos/Directory/DirectoryMovingDto";
import DirectoryUpdateDto from "../../../Domain/Dtos/Directory/DirectoryUpdateDto";
import Directory from "../../../Domain/Entities/Directory/Directory";
import DirectoryShort from "../../../Domain/Entities/Directory/DirectoryShort";
import { Api } from "../../Api/Api";
import IDirectoryRepository from "./IDirectoryRepository";

class DirectoryRepository implements IDirectoryRepository {
  constructor(readonly api: typeof Api) { }

  async getAll() {
    return this.api.getDirectories()
      .then((x) => x.map(Directory.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async getAllShort() {
    return this.api.getDirectoriesShort()
      .then((x) => x.map(DirectoryShort.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async create(data: DirectoryCreateDto) {
    return this.api.createDirectory(data)
      .then(Directory.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: DirectoryUpdateDto) {
    return this.api.updateDirectory(data)
      .then(Directory.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async move(data: DirectoryMovingDto) {
    return this.api.moveDirectory(data.directoryId, data.parentId)
      .then(Directory.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async delete(id: number) {
    return this.api.deleteDirectory(id)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new DirectoryRepository(Api);