import { ReactNode } from "react";

export type SidebarProps = {
  children: ReactNode[] | ReactNode;
}

export default function Sidebar(props: SidebarProps) {
  const { children } = props;
  return <div className="sidebar">
    {children}
  </div>
}