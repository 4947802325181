import { useMutation } from "react-query";
import UserGroupCreateDto from "../../../Domain/Dtos/UserGroup/UserGroupCreateDto";
import UserGroupService from "../../../Domain/Services/UserGroupService/UserGroupService";

export default function useCreateUserGroup(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (data: UserGroupCreateDto) => UserGroupService.create(data),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}