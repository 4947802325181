import IUserGroupMatrixRepository from "../../../Data/Repositories/UserGroupMatrixRepository/IUserGroupMatrixRepository";
import UserGroupMatrixRepository from "../../../Data/Repositories/UserGroupMatrixRepository/UserGroupMatrixRepository";
import UserGroupMatrixUpdateDto from "../../Dtos/UserGroupMatrix/UserGroupMatrixUpdateDto";
import Directory from "../../Entities/Directory/Directory";
import UserGroupMatrix from "../../Entities/UserGroup/UserGroupMatrix";
import UserGroupShort from "../../Entities/UserGroup/UserGroupShort";
import { PERMISSIONS } from "../../Enums/Permissions";
import DirectoryService from "../DirectoryService/DirectoryService";
import IDirectoryService from "../DirectoryService/IDirectoryService";
import IUserGroupMatrixService from "./IUserGroupMatrixService";

const getFlattedDirectoriesList = (directories: Array<Directory>): Array<Directory> => {
  return directories.flatMap((x) => [x, ...getFlattedDirectoriesList(x.childrens)])
}

const directoryReduce = (directories: Array<Directory>, userGroupMatrix: Array<UserGroupMatrix>) => {
  return directories.reduce((acc, directory) => {
    const accValue = acc.get(directory.id) as Map<number, number> || new Map<number, number>()

    userGroupMatrix.forEach((x) => {
      const permission = x.relations
        .find((relation) => relation.directoryId === directory.id)?.permission
        || PERMISSIONS.NO_ACCESS

      accValue.set(x.id, permission)
    })

    acc.set(directory.id, accValue)

    return acc;
  }, new Map())
}

class UserGroupMatrixService implements IUserGroupMatrixService {
  constructor(
    readonly repository: IUserGroupMatrixRepository,
    readonly directoryService: IDirectoryService
  ) { }

  async getAll() {
    return this.repository.getAll()
  }

  async getAllGroupedByDirectory() {
    return Promise.all([this.getAll(), this.directoryService.getAll()])
      .then(([userGroupMatrix, directories]) => {
        const groupedDirectory = directoryReduce(
          getFlattedDirectoriesList(directories),
          userGroupMatrix
        )
        const userGroupShort: Array<UserGroupShort> = userGroupMatrix
          .map((x) => new UserGroupShort(x.id, x.name))
        return [
          directories,
          userGroupShort.sort(UserGroupShort.compare),
          groupedDirectory
        ] as const
      })
  }

  async update(data: UserGroupMatrixUpdateDto) {
    return this.repository.update(data);
  }
}

export default new UserGroupMatrixService(UserGroupMatrixRepository, DirectoryService);