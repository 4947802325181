import { cloneElement, ReactElement } from "react";
import classnames from 'classnames';

export type DroppableProps = {
  children: ReactElement
  className?: string
  dropBeforeOrAfter?: boolean
  dropKey: string
  [key: string]: any
}

export default function Droppable(props: DroppableProps) {
  const { className: propsClassName, dropBeforeOrAfter, children, dropKey, ...restProps } = props;
  const { className: chidlrenClassName } = children.props
  const className = classnames(
    chidlrenClassName,
    'droppable',
    propsClassName,
    {
      'droppable-before-or-after': dropBeforeOrAfter
    },
    dropKey
  )

  const updateChildren = cloneElement(children, { className, ...restProps })
  return <>{updateChildren}</>
}