import { useForm } from "react-final-form"

export type FormErrorMessage = {
  name?: string
}

export default function FormErrorMessage(props: FormErrorMessage) {
  const { name = 'common' } = props;
  const form = useForm();
  const { submitErrors } = form.getState();
  const error = submitErrors?.[name]

  if (!error) return <></>
  return <div className="form-error-message">{error}</div>
}