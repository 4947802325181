import { Form, Field, FormRenderProps } from "react-final-form";
import Button from "../../../Common/Components/Button";
import FormFieldWrapper, { FormFieldWrapperProps } from "../../../Common/Components/ReactFinalForm/FormFieldWrapper";
import TextField from "../../../Common/Components/TextField";
import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import SelectList from "../../../Common/Components/SelectList/SelectList";
import UserGroup from "../../../Domain/Entities/UserGroup/UserGroup";
import { getRoles, Role } from "../../../Domain/Enums/Roles";
import UserGroupShort from "../../../Domain/Entities/UserGroup/UserGroupShort";
import FormErrorMessage from "../../../Common/Components/ReactFinalForm/FormErrorMessage";
import { isRequired } from "../../../Common/validators";

export type UserCreateFormProps = FormRenderProps & {
  disabled?: boolean;
  onCancel?: () => void;
  userGroups: Array<UserGroup>
  canBlock?: boolean
}

export type UserCreateFormSubmitData = {
  firstName: string,
  lastName: string,
  patronymicName: string,
  nickname: string,
  email: string,
  role: Role,
  userGroups: Array<UserGroupShort>,
  isBlocked: boolean,
}

const TextFieldWrapped = FormFieldWrapper(TextField)
const SelectListWrapped = FormFieldWrapper(SelectList)
const CheckboxWrapped = FormFieldWrapper(({ label, isInvalid, ...rest }: FormFieldWrapperProps) => <FormGroup>
  <FormControlLabel control={<Checkbox {...rest} />} label={label} />
</FormGroup>)

const roles = getRoles();

function UserCreateForm(props: UserCreateFormProps) {
  const { submitting, disabled, handleSubmit, onCancel, userGroups, canBlock } = props;
  return <form onSubmit={handleSubmit} className="create-passcard-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Фамилия"
        name="lastName"
        validate={isRequired}
        component={TextFieldWrapped}
        disabled={disabled || submitting}
      />
      <Field
        label="Имя"
        name="firstName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Отчество"
        name="patronymicName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
      />
      <Field
        label="Никнейм"
        name="nickname"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Электронная почта"
        name="email"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Роль"
        name="role"
        component={SelectListWrapped}
        selectList={roles}
        disabled={disabled || submitting}
        validate={isRequired}
        disableClearable
      />
      <Field
        label="Группы пользователей"
        name="userGroups"
        component={SelectListWrapped}
        multiple
        selectList={userGroups}
        disabled={disabled || submitting}
      />
      <Field
        label="Заблокирован"
        name="isBlocked"
        type="checkbox"
        component={CheckboxWrapped}
        disabled={disabled || submitting}
      />
      <FormErrorMessage name="common" />
      <Button type="submit" disabled={disabled || submitting}>Сохранить</Button>
      <Button
        color="secondary"
        onClick={onCancel}
        disabled={disabled || submitting}
      >
        Отмена
      </Button>
    </Stack>
  </form >
}

const DefaultUserCreateForm = (props: any) => <Form {...props} render={UserCreateForm} />

export default DefaultUserCreateForm;