import Stack from "@mui/material/Stack";
import TextField from "../../Common/Components/TextField";
import Profile from "../../Domain/Entities/Profile/Profile";

export type UserGroupsRoleUserProps = {
  profile: Profile
}

export default function UserGroupsRoleUser(props: UserGroupsRoleUserProps) {
  const { profile } = props;
  return <div className="user-groups-role-user">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <TextField
        name="userGroups"
        label="Группы пользователей"
        value={profile.userGroups.map((x) => x.name).join(', ')}
      />
      <TextField
        label="Роль"
        value={profile.role.name}
      />
    </Stack>
  </div>
}