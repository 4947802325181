import React, { createContext, useState, useMemo, memo } from 'react';
import ReactDOM from 'react-dom';
import ConfirmationModal, { IConfirmationModalProps } from '../../Common/Components/ConfirmationModal/ConfirmationModal';

export interface ModalContextType {
  showConfirmationModal: (props: ConfirmationModalProps) => void;
  showInformationModal: (props: InformationModalProps) => void;
  closeModal: () => void;
  getModalProps: () => ModalProps | undefined;
  changeConfirmationProps: (props: Partial<ConfirmationModalProps>) => void;
  changeInformationProps: (props: Partial<InformationModalProps>) => void;
}

export type ModalProps = Omit<
  IConfirmationModalProps,
  'open'
>

export type ConfirmationModalProps = Omit<
  ModalProps,
  'showCancelButton' | 'showAccepteButton'
>

export type InformationModalProps = Omit<
  ModalProps,
  'showCancelButton' | 'showAccepteButton' | 'onCancel' | 'isLoading'
  | 'disabled' | 'cancelButtonName' | 'error'
>

export const ModalContext = createContext<ModalContextType>(undefined!);

const modalPortal = (open: boolean, props: any) => {
  const element = document.getElementById('modal')

  if (!element) return undefined;

  return ReactDOM.createPortal(
    <ConfirmationModal
      open={open}
      {...props}
    />,
    element
  )
}

function _ModalProvider({ children }: { children: React.ReactNode }) {
  const [props, setProps] = useState<ModalProps | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const modal = useMemo(() => {
    if (!props) return null;

    return modalPortal(open, props)
  }, [props, open]);

  const resetProps = () => {
    setProps(undefined)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const changeConfirmationProps = (newProps: Partial<ConfirmationModalProps>) => {
    if (props === undefined) return;
    const value = Object.assign({}, props, newProps);
    setProps(value)
  }

  const changeInformationProps = (newProps: Partial<InformationModalProps>) => {
    if (props === undefined) return;
    const value = Object.assign({}, props, { closeAfterBackdropClick: true }, newProps);
    setProps(value)
  }

  const showConfirmationModal = (confirmationModalProps: ConfirmationModalProps) => {
    const onClose = (e: any) => {
      closeModal()
      confirmationModalProps.onClose?.(e)
    }

    setProps({
      ...confirmationModalProps,
      onClose,
      onExited: resetProps,
      closeAfterBackdropClick: false,
    })
    setOpen(true);
  }

  const showInformationModal = (informationModalProps: InformationModalProps) => {
    const onClose = (e: any) => {
      closeModal()
      informationModalProps.onClose?.(e)
    }
    setProps({
      ...{ closeAfterBackdropClick: true },
      ...informationModalProps,
      onClose,
      showCancelButton: false,
      accepteButtonName: 'OK',
      accepteButtonColor: 'primary',
    })
    setOpen(true);
  }

  const getModalProps = (): ModalProps | undefined => {
    return props;
  }

  const value = useMemo(() => ({
    showConfirmationModal,
    showInformationModal,
    closeModal,
    getModalProps,
    changeConfirmationProps,
    changeInformationProps,
  }), [props]);

  return <ModalContext.Provider value={value}>
    {modal}
    {children}
  </ModalContext.Provider>
}

export const ModalProvider = memo(_ModalProvider)