import { WarningAmberOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { ReactNode, useEffect, useState } from "react";
export type CapsLockDetectProps = {
  onChange?: (value: boolean) => void
  children?: ReactNode
}
export default function CapsLockDetect(props: CapsLockDetectProps) {
  const { onChange, children } = props;
  const [isCapsLock, setIsCapsLock] = useState(false);

  useEffect(() => {
    const listener = (event: any) => {

      const value = Boolean(event.getModifierState && event.getModifierState('CapsLock'))
      setIsCapsLock(value)
      onChange?.(value)
    }

    document.addEventListener("keydown", listener)

    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [onChange])

  if (!isCapsLock) return <></>

  return <div className="caps-lock-detect">
    {children || <span className="caps-lock-detect__default-children"><WarningAmberOutlined color="warning" /> <Typography color="#ed6c02"> Включен Caps Lock</Typography></span>}
  </div>
}