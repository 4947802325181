import Stack from "@mui/material/Stack";
import { Field, Form, FormRenderProps } from "react-final-form";
import Button from "../../../Common/Components/Button";
import FormErrorMessage from "../../../Common/Components/ReactFinalForm/FormErrorMessage";
import FormFieldWrapper from "../../../Common/Components/ReactFinalForm/FormFieldWrapper";
import TextField from "../../../Common/Components/TextField";

export type RestoreFormProps = FormRenderProps & {
  disabled?: boolean;
}

const TextFieldWrapped = FormFieldWrapper(TextField)

function RestoreForm(props: RestoreFormProps) {
  const { submitting, disabled, handleSubmit } = props;
  return <form onSubmit={handleSubmit} className="authorization-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Почта или имя пользователя"
        name="login"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
      />
      <Button isLoading={submitting} type="submit" variant="contained" disabled={disabled || submitting}>Восстановить пароль</Button>
      <FormErrorMessage name="common" />
    </Stack>
  </form >
}

const DefaultRestoreForm = (props: any) => <Form {...props} render={RestoreForm} />

export default DefaultRestoreForm;