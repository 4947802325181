import { ReactNode, useCallback } from 'react';
import MuiToggleButton, { ToggleButtonProps as MuiToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import classnames from 'classnames';

export type ToggleButtonValue = {
  id: string | number,
  value: ReactNode,
  props?: Omit<MuiToggleButtonProps, 'value'>
}

export type ToggleButtonProps = {
  list: Array<ToggleButtonValue>
  value: string | number,
  onChange: (event: any, value: string | number) => void
  className?: string,
}

export default function ToggleButton(props: ToggleButtonProps) {
  const { onChange, value, list } = props;

  const onChangeHandler = useCallback((value: any) => (event: any) => {
    onChange(event, value)
  }, [onChange])

  const className = classnames('toggle-button', props.className)

  return (
    <ToggleButtonGroup
      className={className}
      value={value}
      exclusive
    >
      {
        list.map((x) => <MuiToggleButton key={x.id} value={x.id} {...x.props} onClick={onChangeHandler(x.id)}>
          {x.value}
        </MuiToggleButton>
        )
      }
    </ToggleButtonGroup >
  );
}
