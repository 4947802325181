import { useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";
import Loader from '../../../Common/Components/Loader';
import { apiErrorParser } from "../../../Common/utils";
import PasscardUpdateDto from '../../../Domain/Dtos/Passcard/PasscardUpdateDto';
import DirectoryShort from '../../../Domain/Entities/Directory/DirectoryShort';
import Passcard from '../../../Domain/Entities/Passcard/Passcard';
import { PERMISSIONS } from '../../../Domain/Enums/Permissions';
import { useModalPage } from "../../ModalPage/ModalPage";
import useGetAllShortDirectory from '../../_hooks/Directory/useGetAllShortDirectory';
import useMovePasscard from '../../_hooks/Passcard/useMovePasscard';
import MovePasscardForm from "./MovePasscardForm";
import useGetAllPasscard from '../../_hooks/Passcard/useGetAllPasscard';
import useGetAllStorageShort from '../../_hooks/Storage/useGetAllStorageShort';
import StorageShort from '../../../Domain/Entities/Storage/StorageShort';
import { useProfile } from '../../../System/hooks/useProfile';

type FormValue = {
  directories: Array<DirectoryShort | StorageShort>,
}

const defaultInitialValues = {
  directories: [],
}

export default function MovePasscardPage() {
  const { state } = useLocation();
  const { profile } = useProfile();

  const selectedDirectory: DirectoryShort | undefined = (state as any).selectedDirectory
  const selectStorage: Storage | undefined = (state as any).selectStorage
  const passcard: Passcard = (state as any).passcard

  const [defaultFormState, setDefaultFormState] = useState<FormValue>(defaultInitialValues)

  const setDirectoriesToDefaultFormState = (directories: Array<DirectoryShort | StorageShort>) => {
    const selectedDirectoryShort = directories
      ?.filter((x): x is DirectoryShort => x instanceof DirectoryShort)
      ?.filter((x) => passcard.parentIds.includes(x.id))
      ?.filter((x) => x.permission === PERMISSIONS.FULL_ACCESS)

    const selectedStorageShort = directories
      ?.filter((x): x is StorageShort => x instanceof StorageShort)
      ?.filter((x) => passcard.storageIds.includes(x.id))

    setDefaultFormState({
      ...defaultFormState,
      directories: [
        ...defaultFormState.directories,
        ...selectedDirectoryShort,
        ...selectedStorageShort,
      ]
    })
  }

  const directoryShorts = useGetAllShortDirectory(setDirectoriesToDefaultFormState);
  const storageShorts = useGetAllStorageShort(setDirectoriesToDefaultFormState);

  const directories = useMemo(() => {
    return [
      ...(directoryShorts.data ?? []).filter((x) => x.permission === PERMISSIONS.FULL_ACCESS),
      ...(storageShorts.data ?? [])
    ]
  }, [directoryShorts.data, storageShorts.data])

  const modalPage = useModalPage();
  const getAllPasscardHook = useGetAllPasscard(selectedDirectory?.id, '', selectStorage?.id);
  const movePasscard = useMovePasscard(() => {
    if (!selectedDirectory && !selectStorage) return;
    getAllPasscardHook.refetch()
  });

  // возвращаем список идентификаторов, которых нет в списке папок или нет
  // полного доступа к этой папке
  const getHiddenDirectoryIds = (directoryIds: Array<number>) => {
    return directoryIds.filter((directoryId) => {
      const directory = directoryShorts.data?.find((directoryShort) => directoryShort.id === directoryId)

      if (!directory) return true

      return directory.permission !== PERMISSIONS.FULL_ACCESS
    })
  }

  // возвращаем список идентификаторов, которых нет в списке хранилиш
  const getHiddenStorageIds = (storageIds: Array<number>) => {
    return storageIds.filter((storageId) => {
      const storage = storageShorts.data?.find((storageShort) => storageShort.id === storageId)

      if (!storage) return true

      return false;
    })
  }

  function onSubmit(
    formValues: FormValue,
    form: any,
    showErrors: (errors: any) => void
  ) {
    const movingDto: PasscardUpdateDto = PasscardUpdateDto.fromObject({
      ...passcard,
      parentIds: [
        ...formValues.directories.filter((x) => x instanceof DirectoryShort).map((x) => x.id),
        ...getHiddenDirectoryIds(passcard.parentIds)
      ],
      storageIds: [
        ...formValues.directories.filter((x) => x instanceof StorageShort).map((x) => x.id),
        ...getHiddenStorageIds(passcard.storageIds)
      ]
    });

    return movePasscard.mutateAsync({ id: passcard.id, update: movingDto })
      .then(() => modalPage.onClose())
      .catch((errors) => showErrors(apiErrorParser(errors)));
  }

  if (directoryShorts.isLoading || directoryShorts.isFetching || storageShorts.isLoading || storageShorts.isFetching) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader />
    </div>
  }

  return <MovePasscardForm
    initialValues={defaultFormState}
    onSubmit={onSubmit}
    onCancel={modalPage.onClose}
    directories={directories}
    isAdministator={profile?.isAdministator}
  />
}