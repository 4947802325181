import { ReactNode } from "react";
import Loader from "../../Common/Components/Loader";
import TextField from "../../Common/Components/TextField";

export type UserGroupListType = {
  children?: ReactNode
  onCreate?: (value: string) => void
  onCancel?: () => void
  show?: boolean,
  isLoading?: boolean,
  initialValue?: string
}

export default function UserGroupEditItem(props: UserGroupListType) {
  const { children, onCreate, onCancel, show, initialValue, isLoading } = props;

  const onCreateHandler = (value: string) => {
    onCreate?.(value)
  }

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const { value } = event.target

      if (value === '' || value === initialValue) {
        return
      }

      onCreateHandler?.(event.target.value)
    }
    if (event.key === 'Escape') {
      onCancel?.()
    }
  }

  const onBlur = (event: any) => {
    const { value } = event.target

    if (value === '' || value === initialValue) {
      onCancel?.()
      return
    }

    onCreateHandler?.(value)
  }

  return <>
    {show
      ? <><TextField
        defaultValue={initialValue}
        className="user-group-edit-item"
        size="small"
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus
        placeholder="Название группы пользователей"
      />
        {isLoading && <Loader size={16} />}
      </>
      : children
    }
  </>
}