import IPasscardFavoritesRepository from "../../../Data/Repositories/PasscardFavoritesRepository/IPasscardFavoritesRepository";
import PasscardFavoritesRepository from "../../../Data/Repositories/PasscardFavoritesRepository/PasscardFavoritesRepository";
import Passcard from "../../Entities/Passcard/Passcard";
import IPasscardFavoritesService from "./IPasscardFavoritesService";

class PasscardFavoritesService implements IPasscardFavoritesService {
  constructor(readonly repository: IPasscardFavoritesRepository) { }

  async getAll() {
    return this.repository.getAll()
      .then((x) => x.sort(Passcard.compare))
  }

  async addToFavorites(id: number) {
    return this.repository.addToFavorites(id);
  }

  async removeFromFavorites(id: number) {
    return this.repository.removeFromFavorites(id);
  }
}

export default new PasscardFavoritesService(PasscardFavoritesRepository);