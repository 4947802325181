import { useQuery } from "react-query";
import ProfileService from "../../../Domain/Services/ProfileService/ProfileService";
import { PROFILE_GET_QK } from "../Constants";

export default function useGetProfile(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    PROFILE_GET_QK,
    () => ProfileService.profile(),
    {
      retry: false,
      enabled: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}