import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import React from 'react';
import Button from '../Button';

export interface IConfirmationModalProps {
  open: boolean;
  onClose?: (event?: Event | any) => any;
  style?: React.CSSProperties;
  title: string;
  contentText: string;
  onCancel?: () => void;
  onAccepte: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  cancelButtonName?: string;
  accepteButtonName?: string;
  error?: string;
  onExited?: () => void;
  showCancelButton?: boolean,
  showAccepteButton?: boolean,
  accepteButtonColor?: "inherit" | "primary" | "secondary" | "success"
  | "error" | "info" | "warning" | undefined,
  cancelButtonColor?: "inherit" | "primary" | "secondary" | "success"
  | "error" | "info" | "warning" | undefined,
  closeAfterBackdropClick?: boolean,
}

export default function ConfirmationModal(props: IConfirmationModalProps) {
  const {
    open,
    onClose,
    style,
    title,
    contentText,
    onCancel,
    onAccepte,
    disabled,
    isLoading,
    cancelButtonName = 'Нет',
    accepteButtonName = 'Да',
    error,
    onExited,
    showCancelButton = true,
    showAccepteButton = true,
    accepteButtonColor = 'primary',
    cancelButtonColor = 'secondary',
    closeAfterBackdropClick,
  } = props;

  function handlerCloseClick(e: any) {
    if (!closeAfterBackdropClick) return;
    onClose?.();
    onCancel?.();
  }

  return (
    <Dialog
      open={open}
      onClose={handlerCloseClick}
      TransitionProps={{
        onExited: onExited,
      }}
      sx={style}
    >
      <DialogTitle sx={{ width: 500 }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {error && <><br /><div style={{ color: 'rgb(211, 47, 47)' }}>{error}</div></>}
      </DialogContent>
      <DialogActions>
        {showCancelButton && <Button
          onClick={onCancel}
          color={cancelButtonColor}
          disabled={disabled}
        >
          {cancelButtonName}
        </Button>
        }
        {showAccepteButton && <Button
          onClick={onAccepte}
          variant='outlined'
          color={accepteButtonColor}
          disabled={disabled}
          isLoading={isLoading}
        >
          {accepteButtonName}
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
