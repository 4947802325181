import Button from "../../Common/Components/Button";
import { apiErrorParser } from "../../Common/utils";
import { useModal } from "../../System/hooks/useModal";

export type DownloadFileButtonProps = {
  text: string,
  dataValue?: any,
  downloadFileHook: any,
}

export default function DownloadFileButton(props: DownloadFileButtonProps) {
  const { dataValue, text, downloadFileHook } = props;
  const modal = useModal();

  const downloadFileWithPasscardsHook = downloadFileHook(dataValue, undefined, (errors: any) => {
    modal.showInformationModal({
      title: 'Экспорт паролей',
      contentText: apiErrorParser(errors).common || 'Произошла ошибка',
      onAccepte: modal.closeModal,
    })
  });

  return <Button
    isLoading={downloadFileWithPasscardsHook.isLoading || downloadFileWithPasscardsHook.isFetching}
    variant="contained"
    onClick={() => downloadFileWithPasscardsHook.refetch()}
  >
    {text}
  </Button>
}