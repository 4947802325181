import AuthorizationRepository from "../../../Data/Repositories/AuthorizationRepository/AuthorizationRepository";
import IAuthorizationRepository from "../../../Data/Repositories/AuthorizationRepository/IAuthorizationRepository";
import LoginDto from "../../Dtos/Authorization/LoginDto";
import NewPasswordDto from "../../Dtos/Authorization/NewPasswordDto";
import RegistrationDto from "../../Dtos/Authorization/RegistrationDto";
import RestoreDto from "../../Dtos/Authorization/RestoreDto";
import IAuthorizationService from "./IAuthorizationService";

class AuthorizationService implements IAuthorizationService {
  constructor(readonly repository: IAuthorizationRepository) { }

  async login(data: LoginDto) {
    return this.repository.login(data)
  }
  async logout() {
    return this.repository.logout()
  }
  async register(data: RegistrationDto) {
    return this.repository.register(data)
  }
  async startRestore(data: RestoreDto) {
    return this.repository.startRestore(data)
  }
  async checkRestoreCode(data: string) {
    return this.repository.checkRestoreCode(data)
  }
  async setNewPassword(data: NewPasswordDto) {
    return this.repository.setNewPassword(data)
  }
  async checkInviteCode(data: string) {
    return this.repository.checkInviteCode(data)
  }
}

export default new AuthorizationService(AuthorizationRepository);