import { Api } from "../../Api/Api";
import IExportPasscardsRepository from "./IExportPasscardsRepository";

class ExportPasscardsRepository implements IExportPasscardsRepository {
  constructor(readonly api: typeof Api) { }

  async exportPasscards(type: string) {
    return this.api.exportPasscards(type)
      .catch((error) => {
        throw error.response;
      });
  }

  async exportPasscardsWithHierarchyForPassman(encrypted?: boolean) {
    return this.api.exportPasscardsWithHierarchyForPassman(encrypted)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new ExportPasscardsRepository(Api);