import UserGroup from "../../Domain/Entities/UserGroup/UserGroup";
import classnames from 'classnames';
import { Card, CardContent, CardHeader } from "@mui/material";
import UserShort from "../../Domain/Entities/User/UserShort";
import TransferList from "../../Common/Components/TransferList/TransferList";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PERMISSIONS } from "../../Domain/Enums/Permissions";
import DirectoryPermissionRelationList from "../DirectoryPermissionRelation/DirectoryPermissionRelationList";
import { DirectoryPermissionRelationValue } from "../DirectoryPermissionRelation/DirectoryPermissionRelationListItem";
import { intersection, not } from "../../Common/utils";
import Directory from "../../Domain/Entities/Directory/Directory";
import DirectoryShort from "../../Domain/Entities/Directory/DirectoryShort";

export type UserGroupDetailsProps = {
  userGroup: UserGroup
  directories: Array<Directory>
  canUpdateDirectory?: boolean
  canDeleteDirectory?: boolean
  className?: string,
  users: Array<UserShort>
  onDirectoryPermissionChange: (value: Directory | DirectoryShort, newPermission: PERMISSIONS) => void
  onPermittedUsersChange: (users: Array<UserShort>) => void
}

export default function UserGroupDetails(props: UserGroupDetailsProps) {
  const { userGroup, users, directories, onPermittedUsersChange } = props;
  const className = classnames('user-group-details', props.className)
  const [left, setLeft] = useState<Array<UserShort>>([]);
  const [right, setRight] = useState<Array<UserShort>>([]);

  useEffect(() => {
    const _left = intersection<UserShort, { id: number }>(users, userGroup.userIds.map((id) => ({ id })), 'id')
    const _right = not<UserShort, UserShort>(users, _left, 'id')
    setLeft(_left)
    setRight(_right)
  }, [users, userGroup])

  const getPermission = useCallback((directory: Directory) => {
    const relation = userGroup.relations.find((x) => x.directoryId === directory.id)
    if (!relation) return PERMISSIONS.NO_ACCESS
    return relation.permission
  }, [userGroup]);

  const onDirectoryPermissionChange = useCallback((directoryPermissionRelation: DirectoryPermissionRelationValue, permission: PERMISSIONS) => {
    const { directory } = directoryPermissionRelation
    if (!directory) throw new Error('directory not found')
    props.onDirectoryPermissionChange(directory, permission)
  }, [directories, props.onDirectoryPermissionChange])

  const onSetLeft = useCallback((users: Array<UserShort>) => {
    setLeft(users)
    onPermittedUsersChange(users)
  }, [onPermittedUsersChange]);

  const getRows = (
    directories: Array<Directory>,
    depth: number,
  ) => {
    return directories.flatMap((directory) => {
      const children: any = getRows(directory.childrens, depth + 1);

      return [
        { directory, permission: getPermission(directory), depth },
        ...children
      ]
    })
  }

  const list = useMemo(() => getRows(directories, 0), [directories, getPermission])

  return <div className={className}>
    <h4 className="user-group-details__title">
      {userGroup.name}
    </h4>
    <div className="user-group-details__content">
      <div className="user-group-details__user-transfer-list">
        <TransferList
          left={left}
          right={right}
          setLeft={onSetLeft}
          setRight={setRight}
          textField="fullName"
          dataField="id"
          leftTitle="Состоят в группе"
          rightTitle="Не состоят в группе"
        />
      </div>
      <div className="user-group-details__directories">
        <Card>
          <CardHeader
            sx={{ px: 2, py: 1 }}
            title="Установка доступа"
          />
          <CardContent>
            <DirectoryPermissionRelationList
              list={list}
              onChange={onDirectoryPermissionChange}
              striped
            />
          </CardContent>
        </Card>
      </div>

    </div>


  </div>
}