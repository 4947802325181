export default class PasscardUpdateDto {
  constructor(
    readonly id: number,
    readonly parentIds: number[],
    readonly name: string,
    readonly url: string,
    readonly login: string,
    readonly password: string,
    readonly description: string,
    readonly storageIds: number[],
  ) { }

  static fromObject(obj: PasscardUpdateDto) {
    return new PasscardUpdateDto(
      obj.id,
      obj.parentIds,
      obj.name,
      obj.url,
      obj.login,
      obj.password,
      obj.description,
      obj.storageIds,
    )
  }
}