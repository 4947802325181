import Stack from "@mui/material/Stack";
import { Field, Form, FormRenderProps } from "react-final-form";
import Button from "../../../Common/Components/Button";
import PasswordField from "../../../Common/Components/PasswordField";
import FormErrorMessage from "../../../Common/Components/ReactFinalForm/FormErrorMessage";
import FormFieldWrapper from "../../../Common/Components/ReactFinalForm/FormFieldWrapper";
import TextField from "../../../Common/Components/TextField";
import { isRequired } from "../../../Common/validators";

export type AuthorizationFormProps = FormRenderProps & {
  disabled?: boolean;
}

const TextFieldWrapped = FormFieldWrapper(TextField)
const PasswordFieldWrapped = FormFieldWrapper(PasswordField)

function RegistrationForm(props: AuthorizationFormProps) {
  const { submitting, disabled, handleSubmit } = props;
  return <form onSubmit={handleSubmit} className="authorization-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Фамилия"
        name="lastName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Имя"
        name="firstName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Отчество"
        name="patronymicName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
      />
      <Field
        label="Имя пользователя (никнейм)"
        name="nickname"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Почта"
        name="email"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Пароль"
        name="password"
        autoComplete="on"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Подтвердите пароль"
        name="confirmPassword"
        autoComplete="on"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Button isLoading={submitting} type="submit" variant="contained" disabled={disabled || submitting}>Зарегестрироваться</Button>
      <FormErrorMessage name="common" />
    </Stack>
  </form >
}

const DefaultRegistrationForm = (props: any) => <Form {...props} render={RegistrationForm} />

export default DefaultRegistrationForm;