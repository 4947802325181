import { useQuery } from "react-query";
import AuthorizationService from "../../../Domain/Services/AuthorizationService/AuthorizationService";
import { PROFILE_LOGOUT_QK } from "../Constants";

export default function useLogout(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    PROFILE_LOGOUT_QK,
    () => AuthorizationService.logout(),
    {
      retry: false,
      enabled: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}