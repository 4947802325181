import { AnchorHTMLAttributes } from "react"

export type UrlOrTextProps = AnchorHTMLAttributes<HTMLAnchorElement>

const checkValidUrl = (url = '') => {
  const regExp = new RegExp("^(http|https)://", "i");
  const match = regExp.test(url);

  return match
}

export default function UrlOrText(props: UrlOrTextProps) {
  const { target = "_blank", rel = "noopener noreferrer", href, ...rest } = props
  const isValidUrl = checkValidUrl(href)

  if (isValidUrl) return <a
    target={target}
    rel={rel}
    href={href}
    {...rest}
  >
    {href}
  </a>

  return <>{href}</>
}