export default class InviteCodeUpdateDto {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly isStopped: boolean,
    readonly aliveBefore: string,
  ) { }

  static fromObject(obj: any) {
    return new InviteCodeUpdateDto(
      obj.id,
      obj.name,
      obj.isStopped,
      obj.aliveBefore,
    )
  }
}