import { useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

interface TransferListValue { id: number, name: string }

export type TransferListProps = {
  left: Array<any>
  right: Array<any>
  setLeft: (value: Array<any>) => void
  setRight: (value: Array<any>) => void
  textField: string
  dataField: string
  leftTitle: string,
  rightTitle: string
}

function not(a: TransferListValue[], b: TransferListValue[], fieldName: string) {
  return a.filter((aValue) => b.findIndex((bValue) => (aValue as any)[fieldName] === (bValue as any)[fieldName]) === -1);
}

function intersection(a: TransferListValue[], b: TransferListValue[], fieldName: string) {
  return a.filter((aValue) => b.findIndex((bValue) => (aValue as any)[fieldName] === (bValue as any)[fieldName]) !== -1);
}

function union(a: TransferListValue[], b: TransferListValue[], fieldName: string) {
  return [...a, ...not(b, a, fieldName)];
}

export default function TransferList(props: TransferListProps) {
  const { dataField, left, right, textField, setLeft, setRight, leftTitle, rightTitle } = props;
  const [checked, setChecked] = useState<TransferListValue[]>([]);

  const leftChecked = intersection(checked, left, dataField);
  const rightChecked = intersection(checked, right, dataField);

  const getIndex = (list: Array<TransferListValue>, item: TransferListValue) => {
    return list.findIndex((check) => (check as any)[dataField] === (item as any)[dataField])
  }

  const handleToggle = (value: TransferListValue) => () => {
    const currentIndex = getIndex(checked, value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked, dataField));
    setChecked(not(checked, leftChecked, dataField));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked, dataField));
    setChecked(not(checked, rightChecked, dataField));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const numberOfChecked = (items: TransferListValue[]) =>
    intersection(checked, items, dataField).length;

  const handleToggleAll = (items: TransferListValue[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items, dataField));
    } else {
      setChecked(union(checked, items, dataField));
    }
  };

  const isChecked = (value: TransferListValue) => {
    return getIndex(checked, value) !== -1
  }


  const customList = (title: React.ReactNode, items: TransferListValue[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={<Checkbox
          onClick={handleToggleAll(items)}
          checked={numberOfChecked(items) === items.length && items.length !== 0}
          indeterminate={
            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
          }
          disabled={items.length === 0}
          inputProps={{
            'aria-label': 'all items selected',
          }}
        />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        className="transfer-list__list"
        dense
        component="div"
        role="list"
      >
        {items.map((value: TransferListValue) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={(value as any)[dataField]}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={isChecked(value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={(value as any)[textField]} title={(value as any)[textField]} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid className="transfer-list" container spacing={2}>
      <Grid item className="transfer-list__left-column">{customList(leftTitle, left)}</Grid>
      <Grid item className="transfer-list__middle-column">
        <Grid container direction="column" alignItems="center" >
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="перенести все вправо"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="перенести выбранные элементы вправо"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="перенести выбранные элементы влево"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="перенести все влево"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item className="transfer-list__right-column">{customList(rightTitle, right)}</Grid>
    </Grid>
  );
}
