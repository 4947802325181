import UserGroupShort from "../UserGroup/UserGroupShort";
import { getRole, Role } from '../../Enums/Roles';
import { compareByAlphabetically } from "../../../Common/utils";

export default class User {
  constructor(
    readonly id: number,
    readonly firstName: string,
    readonly lastName: string,
    readonly patronymicName: string,
    readonly nickname: number[],
    readonly email: string,
    readonly role: Role,
    readonly isBlocked: boolean,
    readonly userGroups: Array<UserGroupShort>,
  ) {
    this.userGroups = userGroups.sort((a, b) => a.name.length - b.name.length)
  }

  get fullName() {
    return `${this.lastName} ${this.firstName} ${this.patronymicName}`
  }

  get roleName() {
    return this.role.name
  }

  static fromApi(obj: any) {
    return new User(
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.patronymicName || '',
      obj.nickname,
      obj.email,
      getRole(obj.role),
      obj.isBlocked,
      obj.userGroups.map(UserGroupShort.fromApi),
    )
  }

  static fromObject(obj: any) {
    return new User(
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.patronymicName || '',
      obj.nickname,
      obj.email,
      getRole(obj.role),
      obj.isBlocked,
      obj.userGroups.map(UserGroupShort.fromApi),
    )
  }

  static compare = (a: User, b: User) => compareByAlphabetically(a, b, 'fullName')
}