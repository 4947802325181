import { useQuery } from "react-query";
import InviteCodeService from "../../../Domain/Services/InviteCodeService/InviteCodeService";
import { INVITE_CODES_GET_ALL_QK } from "../Constants";

export default function useGetAllInviteCodes(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    INVITE_CODES_GET_ALL_QK,
    () => InviteCodeService.getAll(),
    {
      initialData: [],
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}