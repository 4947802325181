import DirectoryRepository from "../../../Data/Repositories/DirectoryRepository/DirectoryRepository";
import IDirectoryRepository from "../../../Data/Repositories/DirectoryRepository/IDirectoryRepository";
import DirectoryCreateDto from "../../Dtos/Directory/DirectoryCreateDto";
import DirectoryUpdateDto from "../../Dtos/Directory/DirectoryUpdateDto";
import IDirectoryService from "./IDirectoryService";
import DirectoryMovingDto from "../../Dtos/Directory/DirectoryMovingDto";
import Directory from "../../Entities/Directory/Directory";
import DirectoryShort from "../../Entities/Directory/DirectoryShort";

class DirectoryService implements IDirectoryService {
  constructor(readonly repository: IDirectoryRepository) { }

  async getAll() {
    return this.repository.getAll()
      .then((x) => x.sort(Directory.compare))
  }

  async getAllShort() {
    return this.repository.getAllShort()
      .then((x) => x.sort(DirectoryShort.compare))
  }

  async create(data: DirectoryCreateDto) {
    return this.repository.create(data);
  }

  async update(data: DirectoryUpdateDto) {
    return this.repository.update(data);
  }

  async move(data: DirectoryMovingDto) {
    const newData = new DirectoryMovingDto(data.directoryId, data.parentId || -1)
    return this.repository.move(newData);
  }

  async delete(id: number) {
    return this.repository.delete(id);
  }
}

export default new DirectoryService(DirectoryRepository);