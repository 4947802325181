import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
import { useProfile } from '../../../System/hooks/useProfile';
import UserAccount from '../UserAccount/UserAccount';
import { Link } from '@mui/material';

export default function Navbar() {
  const { profile, logout } = useProfile();

  const navigate = useNavigate();

  const onLogoutClick = () => {
    navigate('/authorization', { replace: true, state: { prevLocation: '/' } })
    logout()
  }
  const onExportPasscardsClick = () => {
    navigate('/export')
  }

  return <AppBar position="static" className="navbar">
    <Toolbar disableGutters>
      <Box sx={{ ml: 3, mr: 10 }}>
        <Typography
          variant="h6"
          noWrap
          component="div"
        >
          <NavLink to="/" className="navbar__logo-link">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Логотип"
              height={45}
            />
          </NavLink>
        </Typography>
      </Box>
      
      <Box className="navbar_guide-link">
        <Link href="https://docs.google.com/document/d/1AzUU-ByZezNededjdLAjvqHPhA_Z8l8EJhukL45lFxA">Инструкция</Link>
      </Box>

      {profile && <Box className="navbar__user-account">
        <UserAccount
          profile={profile}
          onLogoutClick={onLogoutClick}
          onExportPasscardsClick={onExportPasscardsClick}
        />
      </Box>
      }
    </Toolbar>
  </AppBar >
}