export default class RegistrationDto {
  constructor(
    readonly firstName: string,
    readonly lastName: string,
    readonly patronymicName: string,
    readonly nickname: string,
    readonly email: string,
    readonly password: string,
    readonly confirmPassword: string,
    readonly inviteCode: string,
  ) { }

  static fromObject(obj: RegistrationDto) {
    return new RegistrationDto(
      obj.firstName,
      obj.lastName,
      obj.patronymicName,
      obj.nickname,
      obj.email,
      obj.password,
      obj.confirmPassword,
      obj.inviteCode,
    )
  }
}