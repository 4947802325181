import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import LockResetOutlined from "@mui/icons-material/LockResetOutlined";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton/IconButton";
import Button from "../../../Common/Components/Button";
import Email from "../../../Common/Components/Email/Email";
import DataTable from "../../../Common/Components/Table/DataTable";
import User from "../../../Domain/Entities/User/User";

export type UserListProps = {
  users: Array<User>
  onUpdateClick?: (user: User) => void
  onDeleteClick?: (user: User) => void
  onUserBlockClick?: (event: any, user: User) => void
  onResetPasswordClick?: (user: User) => void
  canDelete?: (user: User) => boolean
  canUpdate?: (user: User) => boolean
  canBlock?: (user: User) => boolean
  canResetPassword?: (user: User) => boolean
  canCreate?: boolean
  onCreateClick?: (event: any) => void
}

export default function UserCardList(props: UserListProps) {
  const {
    users, onDeleteClick, onUpdateClick, onUserBlockClick, canBlock,
    canDelete, canUpdate, canCreate, onCreateClick, canResetPassword,
    onResetPasswordClick
  } = props;
  return <div className="user-table-list">
    <div className="user-table-list__top-panel">
      {canCreate && <Button onClick={onCreateClick}>Добавить пользователя</Button>}
    </div>
    <DataTable
      headLines={[
        {
          id: 1,
          cells: [
            {
              id: 1,
              value: 'ФИО',
              renderValue: 'ФИО',
              config: {
                searchable: true
              }
            },
            {
              id: 2,
              value: 'Почта',
              renderValue: 'Почта',
              config: {
                searchable: true
              }
            },
            {
              id: 3,
              value: 'Роль',
              renderValue: 'Роль',
              config: {
                searchable: true
              }
            },
            {
              id: 4,
              value: 'Группы пользователей',
              renderValue: 'Группы пользователей',
              config: {
                searchable: true
              }
            },
            {
              id: 5,
              value: 'Блокировка',
              renderValue: 'Блокировка',
            },
            {
              id: 6,
              value: undefined,
              renderValue: '',
            },
          ]
        }
      ]}
      bodyLines={
        users.map((x) => ({
          id: x.id,
          cells: [
            {
              id: 1,
              value: x.fullName,
              renderValue: x.fullName,
              config: { title: x.fullName }
            },
            {
              id: 2,
              value: x.email,
              renderValue: <Email email={x.email} />,
            },
            {
              id: 3,
              value: x.roleName,
              renderValue: x.roleName,
            },
            {
              id: 4,
              value: x.userGroups.map((x) => x.name).join(', '),
              renderValue: x.userGroups.map((x) => x.name).join(', '),
            },
            {
              id: 5,
              value: x.isBlocked,
              renderValue: canBlock?.(x) && <div className="user-table-list__blocked-checkbox">
                <Checkbox onChange={(event, checked) => onUserBlockClick?.(checked, x)} checked={x.isBlocked} />
              </div>,
              config: { title: x.fullName }
            },
            {
              id: 6,
              value: undefined,
              renderValue: <div style={{ display: 'flex' }}>
                {canUpdate?.(x) && <IconButton onClick={() => onUpdateClick?.(x)} title="Изменить пользователя">
                  <ModeEditOutlineOutlined />
                </IconButton>
                }
                {canDelete?.(x) && <IconButton onClick={() => onDeleteClick?.(x)} title="Удалить пользователя">
                  <DeleteOutlined color="error" />
                </IconButton>
                }
                {canResetPassword?.(x) && <IconButton onClick={() => onResetPasswordClick?.(x)} title="Сбросить пароль пользователю">
                  <LockResetOutlined color="error" />
                </IconButton>
                }
              </div>,
            },
          ]
        }))
      }
    />
  </div>
}