import { useMutation } from "react-query";
import PasscardFavoritesService from "../../../Domain/Services/PasscardFavoritesService/PasscardFavoritesService";

export default function useUpdatePasscard(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (id: number) => PasscardFavoritesService
      .removeFromFavorites(id),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}