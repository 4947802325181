import { useState } from "react";
import Button from "../../Common/Components/Button";
import { apiErrorParser } from "../../Common/utils";
import ChangePasswordDto from "../../Domain/Dtos/Profile/ChangePasswordDto";
import ProfileUpdateDto from "../../Domain/Dtos/Profile/ProfileUpdateDto";
import { useModal } from "../../System/hooks/useModal";
import { useProfile } from "../../System/hooks/useProfile";
import useChangePassword from "../_hooks/Profile/useChangePassword";
import useUpdateProfile from "../_hooks/Profile/useUpdateProfile";
import ChangePasswordProfileForm, { ChangePasswordProfileFormSubmitData } from "./ChangePasswordProfileForm";
import ProfileUpdateForm, { ProfileUpdateFormSubmitData } from "./ProfileUpdateForm";
import UserGroupsRoleUser from "./UserGroupsRoleProfile";

export default function ProfilePage() {
  const [changePasswordFormOpen, setChangePasswordFormOpen] = useState(false);
  const profileHook = useProfile();
  const updateProfileHook = useUpdateProfile()
  const changePasswordHook = useChangePassword()
  const modal = useModal();

  const onUpdateProfile = (
    formValue: ProfileUpdateFormSubmitData,
    form: any,
    showErrors: (errors: any) => void
  ) => {
    return updateProfileHook.mutateAsync(ProfileUpdateDto.fromObject(formValue))
      .then((profile) => profileHook.setProfile(profile))
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Обновление профиля',
          contentText: 'Профиль успешно обновлен',
          onAccepte: modal.closeModal
        })
      })
      .catch((errors: any) => showErrors(apiErrorParser(errors)))
  }

  const onChangePassword = (
    formValue: ChangePasswordProfileFormSubmitData,
    form: any,
    showErrors: (errors: any) => void
  ) => {
    return changePasswordHook.mutateAsync(ChangePasswordDto.fromObject(formValue))
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Смена пароля',
          contentText: 'Пароль успешно изменен',
          onAccepte: () => {
            modal.closeModal()
            setChangePasswordFormOpen(false)
          }
        })
      })
      .catch((errors: any) => showErrors(apiErrorParser(errors)))
  }

  if (!profileHook.profile) return <>Профиль не найден</>

  return <div className="profile-page">
    <div className="profile-page__wrapper">
      <ProfileUpdateForm
        initialValues={profileHook.profile}
        onSubmit={onUpdateProfile}
      />
    </div>
    <div className="profile-page__wrapper">
      <UserGroupsRoleUser profile={profileHook.profile} />
      {!changePasswordFormOpen && <Button
        className="profile-page__change-password-button"
        onClick={() => setChangePasswordFormOpen(true)}
      >
        Сменить пароль
      </Button>
      }
      {changePasswordFormOpen && <ChangePasswordProfileForm
        onSubmit={onChangePassword}
        onCancel={() => setChangePasswordFormOpen(false)}
      />}
    </div>
  </div>
}