import { useQuery } from "react-query";
import StorageService from "../../../Domain/Services/StorageService/StorageService";
import { STORAGE_GET_USER_STORAGE_QK } from "../Constants";

export default function useGetUserStorage(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    STORAGE_GET_USER_STORAGE_QK,
    () => StorageService.getUserStorage(),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}