export const composeValidators = (...validators: Array<(value: any, allValues: any, meta: any) => any>) => (value: any, allValues: any, meta: any) =>
  validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)

export const isRequired = (value: any) => {
  const nullOrUndefined = value === null || value === undefined
  const objectOrArray = nullOrUndefined ? false : typeof value === "object"
  if (objectOrArray) {
    return Object.keys(value).length > 0 ? undefined : "Обязательное поле"
  }
  return value ? undefined : "Обязательное поле"
}