import { ChromeReaderModeOutlined } from '@mui/icons-material';
import ModeEditOutlineOutlined from '@mui/icons-material/ModeEditOutlineOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import { PERMISSIONS } from '../../../Domain/Enums/Permissions';
import ToggleButton, { ToggleButtonValue } from '../ToggleButton/ToggleButton';
import classnames from 'classnames';
import { useState } from 'react';

export type PermissionToggleButtomProps = {
  initialValue: number | string
  onChange: (event: any, value: number | string) => void
  className?: string,
}

const list: Array<ToggleButtonValue> = [
  {
    id: PERMISSIONS.NO_ACCESS,
    value: <VisibilityOffOutlined />,
    props: {
      size: "small",
      title: "Нет доступа",
    }
  },
  {
    id: PERMISSIONS.ONLY_READING,
    value: <ChromeReaderModeOutlined />,
    props: {
      size: "small",
      title: "Только чтение",
    }
  },
  {
    id: PERMISSIONS.FULL_ACCESS,
    value: <ModeEditOutlineOutlined />,
    props: {
      size: "small",
      title: "Полный доступ",
    }
  },
]

export default function PermissionToggleButtom(props: PermissionToggleButtomProps) {
  const { onChange, initialValue } = props;
  const [value, setValue] = useState(initialValue);

  const onChangeHandler = async (event: any, value: number | string) => {
    setValue(value)
    onChange?.(event, value)
  }


  const className = classnames('permission-toggle-button', props.className)

  return (
    <ToggleButton
      className={className}
      list={list}
      onChange={onChangeHandler}
      value={value}
    />
  );
}
