import { Form, Field, FormRenderProps } from "react-final-form";
import FormFieldWrapper from "../../Common/Components/ReactFinalForm/FormFieldWrapper";
import Stack from "@mui/material/Stack";
import TextField from "../../Common/Components/TextField";
import Button from "../../Common/Components/Button";
import FormErrorMessage from "../../Common/Components/ReactFinalForm/FormErrorMessage";
import { isRequired } from "../../Common/validators";

export type UserFormProps = FormRenderProps & {
  disabled?: boolean;
}

export type ProfileUpdateFormSubmitData = {
  lastName: string
  firstName: string
  patronymicName: string
  nickname: string
  email: string
}

const TextFieldWrapped = FormFieldWrapper(TextField)


function ProfileUpdateForm(props: UserFormProps) {
  const { submitting, disabled, handleSubmit } = props;
  return <form onSubmit={handleSubmit} className="create-passcard-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Фамилия"
        name="lastName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Имя"
        name="firstName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Отчество"
        name="patronymicName"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
      />
      <Field
        label="Никнейм"
        name="nickname"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Email"
        name="email"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <FormErrorMessage />
      <Button
        variant="outlined"
        type="submit"
        isLoading={submitting}
        disabled={disabled || submitting}
      >
        Сохранить
      </Button>
    </Stack>
  </form >
}

const DefaultProfileUpdateForm = (props: any) => <Form {...props} render={ProfileUpdateForm} />

export default DefaultProfileUpdateForm;