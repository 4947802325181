import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CapsLockDetect from "../../../Common/Components/CapsLockDetect/CapsLockDetect";
import PageNotFound from "../../../Common/Pages/PageNotFound";
import { apiErrorParser } from "../../../Common/utils";
import NewPasswordDto from "../../../Domain/Dtos/Authorization/NewPasswordDto";
import { useModal } from "../../../System/hooks/useModal";
import useCheckRestoreCode from "../../_hooks/Authorization/useCheckRestoreCode";
import useSetNewPassword from "../../_hooks/Authorization/useSetNewPassword";
import NewPasswordForm, { NewPasswordFormSubmitData } from "./NewPasswordForm";

export default function NewPasswordPage() {
  const [isRestoreCodeValid, setIsRestoreCodeValid] = useState(false)
  const searchParams = useSearchParams()
  const restoreCode = searchParams[0].get('key') || ''
  const checkRestoreCodeHook = useCheckRestoreCode(restoreCode, () => setIsRestoreCodeValid(true), () => setIsRestoreCodeValid(false));
  const setNewPasswordHook = useSetNewPassword();
  const modal = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    checkRestoreCodeHook.refetch()
  }, [])

  if (!checkRestoreCodeHook.isSuccess && !checkRestoreCodeHook.isError) return <></>

  if (!isRestoreCodeValid) return <PageNotFound />

  const mavigateToAuthPage = () => navigate('/authorization', { replace: true })

  const onSubmit = (
    formData: NewPasswordFormSubmitData,
    form: any,
    showErrors: (errors: any) => void
  ) => {
    return setNewPasswordHook.mutateAsync(NewPasswordDto.fromObject({ ...formData, restorePasswordCode: restoreCode }))
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Смена пароля',
          contentText: 'Пароль успешно изменен. Сейчас Вы будете переведены на страницу авторизации',
          onAccepte: () => {
            modal.closeModal()
            mavigateToAuthPage()
          }
        })
      })
      .catch((errors: any) => {
        showErrors(apiErrorParser(errors))
      })
  }
  return <div className="new-password-page">
    <Card>
      <CardContent>
        <NewPasswordForm onSubmit={onSubmit} />
      </CardContent>
    </Card>
    <CapsLockDetect />
  </div>
}