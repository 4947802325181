import { WarningAmberOutlined } from "@mui/icons-material"
import classnames from "classnames"
import PermissionToggleButtom from "../../Common/Components/PermissionToggleButton/PermissionToggleButton"
import DirectoryShort from "../../Domain/Entities/Directory/DirectoryShort"
import { PERMISSIONS } from "../../Domain/Enums/Permissions"

export type DirectoryPermissionRelationValue = {
  directory: DirectoryShort
  permission: PERMISSIONS
  depth: number
}

export type DirectoryPermissionRelationListProps = {
  value: DirectoryPermissionRelationValue
  onChange: (value: DirectoryPermissionRelationValue, newPermission: PERMISSIONS) => void
  className?: string,
}

export default function DirectoryPermissionRelationListItem(props: DirectoryPermissionRelationListProps) {
  const { value } = props;
  const className = classnames('directory-permission-relation-list-item',
    props.className,

  )

  const onChange = (event: any, newPermission: number | string) => {
    return props.onChange(value, +newPermission)
  }

  return <div className={className}>
    <span className={`directory-permission-relation-list-item__title directory-permission-relation-list-item__title--indent-${value.depth > 5 ? 5 : value.depth}`}>{value.directory.name}</span>
    {
      value.depth > 5 && <span
        className="directory-permission-relation-list-item__warning-icon"
        title="Данная папка находится в другой папке"
      >
        <WarningAmberOutlined color="warning" />
      </span>
    }
    <span className="directory-permission-relation-list-item__permissions">
      <PermissionToggleButtom
        key={`${value.directory.id} - ${value.permission}`}
        initialValue={value.permission}
        onChange={onChange}
      />
    </span>
  </div>
}