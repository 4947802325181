import PasscardCreateDto from "../../../Domain/Dtos/Passcard/PasscardCreateDto";
import PasscardUpdateDto from "../../../Domain/Dtos/Passcard/PasscardUpdateDto";
import Passcard from "../../../Domain/Entities/Passcard/Passcard";
import { Api } from "../../Api/Api";
import IPasscardRepository from "./IPasscardRepository";

class PasscardRepository implements IPasscardRepository {
  constructor(readonly api: typeof Api) { }

  async getAll(directoryId?: number, search?: string, storageId?: number) {
    return this.api.getPasscards(directoryId, search, storageId)
      .then((x) => x.map(Passcard.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async getByDirectoryId(id: number) {
    return this.api.getPasscardsByDirectoryId(id)
      .catch((error) => {
        throw error.response;
      });
  }

  async create(data: PasscardCreateDto) {
    return this.api.createPasscard(data)
      .then(Passcard.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: PasscardUpdateDto) {
    return this.api.updatePasscard(data)
      .then(Passcard.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async move(id: number, data: PasscardUpdateDto) {
    return this.api.updatePasscard(data)
      .then(Passcard.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async delete(id: number) {
    return this.api.deletePasscard(id)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new PasscardRepository(Api);