import UserGroupCreateDto from "../../../Domain/Dtos/UserGroup/UserGroupCreateDto";
import UserGroupUpdateDto from "../../../Domain/Dtos/UserGroup/UserGroupUpdateDto";
import UserGroup from "../../../Domain/Entities/UserGroup/UserGroup";
import { Api } from "../../Api/Api";
import IUserGroupRepository from "./IUserGroupRepository";

class UserGroupRepository implements IUserGroupRepository {
  constructor(readonly api: typeof Api) { }

  async getAll() {
    return this.api.getUserGroups()
      .then((x) => x.map(UserGroup.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async create(data: UserGroupCreateDto) {
    return this.api.createUserGroup(data)
      .then(UserGroup.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: UserGroupUpdateDto) {
    return this.api.updateUserGroup(data)
      .then(UserGroup.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async delete(id: number) {
    return this.api.deleteUserGroup(id)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new UserGroupRepository(Api);