import { useMutation } from "react-query";
import ProfileUpdateDto from "../../../Domain/Dtos/Profile/ProfileUpdateDto";
import ProfileService from "../../../Domain/Services/ProfileService/ProfileService";

export default function useUpdateProfile(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (data: ProfileUpdateDto) => ProfileService.update(data),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}