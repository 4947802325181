export default class RestoreDto {
  constructor(
    readonly login: string,
  ) { }

  static fromObject(obj: any) {
    return new RestoreDto(
      obj.login
    )
  }
}