import ExportPasscardsRepository from "../../../Data/Repositories/ExportPasscardsRepository/ExportPasscardsRepository";
import IExportPasscardsRepository from "../../../Data/Repositories/ExportPasscardsRepository/IExportPasscardsRepository";
import IExportPasscardsService from "./IExportPasscardsService";

class ExportPasscardsService implements IExportPasscardsService {
  constructor(readonly repository: IExportPasscardsRepository) { }

  private async downloadFile(fileUrl: string, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([fileUrl]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async exportPasscards(type: string) {
    return this.repository.exportPasscards(type)
      .then((fileUrl) => this.downloadFile(fileUrl, `passman.${type}`));
  }

  async exportPasscardsWithHierarchyForPassman(encrypted?: boolean) {
    return this.repository.exportPasscardsWithHierarchyForPassman(encrypted)
      .then((fileUrl) => this.downloadFile(fileUrl, 'passman-hierarchy.json'));
  }
}

export default new ExportPasscardsService(ExportPasscardsRepository);