import { useMutation } from "react-query";
import PasscardUpdateDto from "../../../Domain/Dtos/Passcard/PasscardUpdateDto";
import PasscardService from "../../../Domain/Services/PasscardService/PasscardService";

export default function useUpdatePasscard(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (data: PasscardUpdateDto) => PasscardService
      .update(data),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}