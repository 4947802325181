import { createContext, useState, useMemo, useEffect } from 'react';
import LoginDto from '../../Domain/Dtos/Authorization/LoginDto';
import RegistrationDto from '../../Domain/Dtos/Authorization/RegistrationDto';
import Profile from '../../Domain/Entities/Profile/Profile';
import useLogin from '../../Feature/_hooks/Authorization/useLogin';
import useLogout from '../../Feature/_hooks/Authorization/useLogout';
import useRegistration from '../../Feature/_hooks/Authorization/useRegistration';
import useGetProfile from '../../Feature/_hooks/Profile/useGetProfile';

export interface ProfileContextType {
  profile?: Profile;
  reloadProfile: () => void;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isBlocked: boolean;
  login: (data: LoginDto) => Promise<void>;
  registration: (data: RegistrationDto) => Promise<void>;
  logout: () => Promise<void>;
  setProfile: (profile: Profile) => void
}

export const ProfileContext = createContext<ProfileContextType>(undefined!);

export function ProfileProvider({ children }: { children: React.ReactNode }) {
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const getProfileHook = useGetProfile((profile) => {
    setProfile(profile)
  });
  const loginHook = useLogin();
  const logoutHook = useLogout();
  const registrationHook = useRegistration();

  useEffect(() => {
    reloadProfile();
  }, [])

  const reloadProfile = () => getProfileHook.refetch()
    .then((x) => {
      if (x.isSuccess) {
        setProfile(x.data)
        return;
      }
      setProfile(undefined)
    })
    .catch(() => {
      setProfile(undefined)
    })

  const login = (data: LoginDto) => loginHook.mutateAsync(data)
    .then((profile) => setProfile(profile))

  const registration = (data: RegistrationDto) => registrationHook.mutateAsync(data)
    .then((profile) => setProfile(profile))

  const logout = () => logoutHook.refetch()
    .then(() => setProfile(undefined))

  const isAuthenticated = profile !== undefined;
  const isAuthenticating = !getProfileHook.isSuccess && !getProfileHook.isError
  const isBlocked = profile !== undefined && profile.isBlocked;

  const value = useMemo(() => ({
    profile,
    reloadProfile,
    isAuthenticated,
    isAuthenticating,
    isBlocked,
    login,
    registration,
    logout,
    setProfile,
  }), [profile, isAuthenticated, isAuthenticating, isBlocked]);

  return <ProfileContext.Provider value={value}>
    {children}
  </ProfileContext.Provider>;
}
