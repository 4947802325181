export default class NewPasswordDto {
  constructor(
    readonly restorePasswordCode: string,
    readonly newPassword: string,
    readonly confirmPassword: string,
  ) { }

  static fromObject(obj: any) {
    return new NewPasswordDto(
      obj.restorePasswordCode,
      obj.newPassword,
      obj.confirmPassword,
    )
  }
}