export default class ProfileUpdateDto {
  constructor(
    readonly firstName: string,
    readonly lastName: string,
    readonly patronymicName: string,
    readonly nickname: number[],
    readonly email: string,
  ) { }

  static fromObject(obj: any) {
    return new ProfileUpdateDto(
      obj.firstName,
      obj.lastName,
      obj.patronymicName,
      obj.nickname,
      obj.email,
    )
  }
}