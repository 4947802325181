import { useQuery } from "react-query";
import Storage from "../../../Domain/Entities/Storage/Storage";
import StorageService from "../../../Domain/Services/StorageService/StorageService";
import { STORAGE_GET_ALL_QK } from "../Constants";

export default function useGetAllStorage(
  onSuccess?: (data: Array<Storage>) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    STORAGE_GET_ALL_QK,
    () => StorageService.getAll(),
    {
      initialData: [],
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}