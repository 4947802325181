export default class UserShort {
  constructor(
    readonly id: number,
    readonly firstName: string,
    readonly lastName: string,
    readonly patronymicName: string,
    readonly nickname: string,
    readonly role: string,
    readonly isBlocked: boolean,
  ) { }

  get fullName() {
    return `${this.lastName} ${this.firstName} ${this.patronymicName}`
  }

  static fromApi(obj: UserShort) {
    return new UserShort(
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.patronymicName || '',
      obj.nickname,
      obj.role,
      obj.isBlocked,
    )
  }
}