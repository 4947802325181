import { ReactElement, cloneElement, useEffect, useCallback, useState } from "react"
import ReactDOM from 'react-dom';
import classnames from 'classnames';

const DROP_BEFORE_OR_AFTER_CLASS_NAME = "droppable-before-or-after"
const DRAGGABLE_CLASS_NAME = "draggable"
const DRAGGING_CLASS_NAME = "dragging"
const DRAGGABLE_FROM_CLASS_NAME = "draggable-from"
const DRAGGABLE_HOVER_CLASS_NAME = "draggable-hover"
const DRAGGABLE_PLACEHOLDER_ID = "draggable-placeholder"

export type DraggableProps = {
  children: ReactElement;
  onDraggingStart?: (event: any) => void
  onDraggingEnd?: (from: HTMLElement, to?: HTMLElement) => void
  dropBeforeOrAfter?: boolean
  dropToKey: string
  renderDraggableElement?: (element: HTMLElement | undefined) => JSX.Element
}

const getParentElementbyClassName = (target: HTMLElement, className: string): HTMLElement | undefined => {
  const parent = target?.closest(`.${className}`)

  if (!parent) {
    return undefined;
  }

  return parent as HTMLElement;
}

const onMove = async (element: HTMLElement, x: number, y: number) => {
  element.style.top = `${y + 2}px`
  element.style.left = `${x}px`
}

const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
  return Math.round(Math.sqrt(Math.pow(x1 - x2, 2) +
    Math.pow(y1 - y2, 2)))
}

let hoveredDroppable: HTMLElement | undefined = undefined;

const setInitialDraggableStyle = async (originalElement: HTMLElement, draggableElement: HTMLElement) => {
  originalElement?.classList.add(DRAGGABLE_FROM_CLASS_NAME)
  draggableElement.classList.add(DRAGGING_CLASS_NAME)
  draggableElement.style.position = "absolute"
  document.body.style.userSelect = 'none'
}

const resetInitialDraggableStyle = async (originalElement: HTMLElement | undefined, draggableElement: HTMLElement | undefined) => {
  originalElement?.classList.remove(DRAGGABLE_FROM_CLASS_NAME)
  document.body.style.userSelect = 'initial'
  document.body.removeChild(draggableElement!);
  hoveredDroppable?.classList.remove(DRAGGABLE_HOVER_CLASS_NAME);
}

const betweenDropablesElement = document.createElement('div')
betweenDropablesElement.style.borderBottom = "2px solid black"

export default function Draggable(props: DraggableProps) {
  const { onDraggingEnd, onDraggingStart, dropBeforeOrAfter, dropToKey, renderDraggableElement } = props;
  const [mouseDownCoordinates, setMouseDownCoordinates] = useState<{ x: number, y: number } | null>(null);
  const [originalElement, setOriginalElement] = useState<HTMLElement | undefined>();
  const [draggableElement, setDraggableElement] = useState<HTMLElement | undefined>();
  const [isDragging, setIsDragging] = useState(false);
  const { className: chidlrenClassName } = props.children.props
  const className = classnames(chidlrenClassName, DRAGGABLE_CLASS_NAME)

  const getDraggableElement = (element: HTMLElement | undefined) => {
    if (renderDraggableElement) {
      const wrapper = document.createElement('div')

      wrapper.id = DRAGGABLE_PLACEHOLDER_ID
      wrapper.style.display = "none"

      document.body.appendChild(wrapper)

      const render = renderDraggableElement(element)

      ReactDOM.render(render, document.getElementById(DRAGGABLE_PLACEHOLDER_ID))
      document.body.removeChild(wrapper)

      return wrapper;
    }

    const clone = element?.cloneNode(true) as HTMLElement

    return clone
  }

  const getBetweenPosition = (event: any, droppable: HTMLElement | undefined) => {
    const droppableClienRect = droppable?.getBoundingClientRect()

    if (!droppableClienRect) return;

    const fromCursorToTopDistance = getDistance(
      event.clientX,
      droppableClienRect.top,
      event.clientX,
      event.clientY
    )
    const fromCursorToBottomDistance = getDistance(
      event.clientX,
      droppableClienRect.bottom,
      event.clientX,
      event.clientY
    )

    const isTopHovered = fromCursorToTopDistance / droppableClienRect.height <= 0.20
    const isBottomHovered = fromCursorToBottomDistance / droppableClienRect.height <= 0.20

    if (isTopHovered) {
      return 'top'
    }

    if (isBottomHovered) {
      return 'bottom'
    }

    return undefined;
  }

  const onBetweenHover = async (event: any, droppable: HTMLElement | undefined) => {
    if (!droppable?.classList.contains(DROP_BEFORE_OR_AFTER_CLASS_NAME)) {
      betweenDropablesElement?.parentElement?.removeChild(betweenDropablesElement)
      return;
    }

    const droppableClienRect = droppable?.getBoundingClientRect()

    if (!droppableClienRect) return;

    let insertPosition: InsertPosition | undefined = undefined;

    const betweenPosition = getBetweenPosition(event, droppable)

    switch (true) {
      case betweenPosition === "top":
        insertPosition = 'beforebegin'
        break;
      case betweenPosition === "bottom":
        insertPosition = 'afterend'
        break;
      default:
        insertPosition = undefined
    }

    if (insertPosition) {
      droppable?.insertAdjacentElement(insertPosition, betweenDropablesElement) as HTMLElement
    } else {
      betweenDropablesElement?.parentElement?.removeChild(betweenDropablesElement)
    }
  }

  const onHoverDroppable = async (event: any) => {
    draggableElement!.style.display = "none";
    const droppable = getParentElementbyClassName(document.elementFromPoint(event.clientX, event.clientY) as HTMLElement, dropToKey)
    draggableElement!.style.display = "";

    const isBetweenPosition = getBetweenPosition(event, droppable) !== undefined

    if (dropBeforeOrAfter) {
      onBetweenHover(event, droppable)
    }

    if (droppable !== hoveredDroppable || isBetweenPosition) {
      hoveredDroppable?.classList.remove(DRAGGABLE_HOVER_CLASS_NAME);
    } else {
      droppable?.classList.add(DRAGGABLE_HOVER_CLASS_NAME);
    }

    if (droppable !== undefined && !droppable?.classList.contains(DRAGGABLE_HOVER_CLASS_NAME) && !droppable?.classList.contains(DRAGGABLE_FROM_CLASS_NAME) && droppable !== hoveredDroppable) {
      hoveredDroppable = droppable;
    }
  }

  const onMouseDown = useCallback(async (event: any) => {
    document.body.classList.add('user-select-none')
    if (window.getSelection()?.toString()) return;

    const element = getParentElementbyClassName(event.target, DRAGGABLE_CLASS_NAME)

    setMouseDownCoordinates({ x: event.clientX, y: event.clientY })
    setOriginalElement(element as HTMLElement)
  }, [])


  const onDragEnd = (event: any) => {
    if (!isDragging || !originalElement || !draggableElement) return;

    draggableElement.style.display = "none";
    const droppable = getParentElementbyClassName(document.elementFromPoint(event.clientX, event.clientY) as HTMLElement, dropToKey)

    const isBetweenPosition = getBetweenPosition(event, droppable) !== undefined

    if (droppable?.classList.contains(DRAGGABLE_FROM_CLASS_NAME) && !isBetweenPosition) return

    if (dropBeforeOrAfter && droppable?.classList.contains(DROP_BEFORE_OR_AFTER_CLASS_NAME) && isBetweenPosition) {
      onDraggingEnd?.(originalElement, undefined)
    } else {
      onDraggingEnd?.(originalElement, droppable)
    }
  }

  const onMouseUp = useCallback(async (event: any) => {
    onDragEnd(event)

    document.body.classList.remove('user-select-none')
    setMouseDownCoordinates(null)
    setOriginalElement(undefined)
    setDraggableElement(undefined)
    setIsDragging(false)

    if (isDragging) {
      betweenDropablesElement?.parentElement?.removeChild(betweenDropablesElement)
      resetInitialDraggableStyle(originalElement, draggableElement)
    }
  }, [isDragging, originalElement, draggableElement])

  const onMouseMove = useCallback(async (event: any) => {
    if (!isDragging && mouseDownCoordinates) {
      const distance = getDistance(
        mouseDownCoordinates.x,
        mouseDownCoordinates.y,
        event.clientX,
        event.clientY
      )

      if (distance >= 10) {
        setIsDragging(true)
        onDraggingStart?.(event)
        if (originalElement) {
          const draggableElement = getDraggableElement(originalElement)
          setInitialDraggableStyle(originalElement, draggableElement)
          setDraggableElement(document.body.appendChild(draggableElement))
        }
      }
    }

    if (!draggableElement || !originalElement || !isDragging) return;


    onHoverDroppable(event)

    onMove(draggableElement, event.clientX, event.clientY)
  }, [draggableElement, originalElement, isDragging, mouseDownCoordinates])

  useEffect(() => {
    if (mouseDownCoordinates !== null) {
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    } else {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [mouseDownCoordinates, isDragging, draggableElement])

  const children = cloneElement(props.children, { onMouseDown, className })
  return <>
    {children}
  </>
}