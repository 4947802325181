import moment from "moment";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import Loader from "../../../Common/Components/Loader";
import { apiErrorParser } from "../../../Common/utils";
import InviteCodeCreateDto from "../../../Domain/Dtos/InviteCode/InviteCodeCreateDto";
import InviteCodeUpdateDto from "../../../Domain/Dtos/InviteCode/InviteCodeUpdateDto";
import { useModalPage } from "../../ModalPage/ModalPage";
import { INVITE_CODES_GET_ALL_QK } from "../../_hooks/Constants";
import useCreateInviteCode from "../../_hooks/InviteCode/useCreateInviteCode";
import useUpdateInviteCode from "../../_hooks/InviteCode/useUpdateInviteCode";
import useGetAllUserGroup from "../../_hooks/UserGroup/useGetAllUserGroup";
import InviteCodeForm, { InviteCodeFormSubmitData } from "./InviteCodeForm";

export type InviteCodeFormPageProps = {
  isCreate?: boolean
}

const initialValuesForm = {
  name: undefined,
  role: undefined,
  isStopped: false,
  aliveBefore: moment().add(1, 'days').utc().format(),
  userGroups: []
}

export default function InviteCodeFormPage(props: InviteCodeFormPageProps) {
  const { isCreate } = props
  const { state } = useLocation();
  const inviteCode = (state as any).inviteCode
  const userGroups = useGetAllUserGroup();
  const queryClient = useQueryClient()
  const createInviteCodeHook = useCreateInviteCode();
  const updateInviteCodeHook = useUpdateInviteCode();
  const modalPage = useModalPage();

  const onCreate = (formValue: InviteCodeFormSubmitData) => {
    return createInviteCodeHook.mutateAsync(InviteCodeCreateDto.fromObject({
      ...formValue,
      userGroupIds: formValue.userGroups?.map((x) => x.id),
      role: formValue.role?.role,
    }))
  }

  const onUpdate = (formValue: InviteCodeFormSubmitData) => {
    return updateInviteCodeHook.mutateAsync(InviteCodeUpdateDto.fromObject({
      ...formValue,
    }))
  }

  const onSubmit = (formValue: InviteCodeFormSubmitData, form: any, showErrors: (errors: any) => void) => {
    const createOrUpdate = isCreate ? onCreate : onUpdate
    return createOrUpdate(formValue)
      .then(() => modalPage.onClose())
      .then(() => setTimeout(() => queryClient.invalidateQueries(INVITE_CODES_GET_ALL_QK), 200))
      .catch((errors: any) => {
        showErrors(apiErrorParser(errors))
      })
  }

  if (userGroups.isLoading || userGroups.isFetching) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader />
    </div>
  }

  return <div className="user-form-page">
    <InviteCodeForm
      initialValues={inviteCode || initialValuesForm}
      onSubmit={onSubmit}
      userGroups={userGroups.data || []}
      onCancel={modalPage.onClose}
      isCreate={isCreate}
    />
  </div>
}