export default class InviteCodeCreateDto {
  constructor(
    readonly name: string,
    readonly isStopped: boolean,
    readonly aliveBefore: string,
    readonly role?: string,
    readonly userGroupIds?: Array<number>,
  ) { }

  static fromObject(obj: InviteCodeCreateDto) {
    return new InviteCodeCreateDto(
      obj.name,
      obj.isStopped,
      obj.aliveBefore,
      obj.role,
      obj.userGroupIds,
    )
  }
}