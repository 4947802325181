export default class StorageUpdateDto {
  constructor(
    readonly id: number,
    readonly name: string,
  ) { }

  static fromObject(obj: StorageUpdateDto) {
    return new StorageUpdateDto(
      obj.id,
      obj.name,
    )
  }
}