import IImportPasscardsRepository from "../../../Data/Repositories/ImportPasscardsRepository/IImportPasscardsRepository";
import ImportPasscardsRepository from "../../../Data/Repositories/ImportPasscardsRepository/ImportPasscardsRepository";
import IImportPasscardsService from "./IImportPasscardsService";

class ImportPasscardsService implements IImportPasscardsService {
  constructor(readonly repository: IImportPasscardsRepository) { }

  async importPasscards(file: File) {
    return this.repository.importPasscards(file)
  }

  async importPasscardsFromPassmanWithHierarchy(file: File) {
    return this.repository.importPasscardsFromPassmanWithHierarchy(file)
  }

  async importPasscardsFromBitwardenWithHierarchy(file: File) {
    return this.repository.importPasscardsFromBitwardenWithHierarchy(file)
  }
}

export default new ImportPasscardsService(ImportPasscardsRepository);