import { Outlet } from "react-router-dom";
import { useProfile } from "../../System/hooks/useProfile";
import PageNotFound from "./PageNotFound";

export default function IsAdministrator() {
  const { profile } = useProfile();

  const isAdmin = profile?.isAdministator

  return isAdmin ? <Outlet /> : <PageNotFound />
}