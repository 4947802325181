import FolderOutlined from '@mui/icons-material/FolderOutlined';
import classnames from "classnames";
import { forwardRef } from "react";
import Storage from "../../../Domain/Entities/Storage/Storage";

export type FavoritesProps = {
  className?: string;
  storage: Storage,
}

function AdminStorageName(props: FavoritesProps, ref: any) {
  const { storage } = props;

  const className = classnames(
    'user-storage-name',
    props.className,
  )

  return <span
    ref={ref}
    className={className}
  >
    <FolderOutlined />
    {storage.nameWithUserName}
  </span >
}

export default forwardRef(AdminStorageName);