export default class PasscardCreateDto {
  constructor(
    readonly name: string,
    readonly parentIds: number[],
    readonly url: string,
    readonly login: string,
    readonly password: string,
    readonly description: string,
    readonly storageIds: number[],
  ) { }


  static fromObject(obj: PasscardCreateDto) {
    return new PasscardCreateDto(
      obj.name,
      obj.parentIds,
      obj.url,
      obj.login,
      obj.password,
      obj.description,
      obj.storageIds,
    )
  }
}