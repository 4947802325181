import { compareByAlphabetically } from "../../../Common/utils"
import { PERMISSIONS } from "../../Enums/Permissions"

export default class DirectoryShort {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly permission: PERMISSIONS,
  ) { }

  static fromApi(obj: DirectoryShort) {
    return new DirectoryShort(
      obj.id,
      obj.name,
      obj.permission,
    )
  }
  static compare = (a: DirectoryShort, b: DirectoryShort) => compareByAlphabetically(a, b, 'name')
}

