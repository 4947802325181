import { Outlet } from "react-router-dom";

export default function AuthCommonPage() {
  return (
    <div className="auth-common-page">
      <div className="auth-common-page__content">
        <Outlet />
      </div>
    </div>
  );
}
