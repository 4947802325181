import { memo, useState } from "react"
import Button from "../../Common/Components/Button"
import UserGroup from "../../Domain/Entities/UserGroup/UserGroup"
import UserGroupEditItem from "./UserGroupEditItem"
import UserGroupListItem from "./UserGroupListItem"

export type UserGroupListType = {
  userGroups: Array<UserGroup>
  selectedUserGroup?: UserGroup
  onSelectedUserGroup?: (event: any, userGroup: UserGroup) => void
  canCreateDirectory?: boolean
  canUpdateDirectory?: boolean
  canDeleteDirectory?: boolean
  onCreate?: (value: string) => Promise<any>
  onUpdate?: (userGroup: UserGroup, newName: string) => Promise<any>
  onDeleteClick?: (userGroup: UserGroup) => void
}

function UserGroupList(props: UserGroupListType) {
  const { userGroups, selectedUserGroup, onSelectedUserGroup, onCreate, onUpdate, onDeleteClick } = props;
  const [isCreating, setIsCreating] = useState(false);
  const [isCreateInProgress, setIsCreateInProgress] = useState(false);

  const onCreateHandler = (value: string) => {
    setIsCreateInProgress(true)
    return onCreate?.(value)
      .finally(() => {
        setIsCreateInProgress(false)
        setIsCreating(false)
      })
  }

  return <div className="user-group-list">
    <div className="user-group-list__add" >
      {isCreating
        ? <UserGroupEditItem
          show={isCreating}
          onCreate={onCreateHandler}
          onCancel={() => setIsCreating(false)}
          isLoading={isCreateInProgress}
        />
        : <Button variant="outlined" size="small" onClick={() => setIsCreating(true)}>Добавить группу</Button>
      }
    </div>
    {
      userGroups.map((x) => {
        return <UserGroupListItem
          key={x.id}
          userGroup={x}
          canDelete
          canUpdate
          isSelected={x.id === selectedUserGroup?.id}
          onSelectedUserGroup={onSelectedUserGroup}
          onUpdate={onUpdate}
          onDeleteClick={onDeleteClick}
        />
      })
    }
  </div>
}

export default memo(UserGroupList)