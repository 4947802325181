import Apps from "@mui/icons-material/Apps";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import { useState } from "react";
import User from "../../../Domain/Entities/User/User"
import UserCardList from "./UserCardList";
import UserTableList from "./UserTableList";
import Divider from "@mui/material/Divider";

export type UserListProps = {
  users: Array<User>
  onUpdateClick?: (user: User) => void
  onCreateClick?: (event: any) => void
  onDeleteClick?: (user: User) => void
  onResetPasswordClick?: (user: User) => void
  onUserBlockClick?: (event: any, user: User) => void
  canDelete?: (user: User) => boolean
  canUpdate?: (user: User) => boolean
  canBlock?: (user: User) => boolean
  canResetPassword?: (user: User) => boolean
  canCreate?: boolean
}

export default function UserList(props: UserListProps) {
  const {
    users, onDeleteClick, onUpdateClick, onUserBlockClick, canBlock, canDelete,
    canUpdate, canCreate, onCreateClick, canResetPassword, onResetPasswordClick
  } = props;
  const [listFormat, setListFormat] = useState(false);

  if (users.length === 0) {
    return <div className="user-list--empty">Список пуст</div>
  }

  return <>
    <ButtonGroup className="user-list__list-format" variant="text">
      <IconButton
        onClick={() => setListFormat(false)}
        title="Список в виде таблицы"
      >
        <FormatListBulleted color={listFormat ? undefined : "primary"} />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <IconButton
        onClick={() => setListFormat(true)}
        title="Список в виде карточек"
      >
        <Apps color={listFormat ? "primary" : undefined} />
      </IconButton>
    </ButtonGroup>
    {
      listFormat
        ? <UserCardList
          onDeleteClick={onDeleteClick}
          onUpdateClick={onUpdateClick}
          onCreateClick={onCreateClick}
          users={users}
          onUserBlockClick={onUserBlockClick}
          canDelete={canDelete}
          canUpdate={canUpdate}
          canCreate={canCreate}
          canBlock={canBlock}
          canResetPassword={canResetPassword}
          onResetPasswordClick={onResetPasswordClick}
        />
        : <UserTableList
          onDeleteClick={onDeleteClick}
          onUpdateClick={onUpdateClick}
          onCreateClick={onCreateClick}
          onResetPasswordClick={onResetPasswordClick}
          users={users}
          onUserBlockClick={onUserBlockClick}
          canDelete={canDelete}
          canUpdate={canUpdate}
          canCreate={canCreate}
          canBlock={canBlock}
          canResetPassword={canResetPassword}
        />
    }
  </>
}