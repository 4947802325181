import ChangePasswordDto from "../../../Domain/Dtos/Profile/ChangePasswordDto";
import Profile from "../../../Domain/Entities/Profile/Profile";
import { Api } from "../../Api/Api";
import IProfileRepository from "./IProfileRepository";
import ProfileUpdateDto from "../../../Domain/Dtos/Profile/ProfileUpdateDto";

class AuthorizationRepository implements IProfileRepository {
  constructor(readonly api: typeof Api) { }

  async changePassword(data: ChangePasswordDto) {
    return this.api.changePassword(data)
      .catch((error) => {
        throw error.response;
      });
  }

  async profile() {
    return this.api.profile()
      .then(Profile.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: ProfileUpdateDto) {
    return this.api.profileUpdate(data)
      .then(Profile.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new AuthorizationRepository(Api);