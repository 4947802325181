import ChangePasswordDto from "../../Domain/Dtos/Profile/ChangePasswordDto";
import LoginDto from "../../Domain/Dtos/Authorization/LoginDto";
import RegistrationDto from "../../Domain/Dtos/Authorization/RegistrationDto";
import DirectoryCreateDto from "../../Domain/Dtos/Directory/DirectoryCreateDto";
import DirectoryUpdateDto from "../../Domain/Dtos/Directory/DirectoryUpdateDto";
import PasscardCreateDto from "../../Domain/Dtos/Passcard/PasscardCreateDto";
import PasscardUpdateDto from "../../Domain/Dtos/Passcard/PasscardUpdateDto";
import UserUpdateDto from "../../Domain/Dtos/User/UserUpdateDto";
import UserGroupCreateDto from "../../Domain/Dtos/UserGroup/UserGroupCreateDto";
import UserGroupMatrixUpdateDto from "../../Domain/Dtos/UserGroupMatrix/UserGroupMatrixUpdateDto";
import UserGroupUpdateDto from "../../Domain/Dtos/UserGroup/UserGroupUpdateDto";
import HttpClient from "../HttpClient/HttpClient";
import IHttpClient from "../HttpClient/IHttpClient";
import ProfileUpdateDto from "../../Domain/Dtos/Profile/ProfileUpdateDto";
import NewPasswordDto from "../../Domain/Dtos/Authorization/NewPasswordDto";
import RestoreDto from "../../Domain/Dtos/Authorization/RestoreDto";
import UserCreateDto from "../../Domain/Dtos/User/UserCreateDto";
import InviteCodeCreateDto from "../../Domain/Dtos/InviteCode/InviteCodeCreateDto";
import InviteCodeUpdateDto from "../../Domain/Dtos/InviteCode/InviteCodeUpdateDto";
import StorageUpdateDto from "../../Domain/Dtos/Storage/StorageUpdateDto";

class _Api {
  constructor(private client: IHttpClient) { }

  async getDirectories() {
    return this.client.get('/directories')
      .then((response) => response.data);
  }
  async getDirectoriesShort() {
    return this.client.get('/directories/short')
      .then((response) => response.data);
  }
  async createDirectory(data: DirectoryCreateDto) {
    return this.client.post('/directories/create', data)
      .then((response) => response.data);
  }
  async updateDirectory(data: DirectoryUpdateDto) {
    return this.client.put('/directories/update/', data)
      .then((response) => response.data);
  }
  async moveDirectory(directoryId: number, parentId?: number) {
    return this.client.put(`/directories/move/${directoryId}/${parentId}`)
      .then((response) => response.data);
  }
  async deleteDirectory(id: number) {
    return this.client.delete(`/directories/delete/${id}`)
      .then((response) => response.data);
  }

  async getPasscards(directoryId?: number, search?: string, storageId?: number) {
    return this.client.get('/passcards', { params: { directoryId, search, storageId } })
      .then((response) => response.data);
  }
  async getPasscardsByDirectoryId(directoryId: number) {
    return this.client.get(`/passcards/directory/${directoryId}`)
      .then((response) => response.data);
  }
  async createPasscard(data: PasscardCreateDto) {
    return this.client.post('/passcards/create', data)
      .then((response) => response.data);
  }
  async updatePasscard(data: PasscardUpdateDto) {
    return this.client.put('/passcards/update/', data)
      .then((response) => response.data);
  }
  async deletePasscard(id: number) {
    return this.client.delete(`/passcards/delete/${id}`)
      .then((response) => response.data);
  }

  async changePassword(data: ChangePasswordDto) {
    return this.client.put('/user/change-password', data)
      .then((response) => response.data);
  }
  async login(data: LoginDto) {
    return this.client.post('/user/login', data)
      .then((response) => response.data);
  }
  async logout() {
    return this.client.get('/user/logout/')
      .then((response) => response.data);
  }
  async profile() {
    return this.client.get('/user/current-user/')
      .then((response) => response.data);
  }
  async register(data: RegistrationDto) {
    return this.client.post(`/user/register?inviteCode=${data.inviteCode}`, data)
      .then((response) => response.data);
  }
  async startRestore(data: RestoreDto) {
    return this.client.post('/user/restore-step-one', data)
      .then((response) => response.data);
  }
  async checkRestoreCode(code: string): Promise<void> {
    return this.client.get(`/user/restore-check-code/${code}`)
      .then((response) => response.data);
  }
  async setNewPassword(data: NewPasswordDto): Promise<void> {
    return this.client.post(`/user/restore-step-two`, data)
      .then((response) => response.data);
  }
  async checkInviteCode(code: string): Promise<void> {
    return this.client.get(`/user/invite-check/${code}`)
      .then((response) => response.data);
  }
  async profileUpdate(data: ProfileUpdateDto) {
    return this.client.put('/user/update', data)
      .then((response) => response.data);
  }

  async getUserGroups() {
    return this.client.get('/admin/user-groups')
      .then((response) => response.data);
  }
  async createUserGroup(data: UserGroupCreateDto) {
    return this.client.post('/admin/user-groups/create', data)
      .then((response) => response.data);
  }
  async updateUserGroup(data: UserGroupUpdateDto) {
    return this.client.put('/admin/user-groups/update/', data)
      .then((response) => response.data);
  }
  async deleteUserGroup(id: number) {
    return this.client.delete(`/admin/user-groups/delete/${id}`)
      .then((response) => response.data);
  }

  async getUsers() {
    return this.client.get('/admin/users')
      .then((response) => response.data);
  }
  async getUsersShort() {
    return this.client.get('/admin/users/short')
      .then((response) => response.data);
  }
  async updateUser(data: UserUpdateDto) {
    return this.client.put('/admin/users/update/', data)
      .then((response) => response.data);
  }
  async createUser(data: UserCreateDto) {
    return this.client.post('/admin/users/create/', data)
      .then((response) => response.data);
  }
  async deleteUser(id: number) {
    return this.client.delete(`/admin/users/delete/${id}`)
      .then((response) => response.data);
  }
  async enabledUser(id: number) {
    return this.client.post(`/admin/users/enable/${id}`)
      .then((response) => response.data);
  }
  async disabledUser(id: number) {
    return this.client.post(`/admin/users/disable/${id}`)
      .then((response) => response.data);
  }
  async dropUserPassword(id: number) {
    return this.client.post(`/admin/users/drop-password/${id}`)
      .then((response) => response.data);
  }

  async getUserGroupMatrix() {
    return this.client.get('/admin/user-groups/matrix')
      .then((response) => response.data);
  }
  async updateUserGroupMatrix(data: UserGroupMatrixUpdateDto) {
    return this.client.put(`/admin/user-groups/matrix/update/${data.userGroupId}/${data.directoryId}/${data.permission}`)
      .then((response) => response.data);
  }

  async getInviteCodes() {
    return this.client.get('/admin/invite-codes')
      .then((response) => response.data);
  }
  async updateInviteCode(data: InviteCodeUpdateDto) {
    return this.client.put('/admin/invite-codes/update/', data)
      .then((response) => response.data);
  }
  async createInviteCode(data: InviteCodeCreateDto) {
    return this.client.post('/admin/invite-codes/create/', data)
      .then((response) => response.data);
  }
  async deleteInviteCode(id: number) {
    return this.client.delete(`/admin/invite-codes/delete/${id}`)
      .then((response) => response.data);
  }
  async enableInviteCode(id: number) {
    return this.client.post(`/admin/invite-codes/enable/${id}`)
      .then((response) => response.data);
  }
  async disableInviteCode(id: number) {
    return this.client.post(`/admin/invite-codes/disable/${id}`)
      .then((response) => response.data);
  }

  async exportPasscards(type: string) {
    return this.client.get(`/export/${type}`, { responseType: 'blob' })
      .then((response) => response.data);
  }
  async importPasscards(data: FormData) {
    return this.client.post('/import', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => response.data);
  }

  async exportPasscardsWithHierarchyForPassman(encrypted?: boolean) {
    return this.client.get('/export/hierarchy/passman', { responseType: 'blob', params: { encrypted } })
      .then((response) => response.data);
  }

  async importPasscardsFromPassmanWithHierarchy(data: FormData) {
    return this.client.post('/import/hierarchy/passman', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => response.data);
  }

  async importPasscardsFromBitwardenWithHierarchy(data: FormData) {
    return this.client.post('/import/hierarchy/bitwarden', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => response.data);
  }

  async getPasscardFavorites() {
    return this.client.get('/passcards/favorite')
      .then((response) => response.data);
  }

  async addPasscardToFavorites(id: number) {
    return this.client.post(`/passcards/favorite/add/${id}`)
      .then((response) => response.data);
  }
  async removePasscardFromFavorites(id: number) {
    return this.client.post(`/passcards/favorite/remove/${id}`)
      .then((response) => response.data);
  }
  async getStorages() {
    return this.client.get('/storages')
      .then((response) => response.data);
  }

  async getStoragesShort() {
    return this.client.get('/storages/short')
      .then((response) => response.data);
  }

  async getUserStorage() {
    return this.client.get('/storages/user')
      .then((response) => response.data);
  }

  async updateStorage(data: StorageUpdateDto) {
    return this.client.put('storages/update', data)
      .then((response) => response.data);
  }
}

export const Api = new _Api(HttpClient);