import { Person } from "@mui/icons-material";
import { Avatar, CardContent, CardHeader, Checkbox, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import Email from "../../../Common/Components/Email/Email";
import User from "../../../Domain/Entities/User/User";
import Groupped from "../../../Common/Components/Groupped";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import LockResetOutlined from "@mui/icons-material/LockResetOutlined";

export type UserCardListItemProps = {
  user: User
  onUpdateClick?: (user: User) => void
  onDeleteClick?: (user: User) => void
  onUserBlockClick?: (event: any, user: User) => void
  onResetPasswordClick?: (user: User) => void
  canDelete?: boolean
  canUpdate?: boolean
  canBlock?: boolean
  canResetPassword?: boolean
}

export default function UserCardListItem(props: UserCardListItemProps) {
  const {
    user, onDeleteClick, onUpdateClick, onUserBlockClick, canBlock, canDelete,
    canUpdate, canResetPassword, onResetPasswordClick
  } = props;
  return <Card sx={{ display: 'flex', mr: 1, mb: 1 }} className="user-card-list-item">
    <CardHeader
      sx={{ px: 1, py: 1 }}
      avatar={<Avatar>
        <Person />
      </Avatar>
      }
    />
    <CardContent sx={{ px: 1, py: 1 }} className="user-card-list-item__content">

      <div className="user-card-list-item__header">
        <span className="user-card-list-item__user-full-name" title={user.fullName}>{user.fullName}</span>
        <span className="user-card-list-item__actions">
          {canUpdate && <IconButton
            title="Изменить пользователя"
            onClick={() => onUpdateClick?.(user)}
          >
            <ModeEditOutlineOutlined />
          </IconButton>
          }
          {canDelete && <IconButton
            title="Удалить пользователя"
            onClick={() => onDeleteClick?.(user)}
          >
            <DeleteOutlined color="error" />
          </IconButton>
          }
          {canResetPassword && <IconButton
            title="Сбросить пароль пользователю"
            onClick={() => onResetPasswordClick?.(user)}
          >
            <LockResetOutlined color="error" />
          </IconButton>
          }
        </span>
      </div>
      <div className="user-card-list-item">
        <span className="user-card-list-item__field-title">Почта:</span>
        <span className="user-card-list-item__field-value">
          <Email email={user.email} />
        </span>
      </div>
      <div className="user-card-list-item">
        <span className="user-card-list-item__field-title">Роль:</span>
        <span className="user-card-list-item__field-value">{user.roleName}</span>
      </div>
      <div className="user-card-list-item">
        <span className="user-card-list-item__field-title">Группы пользователей:</span>
        <span className="user-card-list-item__field-value">
          <Groupped list={user.userGroups
            .map((x) => x.name)} count={1}
          />
        </span>
      </div>
      <div className="user-card-list-item">
        {canBlock && <><span className="user-card-list-item__field-title">Заблокирован:</span>
          <span className="user-card-list-item__field-value">
            <Checkbox onChange={(event, checked) => onUserBlockClick?.(checked, user)} checked={user.isBlocked} />
          </span>
        </>
        }
      </div>
    </CardContent>
  </Card>
}