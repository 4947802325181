import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import { apiErrorParser } from "../../../Common/utils";
import PasscardUpdateDto from "../../../Domain/Dtos/Passcard/PasscardUpdateDto";
import Passcard from "../../../Domain/Entities/Passcard/Passcard";
import Storage from "../../../Domain/Entities/Storage/Storage";
import { useModal } from "../../../System/hooks/useModal";
import { useProfile } from "../../../System/hooks/useProfile";
import { PASSCARD_GET_ALL_QK } from "../../_hooks/Constants";
import useDeletePasscard from "../../_hooks/Passcard/useDeletePasscard";
import useGetAllPasscard from "../../_hooks/Passcard/useGetAllPasscard";
import useMovePasscard from "../../_hooks/Passcard/useMovePasscard";
import useUpdatePasscard from "../../_hooks/Passcard/useUpdatePasscard";
import useGetAllStorage from "../../_hooks/Storage/useGetAllStorage";
import { StorageList } from "./StorageList";

const selectDirectoryId = -2;
const searchValue = undefined;

export function StorageListPage() {
  const [selectStorage, setSelectStorage] = useState<Storage | undefined>(undefined)

  const storagesHook = useGetAllStorage((data) => {
    if (data.length > 0) {
      setSelectStorage(data[0])
    }
  })

  const passcards = useGetAllPasscard(selectDirectoryId, searchValue, selectStorage?.id ?? -2);

  const updatePasscard = useUpdatePasscard(() => passcards.refetch());
  const deletePasscard = useDeletePasscard(() => passcards.refetch());
  const movePasscard = useMovePasscard();
  const queryClient = useQueryClient()
  const modal = useModal();
  const navigate = useNavigate();
  const { profile } = useProfile();

  useEffect(() => {
    passcards.refetch();
  }, [selectStorage])


  const setSelectedDirectoryCallback = useCallback((event: any, storage: Storage) => {
    setSelectStorage(storage)
  }, [setSelectStorage])

  const onUpdate = useCallback((passcard: Passcard) => {
    return updatePasscard
      .mutateAsync(passcard.toUpdateDto())
      .catch((errors) => modal.showInformationModal({
        title: passcard.url,
        contentText: apiErrorParser(errors).common || 'Произошла ошибка',
        onAccepte: modal.closeModal,
      }))
  }, [updatePasscard.mutateAsync])

  const onDeleteFromAllDirectory = useCallback((passcard: Passcard) => {
    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => onAccepteDeleteFromAllDirectory(passcard),
      title: passcard.url,
      contentText: 'Удалить данный пароль из всех папок?',
    });
  }, [])

  const onAccepteDeleteFromStorage = (passcard: Passcard) => {
    if (!selectStorage) return;

    const movingDto: PasscardUpdateDto = PasscardUpdateDto.fromObject({
      ...passcard,
      storageIds: [...passcard.storageIds.filter((x) => x !== selectStorage.id)],
    });

    return movePasscard.mutateAsync({ id: passcard.id, update: movingDto })
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Пароль успешно удален из папки',
          onAccepte: modal.closeModal,
        })
      })
      .then(() => {
        const newPasscardList = (passcards.data ?? []).filter((x) => x.id !== passcard.id)
        queryClient.setQueryData([PASSCARD_GET_ALL_QK, selectDirectoryId, searchValue, selectStorage?.id], newPasscardList)
      })
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onDeleteFromDirectory = useCallback((passcard: Passcard) => {
    let deleteFromOrDeleteFromAll = onAccepteDeleteFromStorage
    if (passcard.storageIds.length === 1 && passcard.parentIds.length === 0) {
      deleteFromOrDeleteFromAll = onAccepteDeleteFromAllDirectory
    }

    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => deleteFromOrDeleteFromAll(passcard),
      title: passcard.url,
      contentText: 'Удалить данный пароль из папки?',
    });
  }, [passcards.data, selectStorage])

  const onPasscardMoveClick = useCallback((passcard: Passcard) => {
    navigate(`/user-storages/passcard/${passcard.id}/move`, {
      state: {
        modalProps: { modalTitle: "Расположение пароля" },
        selectStorage,
        passcard,
      }
    })
  }, [selectStorage])

  const onAccepteDeleteFromAllDirectory = (passcard: Passcard) => {
    return deletePasscard.mutateAsync(passcard.id)
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Пароль успешно удален',
          onAccepte: modal.closeModal,
        })
      })
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const canDeletePasscardFromAllDirectory = useCallback(() => {
    return Boolean(profile?.isAdministator)
  }, [profile])

  const canDeletePasscardFromDirectory = useCallback(() => {
    return selectStorage !== undefined
  }, [selectStorage])

  const canMove = useCallback((passcard: Passcard) => {
    return selectStorage !== undefined
  }, [profile, selectStorage])

  const canFavorites = useCallback(() => false, [])

  const canEdit = useCallback((passcard: Passcard) => {
    return selectStorage !== undefined
  }, [selectStorage])

  const canCreatePasscard = useMemo(() => {
    return false;
    // return selectStorage !== undefined
  }, [selectStorage])

  return <div className="storage-list-page">
    <StorageList
      storages={storagesHook.data ?? []}
      isStoragesLoading={storagesHook.isLoading || storagesHook.isFetching}
      selectStorage={selectStorage}
      onSelectStorage={setSelectedDirectoryCallback}
      passcards={passcards.data ?? []}
      isPasscardsLoading={passcards.isLoading || passcards.isFetching}
      onChange={onUpdate}
      onDeleteFromAllDirectory={onDeleteFromAllDirectory}
      onDeleteFromDirectory={onDeleteFromDirectory}
      onMoveButtonClick={onPasscardMoveClick}
      canDeletePasscardFromAllDirectory={canDeletePasscardFromAllDirectory}
      canDeletePasscardFromDirectory={canDeletePasscardFromDirectory}
      canMove={canMove}
      canFavorites={canFavorites}
      canEdit={canEdit}
      canCreate={canCreatePasscard}
    />
    <Outlet />
  </div>
}