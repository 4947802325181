import { Autocomplete, AutocompleteProps, AutocompleteRenderOptionState, FormControl, Typography } from '@mui/material';
import TextField from '../TextField';
import classnames from "classnames"

export type ISelectFormControlProps = Omit<AutocompleteProps<any, boolean, boolean, boolean>, "options" | "renderInput" | "renderOption"> & {
  selectList: Array<{ id: number, name: string }>;
  isInvalid?: boolean;
  textField?: string,
  onChange?: (value: Array<any> | any) => void,
  label?: string
}

export default function SelectList(props: ISelectFormControlProps) {
  const {
    selectList, isInvalid, label, onChange, value, multiple, className, fullWidth,
    textField = 'name', noOptionsText = "Список пуст", openOnFocus = true, ...rest
  } = props;

  const onChangeHandler = (event: any, newValue: any) => {
    onChange?.(newValue)
  }

  const autocompleteClassName = classnames('select-list', className, {
    "select-list--invalid": isInvalid
  })

  return (
    <FormControl className="select-list-form-control" fullWidth={fullWidth} error={isInvalid}>
      <Autocomplete
        multiple={multiple}
        onChange={onChangeHandler}
        value={value}
        fullWidth={fullWidth}
        className={autocompleteClassName}
        getOptionLabel={(option) => option[textField] || ''}
        options={selectList}
        noOptionsText={noOptionsText}
        disableCloseOnSelect={multiple}
        openOnFocus={openOnFocus}
        renderOption={(props, option, state) => <Typography {...props} key={option.id}>
          {option[textField]}
        </Typography>
        }
        renderInput={(params) => <TextField
          {...params}
          label={label}
        />
        }
        {...rest}
      />
    </FormControl>
  );
}
