import Button from "@mui/material/Button";
import { useQueryClient } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import ConfirmationDelete from "../../Common/Components/ConfirmationDelete/ConfirmationDelete";
import Loader from "../../Common/Components/Loader";
import { apiErrorParser } from "../../Common/utils";
import InviteCode from "../../Domain/Entities/InviteCode/InviteCode"
import { useModal } from "../../System/hooks/useModal";
import { INVITE_CODES_GET_ALL_QK } from "../_hooks/Constants";
import useDeleteInviteCode from "../_hooks/InviteCode/useDeleteInviteCode";
import useDisableInviteCode from "../_hooks/InviteCode/useDisableInviteCode";
import useEnableInviteCode from "../_hooks/InviteCode/useEnableInviteCode";
import useGetAllInviteCodes from "../_hooks/InviteCode/useGetAllInviteCodes"
import InviteCodeList from "./InviteCodeList"

const updateInviteCodeInCache = (cache: InviteCode[], updatingValue: InviteCode) => {
  const newData = cache;
  const index = newData.findIndex((x) => x.id === updatingValue?.id)

  newData[index] = updatingValue
  return newData;
}

export default function InviteCodePage() {
  const getInviteCodesHook = useGetAllInviteCodes()
  const disableInviteCodeHook = useDisableInviteCode()
  const enableInviteCodeHook = useEnableInviteCode()
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const modal = useModal();

  const isLoading = getInviteCodesHook.isLoading || getInviteCodesHook.isFetching

  const onUpdateClick = async (inviteCode: InviteCode) => {
    navigate(`/invite-codes/${inviteCode.id}/update`, {
      state: {
        modalProps: { modalTitle: "Редактирование инвайт-кода" },
        inviteCode,
      }
    })
  }

  const onCreateClick = async () => {
    navigate('/invite-codes/create', {
      state: {
        modalProps: { modalTitle: "Создание инвайт-кода" },
      }
    })
  }

  const onInvideCodeStopClick = (newValue: boolean, inviteCode: InviteCode) => {
    const newInviteCode = InviteCode.fromObject({ ...inviteCode, role: inviteCode.role.role, isStopped: newValue })
    const newData = updateInviteCodeInCache(getInviteCodesHook.data ?? [], newInviteCode)

    queryClient.setQueryData(INVITE_CODES_GET_ALL_QK, newData)

    const hook = newValue ? disableInviteCodeHook : enableInviteCodeHook
    return hook.mutateAsync(inviteCode.id)
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Приостановка инвайт-кодов',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  return <div className="invite-code-page">
    <div className="invite-code-page__top-panel">
      <Button variant="outlined" onClick={onCreateClick}>Добавить инвайт-код</Button>
    </div>
    {isLoading && <Loader />}
    {!isLoading && <ConfirmationDelete<InviteCode>
      useDeleteHook={useDeleteInviteCode}
      confirmationText="Вы точно хотите удалить данный инвайт-код?"
      title={(x) => x.name}
      successText="Инвайт-код успешно удален"
      dataToHook={(x) => x.id}
      onSuccessInformationModalClick={() => getInviteCodesHook.refetch()}
      propsName="onDeleteButtonClick"
    >
      <InviteCodeList
        list={getInviteCodesHook.data ?? []}
        onEditButtonClick={onUpdateClick}
        onInviteCodeStopClick={onInvideCodeStopClick}
      />
    </ConfirmationDelete>
    }
    <Outlet />
  </div >
}