import InviteCodeCreateDto from "../../../Domain/Dtos/InviteCode/InviteCodeCreateDto";
import InviteCodeUpdateDto from "../../../Domain/Dtos/InviteCode/InviteCodeUpdateDto";
import InviteCode from "../../../Domain/Entities/InviteCode/InviteCode";
import { Api } from "../../Api/Api";
import IInviteCodeRepository from "./IInviteCodeRepository";

class InviteCodeRepository implements IInviteCodeRepository {
  constructor(readonly api: typeof Api) { }

  async getAll() {
    return this.api.getInviteCodes()
      .then((x) => x.map(InviteCode.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async create(data: InviteCodeCreateDto) {
    return this.api.createInviteCode(data)
      .then(InviteCode.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: InviteCodeUpdateDto) {
    return this.api.updateInviteCode(data)
      .then(InviteCode.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async delete(id: number) {
    return this.api.deleteInviteCode(id)
      .catch((error) => {
        throw error.response;
      });
  }

  async enable(id: number) {
    return this.api.enableInviteCode(id)
      .catch((error) => {
        throw error.response;
      });
  }

  async disable(id: number) {
    return this.api.disableInviteCode(id)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new InviteCodeRepository(Api);