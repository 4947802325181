import UserGroupMatrixUpdateDto from "../../../Domain/Dtos/UserGroupMatrix/UserGroupMatrixUpdateDto";
import UserGroupMatrix from "../../../Domain/Entities/UserGroup/UserGroupMatrix";
import { Api } from "../../Api/Api";
import IUserGroupMatrixRepository from "./IUserGroupMatrixRepository";

class UserGroupMatrixRepository implements IUserGroupMatrixRepository {
  constructor(readonly api: typeof Api) { }

  async getAll() {
    return this.api.getUserGroupMatrix()
      .then((x) => x.map(UserGroupMatrix.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: UserGroupMatrixUpdateDto) {
    return this.api.updateUserGroupMatrix(data)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new UserGroupMatrixRepository(Api);