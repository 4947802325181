import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useLocation, useNavigate } from "react-router-dom";
import CapsLockDetect from "../../../Common/Components/CapsLockDetect/CapsLockDetect";
import { apiErrorParser } from "../../../Common/utils";
import LoginDto from "../../../Domain/Dtos/Authorization/LoginDto";
import { useProfile } from "../../../System/hooks/useProfile";
import AuthorizationForm from "./AuthorizationForm";

const getAuthError = (errors: any) => {
  const status = errors?.status;
  const apiErrors = apiErrorParser(errors);

  switch (true) {
    case status === 400 && Boolean(apiErrors?.isBlocked):
      return apiErrors?.isBlocked
    case status === 400:
    case status === 404:
      return 'Неверная почта/имя пользователя или пароль'
    default:
      return apiErrors?.common
  }
}

export default function AuthorizationPage() {
  const profileHook = useProfile();
  const navigateHook = useNavigate();
  const { state } = useLocation();
  const redirectPath = (state as any)?.prevLocation || '/'

  const onSubmit = (
    formData: LoginDto,
    form: any,
    showErrors: (errors: any) => void
  ) => {
    return profileHook.login(formData)
      .then(() => navigateHook(redirectPath, { replace: true }))
      .catch((errors: any) => {
        const showError = {
          common: getAuthError(errors)
        }
        showErrors(showError)
      })
  }
  return <div className="authorization-page">
    <Card>
      <CardContent>
        <AuthorizationForm onSubmit={onSubmit} />
      </CardContent>
    </Card>
    <CapsLockDetect />
  </div>
}