import useExportPasscards from "../_hooks/ExportPasscards/useExportPasscards";
import useExportPasscardsWithHierarchyForPassman from "../_hooks/ExportPasscards/useExportPasscardsWithHierarchyForPassman";
import DownloadFileButton from "./DownloadFileButton";

export default function ExportPage() {

  return <div className="export-page">
    <div className="export-page__title">
      <h3>Экспорт паролей</h3>
    </div>
    <div className="export-page__content">
      <DownloadFileButton
        text="Скачать в формате CSV"
        dataValue="csv"
        downloadFileHook={useExportPasscards}
      />
      <DownloadFileButton
        text="Скачать в формате XLSX"
        dataValue="xlsx"
        downloadFileHook={useExportPasscards}
      />
      <DownloadFileButton
        text="Скачать в формате для Passman с иерархией"
        downloadFileHook={useExportPasscardsWithHierarchyForPassman}
      />
    </div>
  </div>
}