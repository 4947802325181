export function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export interface ApiError {
  status: number,
  data: {
    errorCode: string
    errorMessage: string;
    propertyName: string;
  }[];
}

export function toLowerCaseFirstLetter(value: string) {
  return value.charAt(0).toLowerCase() + value.slice(1)
}

export function apiErrorParser(errors: ApiError): Partial<{ [otherProp: string]: string }> {
  let result = {};

  if (errors === undefined || errors?.status === 500) {
    result = {
      common: 'Произошла ошибка. Попробуйте позже',
    }
  }

  if (errors?.status === 403) {
    result = {
      common: 'Отсутствуют права на совершение данной операции',
    }
  }

  if (typeof errors?.data === 'string'
    && errors?.status !== 500
    && errors?.status !== 403
  ) {
    result = {
      common: errors?.data,
    }
  }

  if (Array.isArray(errors?.data)) {
    errors?.data?.reduce((acc: any, current: any) => {
      acc[toLowerCaseFirstLetter(current.propertyName)] = current.errorMessage
      return acc;
    }, result)
  }

  return result;
}

export function primitiveOrFunction<FirstType>(primitiveOrFunction: FirstType, dataToFunction: { [key: string]: any }) {
  if (typeof primitiveOrFunction === "function") return primitiveOrFunction(dataToFunction)
  return Reflect.get(dataToFunction, primitiveOrFunction as unknown as string);
}

export function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line no-useless-escape
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const compareByAlphabetically = (a: any, b: any, fieldName: string) => {
  if (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) return -1;
  if (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) return 1;
  return 0;
}

export function not<F, S>(a: readonly F[], b: readonly S[], fieldName: string) {
  return a.filter((aValue) => b.findIndex((bValue) => (aValue as any)[fieldName] === (bValue as any)[fieldName]) === -1);
}

export function intersection<F, S>(a: readonly F[], b: readonly S[], fieldName: string) {
  return a.filter((aValue) => b.findIndex((bValue) => (aValue as any)[fieldName] === (bValue as any)[fieldName]) !== -1);
}

export function updateItemInList(list: Array<any>, itemId: number, newValue: (() => any) | any) {
  const newList = [...list]
  const index = newList.findIndex((x) => x.id == itemId)

  if (index !== -1) {
    newList[index] = primitiveOrFunction(newValue, newList[index])
  }

  return newList;
}