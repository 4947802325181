import { compareByAlphabetically } from "../../../Common/utils"
import StorageUpdateDto from "../../Dtos/Storage/StorageUpdateDto"
import UserShort from "../User/UserShort"

export default class StorageShort {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly user: UserShort,
  ) { }

  get nameWithUserName() {
    return `${this.name} (${this.user.fullName})`
  }

  static fromApi(obj: StorageShort | any) {
    return new StorageShort(
      obj.id,
      obj.name,
      UserShort.fromApi(obj.user),
    )
  }

  toUpdateDto() {
    return new StorageUpdateDto(
      this.id,
      this.name,
    )
  }

  static compare = (a: StorageShort, b: StorageShort) => compareByAlphabetically(a, b, 'nameWithUserName')
}