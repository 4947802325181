export default class UserGroupDirectoryPermissionRelation {
  constructor(
    readonly directoryId: number,
    readonly permission: number,
  ) { }

  static fromApi(obj: UserGroupDirectoryPermissionRelation) {
    return new UserGroupDirectoryPermissionRelation(
      obj.directoryId,
      obj.permission
    )
  }
}
