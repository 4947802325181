import CreateNewFolderOutlined from "@mui/icons-material/CreateNewFolderOutlined";
import Button from "@mui/material/Button/Button";
import { memo, useCallback, useState } from "react";
import Draggable from "../../../Common/Components/DragAndDrop/Draggable";
import DirectoryEntity from "../../../Domain/Entities/Directory/Directory";
import { PERMISSIONS } from "../../../Domain/Enums/Permissions";
import DirectoryCreate from "../DirectoryCreate";
import DirectoryTreeItem from "./DirectoryTreeItem";

export type DirectoryTreeProps = {
  directories: Array<DirectoryEntity>,
  onSelectDirectory?: (event: any, directory: DirectoryEntity) => void,
  onSelectFavoites?: (event: any) => void,
  selectedDirectory?: DirectoryEntity,
  isSelectedFavorites?: boolean,
  canCreateDirectory?: boolean,
  canCreateRootDirectory?: boolean,
  canUpdateDirectory?: boolean,
  canDeleteDirectory?: boolean,
  onMoveClick?: (directoryId: number, parenrId?: number) => void,
  onReloadTree?: () => Promise<any>;
}

const displayingDirectory = (
  directory: DirectoryEntity,
  selectedDirectory?: DirectoryEntity,
  onSelectDirectory?: (event: any, directory: DirectoryEntity) => void,
  canCreateDirectory?: boolean,
  canUpdateDirectory?: boolean,
  canDeleteDirectory?: boolean,
  onReloadTree?: () => Promise<any>,
  onMoveDirectory?: (event: any) => void,
) => {
  const children = directory.childrens.map((x) => displayingDirectory(x, selectedDirectory, onSelectDirectory, canCreateDirectory, canUpdateDirectory, canDeleteDirectory, onReloadTree));
  return <Draggable
    key={directory.id}
    dropBeforeOrAfter
    onDraggingEnd={onMoveDirectory}
    dropToKey="directory-tree"
  >
    <DirectoryTreeItem
      directory={directory}
      isSelected={directory.id === selectedDirectory?.id}
      onSelect={onSelectDirectory}
      canCreate={canCreateDirectory || directory.permission === PERMISSIONS.FULL_ACCESS}
      canUpdate={canUpdateDirectory || directory.permission === PERMISSIONS.FULL_ACCESS}
      canDelete={canDeleteDirectory || directory.permission === PERMISSIONS.FULL_ACCESS}
      onReloadTree={onReloadTree}
    >
      {children}
    </DirectoryTreeItem>
  </Draggable>
}

const DirectoryTree = (props: DirectoryTreeProps) => {
  const {
    onSelectDirectory, selectedDirectory, canCreateDirectory, canUpdateDirectory,
    canDeleteDirectory, onReloadTree, onMoveClick, canCreateRootDirectory,
    isSelectedFavorites, onSelectFavoites
  } = props;
  const [isCreatingDirectory, setIsCreatingDirectory] = useState(false);

  const onMoveDirectory = (from: HTMLElement, to?: HTMLElement) => {
    const directoryId = +from.dataset.directoryid!
    const parentId = to?.dataset?.directoryid ? +to.dataset.directoryid : undefined

    onMoveClick?.(directoryId, parentId)
  }

  const displayingDirectoryCallback = useCallback((directory: DirectoryEntity) => {
    return displayingDirectory(
      directory,
      selectedDirectory,
      onSelectDirectory,
      canCreateDirectory,
      canUpdateDirectory,
      canDeleteDirectory,
      onReloadTree,
      onMoveDirectory,
    )
  }, [props.onSelectDirectory, selectedDirectory, canCreateDirectory, canUpdateDirectory, canDeleteDirectory, onReloadTree, onMoveDirectory])

  return <div className="directory-tree">
    {canCreateRootDirectory && !isCreatingDirectory && <div className="directory-tree__create">
      <Button
        color="info"
        className="directory-tree__create-button"
        onClick={() => {
          setIsCreatingDirectory(true)
        }}
        startIcon={<CreateNewFolderOutlined />}
      >
        Добавить папку
      </Button>
    </div>
    }

    {isCreatingDirectory && <div className="directory-tree__create">
      <DirectoryCreate
        onCancel={() => setIsCreatingDirectory(false)}
        onCreated={() => {
          setIsCreatingDirectory(false)
          onReloadTree?.();
        }} />
    </div>
    }


    {props.directories.map(displayingDirectoryCallback)}
  </div>
}

export default memo(DirectoryTree)