import IInviteCodeRepository from "../../../Data/Repositories/InviteCodeRepository/IInviteCodeRepository";
import InviteCodeRepository from "../../../Data/Repositories/InviteCodeRepository/InviteCodeRepository";
import InviteCodeCreateDto from "../../Dtos/InviteCode/InviteCodeCreateDto";
import InviteCodeUpdateDto from "../../Dtos/InviteCode/InviteCodeUpdateDto";
import IInviteCodeService from "./IInviteCodeService";

class InviteCodeService implements IInviteCodeService {
  constructor(readonly repository: IInviteCodeRepository) { }

  async getAll() {
    return this.repository.getAll()
  }

  async update(data: InviteCodeUpdateDto) {
    return this.repository.update(data);
  }

  async create(data: InviteCodeCreateDto) {
    return this.repository.create(data);
  }

  async delete(id: number) {
    return this.repository.delete(id);
  }

  async enable(id: number) {
    return this.repository.enable(id);
  }

  async disable(id: number) {
    return this.repository.disable(id);
  }
}

export default new InviteCodeService(InviteCodeRepository);