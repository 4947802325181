import { useState } from "react";
import { Outlet, useOutletContext, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

type OutletContextType = {
  onClose: () => void
}

export const useModalPage = () => {
  return useOutletContext<OutletContextType>();
}

const onCloseModal = () => {
  window.history.back()
}

export default function ModalPage() {
  const { state } = useLocation();
  const [open, setOpen] = useState(true);
  const { modalTitle, ...restModalProps } = (state as any)?.modalProps || {};

  return <Dialog
    open={open}
    onClose={() => setOpen(false)}
    TransitionProps={{
      onExited: onCloseModal
    }}
    {...restModalProps}
  >
    <DialogTitle sx={{ width: 500 }}>
      {modalTitle}
    </DialogTitle>
    <DialogContent sx={{ paddingTop: '5px' }}>
      <Outlet context={{ onClose: () => setOpen(false) }} />
    </DialogContent>
  </Dialog >
}