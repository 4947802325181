import { FormControl } from "@mui/material";
import { FieldRenderProps } from "react-final-form"

export type FormFieldWrapperProps = FieldRenderProps<any, any> & {
  onChange?: any;
}

const getSubmitError = (submitError: any) => {
  if (Array.isArray(submitError)) return submitError.map((x) => x.toString())
  if (typeof submitError !== 'string') return submitError.toString()
  return submitError;
}

export default function FormFieldWrapper(Component: any) {
  const componentdWrapped = ({ onChange, input, meta, ...rest }: FormFieldWrapperProps) => {
    const customOnChange = (e: any) => {
      if (onChange) {
        onChange?.(e);
      } else {
        input.onChange(e);
      }
    }
    return (
      <FormControl>
        <Component {...input} {...rest} onChange={customOnChange} isInvalid={meta.touched && meta.invalid} />
        {meta.touched && (meta.error || meta.submitError) &&
          <div style={{ marginTop: '0', marginBottom: '5px', color: '#d32f2f' }}>
            {meta.error || getSubmitError(meta.submitError)}
          </div>
        }
      </FormControl>
    )
  }
  return componentdWrapped
}