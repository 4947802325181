import Grid from "@mui/material/Grid";
import User from "../../../Domain/Entities/User/User";
import UserCardListItem from "./UserCardListItem";
import UserCardListItemCreate from "./UserCardListItemCreate";

export type UserCardListProps = {
  users: Array<User>
  onUpdateClick?: (user: User) => void
  onDeleteClick?: (user: User) => void
  onUserBlockClick?: (event: any, user: User) => void
  canDelete?: (user: User) => boolean
  canUpdate?: (user: User) => boolean
  canBlock?: (user: User) => boolean
  canCreate?: boolean
  onCreateClick?: (event: any) => void
  canResetPassword?: (user: User) => boolean
  onResetPasswordClick?: (user: User) => void
}

export default function UserCardList(props: UserCardListProps) {
  const {
    users, onDeleteClick, onUpdateClick, onUserBlockClick, canBlock, canDelete,
    canUpdate, canCreate, onCreateClick, canResetPassword, onResetPasswordClick
  } = props;

  return <div className="user-card-list">
    <Grid container spacing={3} maxWidth={1300}>
      {canCreate && <Grid item xs={2} sm={4} md={4}>
        <UserCardListItemCreate onClick={onCreateClick} />
      </Grid>
      }
      {
        users.map((x) => <Grid item key={x.id} xs={2} sm={4} md={4}>
          <UserCardListItem
            onDeleteClick={onDeleteClick}
            onUpdateClick={onUpdateClick}
            user={x}
            onUserBlockClick={onUserBlockClick}
            canDelete={canDelete?.(x)}
            canUpdate={canUpdate?.(x)}
            canBlock={canBlock?.(x)}
            canResetPassword={canResetPassword?.(x)}
            onResetPasswordClick={onResetPasswordClick}
          />
        </Grid>)
      }
    </Grid>
  </div>
}