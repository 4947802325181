import { Form, Field, FormRenderProps } from "react-final-form";
import Button from "../../../Common/Components/Button";
import FormFieldWrapper from "../../../Common/Components/ReactFinalForm/FormFieldWrapper";
import TextField from "../../../Common/Components/TextField";
import { Stack, Typography } from '@mui/material';
import FormErrorMessage from "../../../Common/Components/ReactFinalForm/FormErrorMessage";
import { isRequired } from "../../../Common/validators";
import SelectList from "../../../Common/Components/SelectList/SelectList";
import PasswordField from "../../../Common/Components/PasswordField";
import StorageShort from "../../../Domain/Entities/Storage/StorageShort";
import { useCallback } from "react";
import DirectoryShort from "../../../Domain/Entities/Directory/DirectoryShort";

export type CreatePasscardFormProps = FormRenderProps & {
  disabled?: boolean;
  onCancel?: () => void;
  directories?: Array<DirectoryShort | StorageShort>
  isAdministator?: boolean,
}

const TextFieldWrapped = FormFieldWrapper(TextField)
const PasswordFieldWrapped = FormFieldWrapper(PasswordField)
const SelectListWrapped = FormFieldWrapper(SelectList)

const getDirectoryName = (x: DirectoryShort | StorageShort, isAdministator: boolean) => {
  if (x instanceof StorageShort) {
    return isAdministator ? x.nameWithUserName : x.name
  }

  return x.name
};

const renderDirectoryOption = (props: any, option: DirectoryShort | StorageShort, state: any, isAdministator: boolean) => {
  const id = option instanceof StorageShort
    ? `SS_${option.id}`
    : option.id

  return <Typography {...props} key={id}>
    {getDirectoryName(option, isAdministator)}
  </Typography>
}

function CreatePasscardForm(props: CreatePasscardFormProps) {
  const { submitting, disabled, handleSubmit, onCancel, directories = [], isAdministator } = props;

  const getDirectoryNameCallback = useCallback((dir: DirectoryShort | StorageShort) => getDirectoryName(dir, Boolean(isAdministator)), [isAdministator])

  const renderDirectoryOptionCallback = useCallback((props: any, option: any, state: any) => renderDirectoryOption(props, option, state, Boolean(isAdministator)), [isAdministator])

  return <form onSubmit={handleSubmit} className="create-passcard-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Название"
        name="name"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Адрес"
        name="url"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
      />
      <Field
        label="Логин"
        name="login"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Пароль"
        name="password"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Описание"
        name="description"
        component={TextFieldWrapped}
        disabled={disabled || submitting}
      />
      <Field
        label="Папки"
        name="directories"
        component={SelectListWrapped}
        getOptionLabel={getDirectoryNameCallback}
        renderOption={renderDirectoryOptionCallback}
        multiple
        selectList={directories}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <FormErrorMessage name="common" />
      <Button type="submit" disabled={disabled || submitting}>Создать</Button>
      <Button
        color="secondary"
        onClick={onCancel}
        disabled={disabled || submitting}
      >
        Отмена
      </Button>
    </Stack>
  </form >
}

const DefaultCreatePasscardForm = (props: any) => <Form {...props} render={CreatePasscardForm} />

export default DefaultCreatePasscardForm;