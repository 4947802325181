import { compareByAlphabetically } from "../../../Common/utils";
import UserGroupDirectoryPermissionRelation from "../UserGroupRelation/UserGroupDirectoryPermissionRelation";

export default class UserGroup {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly userIds: Array<number>,
    readonly relations: Array<UserGroupDirectoryPermissionRelation>,
  ) { }

  static fromApi(obj: UserGroup) {
    return new UserGroup(
      obj.id,
      obj.name,
      obj.userIds,
      obj.relations.map(UserGroupDirectoryPermissionRelation.fromApi),
    )
  }
  static fromObject(obj: UserGroup) {
    return new UserGroup(
      obj.id,
      obj.name,
      obj.userIds,
      obj.relations.map(UserGroupDirectoryPermissionRelation.fromApi),
    )
  }

  static compare = (a: UserGroup, b: UserGroup) => compareByAlphabetically(a, b, 'name')
}