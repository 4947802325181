import IUserGroupRepository from "../../../Data/Repositories/UserGroupRepository/IUserGroupRepository";
import UserGroupRepository from "../../../Data/Repositories/UserGroupRepository/UserGroupRepository";
import UserGroupCreateDto from "../../Dtos/UserGroup/UserGroupCreateDto";
import UserGroupUpdateDto from "../../Dtos/UserGroup/UserGroupUpdateDto";
import UserGroup from "../../Entities/UserGroup/UserGroup";
import IUserGroupService from "./IUserGroupService";

class UserGroupService implements IUserGroupService {
  constructor(readonly repository: IUserGroupRepository) { }

  async getAll() {
    return this.repository.getAll()
      .then((x) => x.sort(UserGroup.compare))
  }

  async create(data: UserGroupCreateDto) {
    return this.repository.create(data);
  }

  async update(data: UserGroupUpdateDto) {
    return this.repository.update(data);
  }

  async delete(id: number) {
    return this.repository.delete(id);
  }
}

export default new UserGroupService(UserGroupRepository);