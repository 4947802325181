import { memo, forwardRef } from "react";
import DirectoryEntity from "../../Domain/Entities/Directory/Directory";
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import FolderOpen from '@mui/icons-material/FolderOpen';
import classnames from "classnames";
import CreateNewFolderOutlined from '@mui/icons-material/CreateNewFolderOutlined';
import ModeEditOutlineOutlined from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import IconButton from "@mui/material/IconButton";

export type DirectoryProps = {
  className?: string;
  directory: DirectoryEntity;
  onClick?: (event: any, directory: DirectoryEntity) => void;
  isSelected?: boolean,
  canCreate?: boolean,
  canUpdate?: boolean,
  canDelete?: boolean,
  onCreateClick?: (directory: DirectoryEntity) => void
  onUpdateClick?: (directory: DirectoryEntity) => void
  onDeleteClick?: (directory: DirectoryEntity) => void
  onMouseDown?: (event: any) => void;
  onMouseEnter?: (event: any) => void;
}

function Directory(props: DirectoryProps, ref: any) {
  const { directory, onClick, isSelected, onCreateClick, canCreate,
    canUpdate, canDelete, onUpdateClick, onDeleteClick, onMouseDown, onMouseEnter } = props;
  const className = classnames(
    'directory',
    { "directory--selected": isSelected },
    props.className
  )
  return <div
    ref={ref}
    className={className}
    onClick={(event: any) => onClick?.(event, directory)}
    data-directoryid={directory.id}
    onMouseDown={onMouseDown}
    onMouseEnter={onMouseEnter}
  >
    {isSelected ? <FolderOpen /> : <FolderOutlined />}
    <div className="directory__content">
      <span
        className="directory__name"
        onDoubleClick={canUpdate
          ? (e) => {
            e.preventDefault();
            e.stopPropagation();
            onUpdateClick?.(directory)
          }
          : undefined
        }
      >
        {props.directory.name}
      </span>
      <div className="directory__actions">
        {canCreate && (<IconButton
          className="directory__create-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCreateClick?.(directory)
          }}
          onMouseDown={(event) => event.stopPropagation()}
          title="Добавить вложенную папку"
        >
          <CreateNewFolderOutlined />
        </IconButton>
        )}
        {canUpdate && (
          <IconButton
            className="directory__create-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onUpdateClick?.(directory)
            }}
            onMouseDown={(event) => event.stopPropagation()}
            title="Изменить название папки"
          >
            <ModeEditOutlineOutlined />
          </IconButton>
        )}
        {canDelete && (
          <IconButton
            className="directory__create-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteClick?.(directory)
            }}
            onMouseDown={(event) => event.stopPropagation()}
            title="Удалить папку"
          >
            <DeleteOutlined color="error" />
          </IconButton>
        )}
      </div>
    </div>
  </div >
}

export default memo(forwardRef(Directory));