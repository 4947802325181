import { useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";
import HttpClient from "../../Data/HttpClient/HttpClient";
import { useProfile } from "../../System/hooks/useProfile";
import Navbar from "../../Common/Components/Navbar/Navbar";

const forbiddenListener = (reload: () => void) => () => {
  reload();
}

export default function GeneralPage() {
  const { reloadProfile, isAuthenticated } = useProfile();

  const redirectListener = useCallback((redirectUrl: string) => {
    if (redirectUrl && isAuthenticated) {
      reloadProfile()
    }
  }, [reloadProfile, isAuthenticated])

  useEffect(() => {
    HttpClient.setForbiddenListener(forbiddenListener(reloadProfile));
    HttpClient.setRedirectListener(redirectListener);
  }, [reloadProfile, redirectListener])

  return (
    <div className="general-page">
      <Navbar />
      <div className="general-page__content">
        <Outlet />
      </div>
    </div>
  );
}
