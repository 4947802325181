import { useCallback } from "react";
import Loader from "../../Common/Components/Loader";
import { apiErrorParser } from "../../Common/utils";
import UserGroupMatrixUpdateDto from "../../Domain/Dtos/UserGroupMatrix/UserGroupMatrixUpdateDto";
import Directory from "../../Domain/Entities/Directory/Directory";
import UserGroupShort from "../../Domain/Entities/UserGroup/UserGroupShort";
import { PERMISSIONS } from "../../Domain/Enums/Permissions";
import { useModal } from "../../System/hooks/useModal";
import useGetAllGroupedUserGroupMatrixByDirectory from "../_hooks/UserGroupMatrix/useGetAllGroupedUserGroupMatrixByDirectory";
import useUpdateUserGroupMatrix from "../_hooks/UserGroupMatrix/useUpdateUserGroupMatrix";
import MatrixTable from "./MatrixTable";

export default function MatrixPage() {
  const getAllGroupedUserGroupMatrixByDirectoryHook = useGetAllGroupedUserGroupMatrixByDirectory();
  const updateUserGroupMatrixHook = useUpdateUserGroupMatrix();
  const modal = useModal();

  const onPermissionChange = useCallback((event: any, directory: Directory, userGroup: UserGroupShort, permission: PERMISSIONS) => {
    updateUserGroupMatrixHook
      .mutateAsync(new UserGroupMatrixUpdateDto(userGroup.id, directory.id, permission))
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Установка прав',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }, [])

  if (getAllGroupedUserGroupMatrixByDirectoryHook.isLoading || getAllGroupedUserGroupMatrixByDirectoryHook.isFetching) {
    return <div className="matrix-page">
      <Loader />
    </div>
  }


  return <div className="matrix-page">
    <MatrixTable
      directories={getAllGroupedUserGroupMatrixByDirectoryHook.data?.[0] || []}
      userGroups={getAllGroupedUserGroupMatrixByDirectoryHook.data?.[1] || []}
      groupedDirectoryUserGroupPermission={getAllGroupedUserGroupMatrixByDirectoryHook.data?.[2] || new Map()}
      onPermissionChange={onPermissionChange}
    />
  </div>
}