import moment from 'moment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, BrowserRouter as Router, Routes, } from 'react-router-dom';
import AccessDeniedPage from './Common/Pages/AccessDeniedPage';
import IsAdministrator from './Common/Pages/IsAdministrator';
import IsAuthorized from './Common/Pages/IsAuthorized';
import PageNotFound from './Common/Pages/PageNotFound';
import AuthCommonPage from './Feature/Auth/AuthCommonPage';
import AuthorizationPage from './Feature/Auth/Authorization/AuthorizationPage';
import NewPasswordPage from './Feature/Auth/NewPassword/NewPasswordPage';
import RegistrationPage from './Feature/Auth/Registration/RegistrationPage';
import RestorePage from './Feature/Auth/Restore/RestorePage';
import GeneralPage from './Feature/GeneralPage/GeneralPage';
import ImportExportPage from './Feature/ImportExport/ImportExportPage';
import InviteCodeFormPage from './Feature/InviteCode/Form/InviteCodeFormPage';
import InviteCodePage from './Feature/InviteCode/InviteCodePage';
import MainPage from './Feature/MainPage/MainPage';
import MatrixPage from './Feature/Matrix/MatrixPage';
import ModalPage from './Feature/ModalPage/ModalPage';
import CreatePasscardPage from './Feature/Passcard/Create/CreatePasscardPage';
import MovePasscardPage from './Feature/Passcard/Move/MovePasscardPage';
import ProfilePage from './Feature/Profile/ProfilePage';
import { StorageListPage } from './Feature/Storage/StorageList/StorageListPage';
import UserCreateFormPage from './Feature/User/CreateForm/UserCreateFormPage';
import UserUpdateFormPage from './Feature/User/UpdateForm/UserUpdateFormPage';
import UserPage from './Feature/User/UserPage';
import UserGroupPage from './Feature/UserGroup/UserGroupPage';
import { ModalProvider } from './System/Providers/ModalProvider';
import { ProfileProvider } from './System/Providers/ProfileProvider';

function App() {
  moment.locale('ru')

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ProfileProvider>
        <ModalProvider>
          <Router>
            <Routes>
              <Route
                element={
                  <AuthCommonPage />
                }>
                <Route
                  path="authorization"
                  element={
                    <AuthorizationPage />
                  }
                />
                <Route
                  path="registration"
                  element={
                    <RegistrationPage />
                  }
                />
                <Route
                  path="restore"
                  element={
                    <RestorePage />
                  }
                />
                <Route
                  path="new-password"
                  element={
                    <NewPasswordPage />
                  }
                />
              </Route>
              <Route
                element={
                  <IsAuthorized>
                    <GeneralPage />
                  </IsAuthorized>
                }
              >
                <Route
                  path="/"
                  element={
                    <MainPage />
                  }
                >
                  <Route
                    element={
                      <ModalPage />
                    }
                  >
                    <Route
                      path="passcard/create"
                      element={
                        <CreatePasscardPage />
                      }
                    />
                    <Route
                      path="passcard/:id/move"
                      element={
                        <MovePasscardPage />
                      }
                    />
                  </Route>
                </Route>
                <Route element={<IsAdministrator />}>
                  <Route
                    path="/user-groups"
                    element={<UserGroupPage />}
                  />
                </Route>
                <Route element={<IsAdministrator />}>
                  <Route
                    path="/users"
                    element={<UserPage />}
                  >
                    <Route
                      element={
                        <ModalPage />
                      }
                    >
                      <Route
                        path=":id/update"
                        element={
                          <UserUpdateFormPage />
                        }
                      />
                      <Route
                        path="create"
                        element={
                          <UserCreateFormPage />
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="invite-codes"
                    element={<InviteCodePage />}
                  >
                    <Route
                      element={
                        <ModalPage />
                      }
                    >
                      <Route
                        path=":id/update"
                        element={
                          <InviteCodeFormPage />
                        }
                      />
                      <Route
                        path="create"
                        element={
                          <InviteCodeFormPage isCreate />
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path='matrix'
                    element={<MatrixPage />}
                  />
                  <Route
                    path='export'
                    element={<ImportExportPage />}
                  />
                  <Route
                    path='user-storages'
                    element={<StorageListPage />}
                  >
                    <Route
                      element={
                        <ModalPage />
                      }
                    >
                      <Route
                        path="passcard/:id/move"
                        element={
                          <MovePasscardPage />
                        }
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  path='profile'
                  element={<ProfilePage />}
                />
              </Route>
              <Route
                path="access-denied"
                element={<AccessDeniedPage />}
              />
              <Route
                path="*"
                element={<PageNotFound />}
              />
            </Routes>
          </Router>
        </ModalProvider>
      </ProfileProvider>
    </QueryClientProvider>
  );
}

export default App;
