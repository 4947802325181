import axios, { Axios, AxiosRequestConfig } from 'axios';
import { REACT_APP_BACKEND_URL } from '../../Common/const';
import IHttpClient from './IHttpClient';

class _HttpClient implements IHttpClient {
  private url?: string;
  private session: Axios;
  private unauthorizedListener?: () => void
  private forbiddenListener?: () => void
  private redirectListener?: (redirectUrl: string) => void

  constructor(baseUrl: string) {
    this.url = baseUrl;
    this.session = axios.create({
      baseURL: this.url,
      withCredentials: true,
    });
    this.configure()
  }

  private configure() {
    this.session.interceptors.response.use(
      undefined,
      async (response) => {
        const url = response.request.responseURL
        if (url) {
          this.redirectListener?.(url)
        }
        throw response
      })
  }

  setUnauthorizedListener(listener: () => void) {
    this.unauthorizedListener = listener;
  }

  setForbiddenListener(listener: () => void) {
    this.forbiddenListener = listener;
  }

  setRedirectListener(listener: (redirectUrl: string) => void) {
    this.redirectListener = listener;
  }

  private async callListeners(response: any) {
    const status = response?.status

    switch (true) {
      case status === 401:
        this.unauthorizedListener?.()
        break;
      case status === 403:
        this.forbiddenListener?.();
        break;
    }
  }

  get = async<T>(url: string, config?: AxiosRequestConfig) => {
    return this.session.get<T>(url, config)
      .catch((exception) => {
        this.callListeners(exception?.response)
        throw exception;
      })
  }

  post = async<T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return this.session.post<T>(url, data, config)
      .catch((exception) => {
        this.callListeners(exception?.response)
        throw exception;
      })
  };

  put = async<T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return this.session.put<T>(url, data, config)
      .catch((exception) => {
        this.callListeners(exception?.response)
        throw exception;
      })
  };

  delete = async<T>(url: string, config?: AxiosRequestConfig) => {
    return this.session.delete<T>(url, config)
      .catch((exception) => {
        this.callListeners(exception?.response)
        throw exception;
      })
  }
}

const HttpClient = new _HttpClient(REACT_APP_BACKEND_URL);

export default HttpClient;