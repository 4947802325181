import { useMutation } from "react-query";
import UserService from "../../../Domain/Services/UserService/UserService";

export default function useDropUserPassword(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (id: number) => UserService.dropUserPassword(id),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}