import { useMutation } from "react-query";
import PasscardCreateDto from "../../../Domain/Dtos/Passcard/PasscardCreateDto";
import PasscardService from "../../../Domain/Services/PasscardService/PasscardService";

export default function useCreatePasscard(
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useMutation(
    (data: PasscardCreateDto) => PasscardService.create(data),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}