import Button from "@mui/material/Button/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import Loader from "../../Common/Components/Loader";
import SearchField from "../../Common/Components/SearchField/SearchField";
import Sidebar from "../../Common/Components/Sidebar/Sidebar";
import { apiErrorParser, updateItemInList } from "../../Common/utils";
import DirectoryMovingDto from "../../Domain/Dtos/Directory/DirectoryMovingDto";
import PasscardUpdateDto from "../../Domain/Dtos/Passcard/PasscardUpdateDto";
import DirectoryEntity from "../../Domain/Entities/Directory/Directory";
import Passcard from "../../Domain/Entities/Passcard/Passcard";
import Storage from "../../Domain/Entities/Storage/Storage";
import { PERMISSIONS } from "../../Domain/Enums/Permissions";
import { useModal } from "../../System/hooks/useModal";
import { useProfile } from "../../System/hooks/useProfile";
import Directory from "../Directory/Directory";
import Favorites from "../Directory/Favorites";
import DirectoryTree from "../Directory/Tree/DirectoryTree";
import PasscardTable from "../Passcard/PasscardTable/PasscardTable";
import UserStorage from "../Storage/UserStorage";
import UserStorageName from "../Storage/UserStorageName";
import { PASSCARD_FAVORITES_GET_ALL_QK, PASSCARD_GET_ALL_QK } from "../_hooks/Constants";
import useGetAllDirectory from "../_hooks/Directory/useGetAllDirectory";
import useMoveDirectory from "../_hooks/Directory/useMoveDirectory";
import useDeletePasscard from "../_hooks/Passcard/useDeletePasscard";
import useGetAllPasscard from "../_hooks/Passcard/useGetAllPasscard";
import useMovePasscard from "../_hooks/Passcard/useMovePasscard";
import useUpdatePasscard from "../_hooks/Passcard/useUpdatePasscard";
import useAddPasscardToFavorites from "../_hooks/PasscardFavorites/useAddPasscardToFavorites";
import useGetAllPasscardFavorites from "../_hooks/PasscardFavorites/useGetAllPasscardFavorites";
import useRemovePasscardFromFavorites from "../_hooks/PasscardFavorites/useRemovePasscardFromFavorites";
import Welcome from "./Welcome";

const getFlattedDirectoriesList = (directories: Array<DirectoryEntity>): Array<DirectoryEntity> => {
  return directories.flatMap((x) => [x, ...getFlattedDirectoriesList(x.childrens)])
}

const getMappedDirectoryPermission = async (directories: Array<DirectoryEntity>): Promise<Map<number, PERMISSIONS>> => {
  const map = new Map<number, PERMISSIONS>();
  const flattedDirectories = getFlattedDirectoriesList(directories)
  flattedDirectories.forEach((x) => map.set(x.id, x.permission))
  return map;
}

export default function MainPage() {
  const [selectedDirectory, setSelectedDirectory] = useState<DirectoryEntity | undefined>(undefined);
  const [isSelectFavorites, setIsSelectFavorites] = useState(true)
  const [selectStorage, setSelectStorage] = useState<Storage | undefined>(undefined)
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [resetSearchField, setResetSearchField] = useState(false);
  const [directoryPermissionMap, setDirectoryPermissionMap] = useState<Map<number, PERMISSIONS> | undefined>(undefined);

  const getDirectories = useGetAllDirectory((directories: Array<DirectoryEntity>) => {
    getMappedDirectoryPermission(directories).then(setDirectoryPermissionMap)
  });
  const getAllPasscardFavorites = useGetAllPasscardFavorites();
  const getAllPasscardHook = useGetAllPasscard(selectedDirectory?.id, searchValue, selectStorage?.id);
  const updatePasscard = useUpdatePasscard(() => isSelectFavorites ? getAllPasscardFavorites.refetch() : getAllPasscardHook.refetch());
  const deletePasscard = useDeletePasscard(() => isSelectFavorites ? getAllPasscardFavorites.refetch() : getAllPasscardHook.refetch());
  const moveDirectory = useMoveDirectory(getDirectories.refetch);
  const movePasscard = useMovePasscard();
  const addPasscardToFavorites = useAddPasscardToFavorites();
  const removePasscardFromFavorites = useRemovePasscardFromFavorites();
  const queryClient = useQueryClient()
  const modal = useModal();
  const { profile } = useProfile();

  const isLoading = getAllPasscardHook.isLoading || getAllPasscardHook.isFetching
    || getAllPasscardFavorites.isLoading || getAllPasscardFavorites.isFetching

  const setSelectedDirectoryCallback = useCallback((event: any, directory: DirectoryEntity) => {
    setSelectedDirectory(directory)
    setIsSelectFavorites(false)
    setSelectStorage(undefined)
    setSearchValue('')
    setResetSearchField((x) => !x)
  }, [])

  const setSelectedFavoritesCallback = useCallback((event: any) => {
    setSelectedDirectory(undefined)
    setSelectStorage(undefined)
    setIsSelectFavorites(true)
    setSearchValue('')
    setResetSearchField((x) => !x)
  }, [])

  const setSelectedStorageCallback = useCallback((event: any, storage: Storage) => {
    setSelectedDirectory(undefined)
    setIsSelectFavorites(false)
    setSelectStorage(storage)
    setSearchValue('')
    setResetSearchField((x) => !x)
  }, [])

  const onSearchCallback = useCallback((value: any) => {
    setSearchValue(value)
    setSelectedDirectory(undefined)
    setIsSelectFavorites(false)
    setSelectStorage(undefined)
  }, [])

  const onUpdate = useCallback((passcard: Passcard) => {
    return updatePasscard
      .mutateAsync(passcard.toUpdateDto())
      .catch((errors) => modal.showInformationModal({
        title: passcard.url,
        contentText: apiErrorParser(errors).common || 'Произошла ошибка',
        onAccepte: modal.closeModal,
      }))
  }, [updatePasscard.mutateAsync])

  const onAccepteMove = (directoryId: number, parentId?: number) => {
    return moveDirectory
      .mutateAsync(new DirectoryMovingDto(directoryId, parentId))
      .then(() => {
        modal.closeModal();
      })
      .catch((errors) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Перемещение папки',
          contentText: apiErrorParser(errors).common || 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onMoveClick = (directoryId: number, parentId?: number) => {
    const directories = getFlattedDirectoriesList(getDirectories.data ?? [])
    const directory = directories.find((x) => x.id === directoryId)
    const parentDirectorty = directories.find((x) => x.id === parentId)

    if (directoryId === parentId) {
      modal.showInformationModal({
        title: `Перемещение ${directory?.name}`,
        contentText: 'Нельзя переместить папку в саму себя',
        onAccepte: modal.closeModal,
      })
      return;
    }

    // папка уже находится в корне
    if (getDirectories.data?.find((x) => x.id === directory?.id) && parentId === undefined) {
      return;
    }

    if (parentDirectorty && parentDirectorty.childrens?.findIndex((x) => x.id === directory?.id) !== -1) {
      modal.showInformationModal({
        title: `Перемещение ${directory?.name}`,
        contentText: `${directory?.name} уже находится в ${parentDirectorty?.name}`,
        onAccepte: modal.closeModal,
      })
      return;
    }

    if (!profile?.isAdministator && !parentDirectorty && directory?.permission !== PERMISSIONS.FULL_ACCESS) {
      modal.showInformationModal({
        title: `Перемещение ${directory?.name}`,
        contentText: `Для перемещения ${directory?.name} в корень необходимо обладать полным доступом к папке`,
        onAccepte: modal.closeModal,
      })
      return;
    }

    if (!profile?.isAdministator && parentDirectorty && (directory?.permission !== PERMISSIONS.FULL_ACCESS || parentDirectorty?.permission !== PERMISSIONS.FULL_ACCESS)) {
      modal.showInformationModal({
        title: `Перемещение ${directory?.name}`,
        contentText: `Для перемещения ${directory?.name} в ${parentDirectorty?.name} необходимо обладать полным доступом к обеим папкам`,
        onAccepte: modal.closeModal,
      })
      return;
    }

    const modalText = parentDirectorty
      ? `Переместить папку ${directory?.name} в ${parentDirectorty?.name}?`
      : `Переместить папку ${directory?.name} в корень?`

    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => onAccepteMove(directoryId, parentId),
      title: directory?.name || '',
      contentText: modalText,
    });
  }

  useEffect(() => {
    if (isSelectFavorites) {
      getAllPasscardFavorites.refetch()
    } else {
      getAllPasscardHook.refetch();
    }
  }, [isSelectFavorites, selectedDirectory, searchValue, selectStorage])

  useEffect(() => {
    modal.changeConfirmationProps({
      isLoading: deletePasscard.isLoading || moveDirectory.isLoading,
      disabled: deletePasscard.isLoading || moveDirectory.isLoading,
    })
  }, [deletePasscard.isLoading, moveDirectory.isLoading])

  const onAccepteDeleteFromAllDirectory = (passcard: Passcard) => {
    return deletePasscard.mutateAsync(passcard.id)
      .then(() => {
        if (selectedDirectory) {
          setSelectedDirectory(new DirectoryEntity(
            selectedDirectory.id,
            selectedDirectory.parentId,
            selectedDirectory.name,
            selectedDirectory.permission,
            selectedDirectory.childrens,
          ))
        }
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Пароль успешно удален',
          onAccepte: modal.closeModal,
        })
      })
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onAccepteDeleteFromDirectory = (passcard: Passcard) => {
    if (!selectedDirectory) return;

    const movingDto: PasscardUpdateDto = PasscardUpdateDto.fromObject({
      ...passcard,
      parentIds: [...passcard.parentIds.filter((x) => x !== selectedDirectory.id)],
    });

    return movePasscard.mutateAsync({ id: passcard.id, update: movingDto })
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Пароль успешно удален из папки',
          onAccepte: modal.closeModal,
        })
      })
      .then(() => {
        const newPasscardList = (getAllPasscardHook.data ?? []).filter((x) => x.id !== passcard.id)
        queryClient.setQueryData([PASSCARD_GET_ALL_QK, selectedDirectory?.id, searchValue, selectStorage?.id], newPasscardList)
      })
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onAccepteDeleteFromStorage = (passcard: Passcard) => {
    if (!selectStorage) return;

    const movingDto: PasscardUpdateDto = PasscardUpdateDto.fromObject({
      ...passcard,
      storageIds: [...passcard.storageIds.filter((x) => x !== selectStorage.id)],
    });

    return movePasscard.mutateAsync({ id: passcard.id, update: movingDto })
      .then(() => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Пароль успешно удален из папки',
          onAccepte: modal.closeModal,
        })
      })
      .then(() => {
        const newPasscardList = (getAllPasscardHook.data ?? []).filter((x) => x.id !== passcard.id)
        queryClient.setQueryData([PASSCARD_GET_ALL_QK, selectedDirectory?.id, searchValue, selectStorage?.id], newPasscardList)
      })
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.url,
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }

  const onDeleteFromAllDirectory = useCallback((passcard: Passcard) => {
    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => onAccepteDeleteFromAllDirectory(passcard),
      title: passcard.url,
      contentText: 'Удалить данный пароль из всех папок?',
    });
  }, [])

  const onDeleteFromDirectory = useCallback((passcard: Passcard) => {
    let deleteFromOrDeleteFromAll = onAccepteDeleteFromDirectory

    if (selectedDirectory) {
      if (passcard.parentIds.length === 1 && passcard.storageIds.length === 0) {
        deleteFromOrDeleteFromAll = onAccepteDeleteFromAllDirectory
      }
    }

    if (selectStorage) {
      deleteFromOrDeleteFromAll = onAccepteDeleteFromStorage
      if (passcard.storageIds.length === 1 && passcard.parentIds.length === 0) {
        deleteFromOrDeleteFromAll = onAccepteDeleteFromAllDirectory
      }
    }

    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => deleteFromOrDeleteFromAll(passcard),
      title: passcard.url,
      contentText: 'Удалить данный пароль из папки?',
    });
  }, [selectedDirectory, getAllPasscardHook.data, selectStorage])

  const onPasscardCreateClick = useCallback(() => {
    navigate(`/passcard/create`, {
      state: {
        modalProps: { modalTitle: "Добавление пароля" },
        selectedDirectory,
        selectStorage,
      }
    })
  }, [selectedDirectory, selectStorage])

  const onPasscardMoveClick = useCallback((passcard: Passcard) => {
    navigate(`/passcard/${passcard.id}/move`, {
      state: {
        modalProps: { modalTitle: "Расположение пароля" },
        selectedDirectory,
        selectStorage,
        passcard,
      }
    })
  }, [selectedDirectory, selectStorage])

  const hasPasscardFullAccess = useCallback((passcard: Passcard) => {
    if (profile?.isAdministator) return true;
    if (selectedDirectory) return selectedDirectory.permission === PERMISSIONS.FULL_ACCESS
    if (!selectedDirectory) return passcard.parentIds.some((x) => directoryPermissionMap?.get(x) === PERMISSIONS.FULL_ACCESS)
    return false;
  }, [profile, selectedDirectory, directoryPermissionMap])

  const canDeletePasscardFromAllDirectory = useCallback(() => {
    return Boolean(profile?.isAdministator)
  }, [profile])

  const canDeletePasscardFromDirectory = useCallback(() => {
    const canDeleteFromDirectory = selectedDirectory && (profile?.isAdministator
      || selectedDirectory?.permission === PERMISSIONS.FULL_ACCESS)
    const canDeleteFromStorage = selectedDirectory === undefined
      && isSelectFavorites === false
      && selectStorage !== undefined

    return Boolean(canDeleteFromDirectory || canDeleteFromStorage)
  }, [profile, selectedDirectory, isSelectFavorites, selectStorage])

  const canMove = useCallback((passcard: Passcard) => {
    return selectStorage !== undefined || (selectedDirectory !== undefined && hasPasscardFullAccess(passcard))
  }, [profile, selectedDirectory, directoryPermissionMap, hasPasscardFullAccess, selectStorage])

  const canFavorites = useCallback(() => true, [])

  const canEdit = useCallback((passcard: Passcard) => {
    return selectStorage !== undefined || (selectedDirectory !== undefined && hasPasscardFullAccess(passcard))
  }, [profile, selectedDirectory, directoryPermissionMap, hasPasscardFullAccess, selectStorage])

  const canCreatePasscard = useMemo(() => {
    return selectedDirectory?.permission === PERMISSIONS.FULL_ACCESS || selectStorage !== undefined
  }, [selectedDirectory, selectStorage])

  const availableDragAndDrop = useMemo(() => {
    if (profile?.isAdministator) return true;
    if (selectedDirectory) return selectedDirectory.permission === PERMISSIONS.FULL_ACCESS
    if (!selectedDirectory) return true
    return false;
  }, [profile, selectedDirectory, directoryPermissionMap])

  const onMovePasscard = useCallback((toDirectoryId: number, fromDirectoryId: number | undefined, passcard: Passcard) => {
    const movingDto: PasscardUpdateDto = PasscardUpdateDto.fromObject({
      ...passcard,
      parentIds: [...passcard.parentIds.filter((x) => x !== fromDirectoryId), toDirectoryId],
    });

    return movePasscard.mutateAsync({ id: passcard.id, update: movingDto })
  }, [])

  const onMovePasscardToDirectory = useCallback(async (fromDirectoryId: number, toDirectoryId: number, passcard: Passcard) => {
    onMovePasscard(toDirectoryId, fromDirectoryId, passcard)
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Перемещение пароля',
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })

    const newPasscardList = (getAllPasscardHook.data ?? []).filter((x) => x.id !== passcard.id)
    queryClient.setQueryData([PASSCARD_GET_ALL_QK, selectedDirectory?.id, searchValue], newPasscardList)
  }, [getAllPasscardHook.data, selectedDirectory?.id, searchValue])

  const onAccepteAddPasscardToDirectory = useCallback(async (toDirectoryId: number, passcard: Passcard) => {
    onMovePasscard(toDirectoryId, undefined, passcard)
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: 'Добавление пароля',
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
    modal.closeModal()

    const newPasscardList = [...(getAllPasscardHook.data ?? [])]
    const passcardIndex = newPasscardList.findIndex((x) => x.id == passcard.id)

    if (passcardIndex !== -1) {
      const passcardByIndex = newPasscardList[passcardIndex];
      const newPasscard = new Passcard(
        passcardByIndex.id,
        [...passcardByIndex.parentIds, toDirectoryId],
        passcardByIndex.name,
        passcardByIndex.url,
        passcardByIndex.login,
        passcardByIndex.password,
        passcardByIndex.description,
        passcardByIndex.isFavorite,
        passcardByIndex.storageIds,
      )

      newPasscardList[passcardIndex] = newPasscard
    }

    queryClient.setQueryData([PASSCARD_GET_ALL_QK, selectedDirectory?.id, searchValue], newPasscardList)
  }, [onMovePasscard, queryClient, getAllPasscardHook.data])

  const onAddPasscardToDirectory = useCallback(async (toDirectoryId: number, passcard: Passcard) => {

    modal.showConfirmationModal({
      onCancel: modal.closeModal,
      onAccepte: () => onAccepteAddPasscardToDirectory(toDirectoryId, passcard),
      title: "Добавление пароля в папку",
      contentText: 'Вы уверены, что хотите добавить пароль в данную папку?',
    });
  }, [onAccepteAddPasscardToDirectory])

  const onAddOrMovePasscardToDirectory = useCallback(async (toDirectoryId: number, passcard: Passcard) => {
    const directories = getFlattedDirectoriesList(getDirectories.data ?? [])
    const toDirectory = directories.find((x) => x.id === toDirectoryId)
    const fromDirectory = selectedDirectory

    if (toDirectoryId === fromDirectory?.id || passcard.parentIds.includes(toDirectoryId)) {
      modal.showInformationModal({
        title: 'Перемещение пароля',
        contentText: 'Пароль уже находится в данной папке',
        onAccepte: modal.closeModal,
      })
      return;
    }

    if (!profile?.isAdministator && !fromDirectory && toDirectory?.permission !== PERMISSIONS.FULL_ACCESS) {
      modal.showInformationModal({
        title: 'Перемещение пароля',
        contentText: `Для перемещения ${passcard.name} в ${toDirectory?.name} необходимо обладать полным доступом к целевой папке`,
        onAccepte: modal.closeModal,
      })
      return;
    }

    if (!profile?.isAdministator && fromDirectory && (toDirectory?.permission !== PERMISSIONS.FULL_ACCESS || fromDirectory?.permission !== PERMISSIONS.FULL_ACCESS)) {
      modal.showInformationModal({
        title: 'Перемещение пароля',
        contentText: `Для перемещения ${passcard.name} в ${toDirectory?.name} необходимо обладать полным доступом к обеим папкам`,
        onAccepte: modal.closeModal,
      })
      return;
    }

    if (selectedDirectory) {
      onMovePasscardToDirectory(selectedDirectory.id, toDirectoryId, passcard)
    } else {
      onAddPasscardToDirectory(toDirectoryId, passcard)
    }
  }, [selectedDirectory, getDirectories.data, onMovePasscardToDirectory, onAddPasscardToDirectory])

  const updateFavoriteStatusInCache = useCallback(async (passcard: Passcard) => {
    const queryKey = isSelectFavorites
      ? PASSCARD_FAVORITES_GET_ALL_QK
      : [PASSCARD_GET_ALL_QK, selectedDirectory?.id, searchValue, selectStorage?.id]

    const list = updateItemInList(
      [...isSelectFavorites ? (getAllPasscardFavorites.data ?? []) : (getAllPasscardHook.data ?? [])],
      passcard.id,
      (passcard: Passcard) => Passcard.fromApi({ ...passcard, isFavorite: !passcard.isFavorite })
    )

    queryClient.setQueryData(queryKey, list)
  }, [queryClient.setQueryData, getAllPasscardFavorites.data, getAllPasscardHook.data])

  const onFavoriteClick = useCallback((passcard: Passcard) => {
    const hook = passcard.isFavorite ? removePasscardFromFavorites : addPasscardToFavorites

    updateFavoriteStatusInCache(passcard)

    return hook.mutateAsync(passcard.id)
      .catch((errors: any) => {
        modal.closeModal();
        modal.showInformationModal({
          title: passcard.isFavorite ? 'Удаление из избранного' : 'Добавление в избранное',
          contentText: 'Произошла ошибка',
          onAccepte: modal.closeModal,
        })
      })
  }, [removePasscardFromFavorites.mutateAsync, addPasscardToFavorites.mutateAsync, modal, queryClient.setQueryData, updateFavoriteStatusInCache, isSelectFavorites, getAllPasscardHook.data, getAllPasscardFavorites.data])

  return <div className="main-page" style={{ display: 'flex' }}>
    <div className="main-page__sidebar">
      <Sidebar>
        <div className="personal-space">
          <Favorites
            onClick={setSelectedFavoritesCallback}
            isSelected={isSelectFavorites}
          />

          <UserStorage
            onClick={setSelectedStorageCallback}
            isSelected={selectStorage !== undefined}
          />
        </div>
        <DirectoryTree
          directories={getDirectories.data ?? []}
          selectedDirectory={selectedDirectory}
          onSelectDirectory={setSelectedDirectoryCallback}
          canCreateRootDirectory={profile?.isAdministator}
          canCreateDirectory={profile?.isAdministator}
          canUpdateDirectory={profile?.isAdministator}
          canDeleteDirectory={profile?.isAdministator}
          onReloadTree={getDirectories.refetch}
          onMoveClick={onMoveClick}
          isSelectedFavorites={isSelectFavorites}
          onSelectFavoites={setSelectedFavoritesCallback}
        />
      </Sidebar>
    </div>
    <div className="main-page__content">
      <div className="main-page__title">
        <span className="main-page__title-wrapper">
          {Boolean(selectedDirectory) && <Directory directory={selectedDirectory!} />}
          {isSelectFavorites && <Favorites />}
          {Boolean(selectStorage) && <UserStorageName storage={selectStorage!} />}
        </span>
        <span className="main-page__search">
          <SearchField
            key={resetSearchField.toString()}
            label="Поиск пароля"
            onSearch={onSearchCallback}
          />
        </span>
      </div>
      <div className="main-page__top-panel">
        {canCreatePasscard && <Button variant="contained" onClick={onPasscardCreateClick}>
          Добавить пароль
        </Button>}
      </div>
      {isLoading && <Loader />}
      {!isLoading && ((getDirectories.data?.length || 0) > 0 || selectStorage !== undefined) && (
        <PasscardTable
          onChange={onUpdate}
          onDeleteFromAllDirectory={onDeleteFromAllDirectory}
          onDeleteFromDirectory={onDeleteFromDirectory}
          onMoveButtonClick={onPasscardMoveClick}
          passcards={isSelectFavorites ? (getAllPasscardFavorites.data || []) : (getAllPasscardHook.data || [])}
          onMoveToDirectory={onAddOrMovePasscardToDirectory}
          availableDragAndDrop={availableDragAndDrop}
          canDeletePasscardFromAllDirectory={canDeletePasscardFromAllDirectory}
          canDeletePasscardFromDirectory={canDeletePasscardFromDirectory}
          canMove={canMove}
          canFavorites={canFavorites}
          canEdit={canEdit}
          onFavoriteClick={onFavoriteClick}
          canCreate={canCreatePasscard}
        />
      )}
      {!isLoading && (getDirectories.data?.length ?? 1) < 1 && selectStorage === undefined && <Welcome />}
    </div>
    <Outlet />
  </div >
}