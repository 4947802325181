import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { useState } from "react";
import Passcard from "../../../Domain/Entities/Passcard/Passcard"

export type ViewPasswordProps = {
  passcard: Passcard
}

export default function ViewPassword(props: ViewPasswordProps) {
  const { passcard } = props;
  const [view, setView] = useState<boolean>(false);

  const onVisibilityClick = () => {
    const newValue = !view
    setView(newValue)
  }

  return <div className="view-password">
    <span className="view-password__text">{view ? passcard.password : passcard.password ? '********' : ''}</span>
    <span className="view-password__button-container">
      <IconButton onClick={onVisibilityClick}>{view ? <Visibility /> : <VisibilityOff />}</IconButton>
    </span>
  </div>
}