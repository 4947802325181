import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { useState } from 'react';

export type TextFieldProps = OutlinedInputProps & {
  isInvalid?: boolean;
}

export default function PasswordField(props: TextFieldProps) {
  const [show, setShow] = useState(false)
  const { isInvalid, label, name, ...rest } = props;
  return <FormControl variant="outlined" error={isInvalid}>
    <InputLabel htmlFor={`outlined-adornment-password-${name}`} error={isInvalid}>{label}</InputLabel>
    <OutlinedInput
      id={`outlined-adornment-password-${name}`}
      type={show ? 'text' : 'password'}
      label={label}
      {...rest}
      error={isInvalid}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShow(x => !x)}
            edge="end"
            tabIndex={-1}
          >
            {show ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>
}