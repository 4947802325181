import { forwardRef } from "react";
import classnames from "classnames";
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';

export type FavoritesProps = {
  name?: string;
  className?: string;
  onClick?: (event: any) => void;
  isSelected?: boolean,
}

function Favorites(props: FavoritesProps, ref: any) {
  const { name = "Избранное", onClick, isSelected } = props;

  const className = classnames(
    'favorites',
    { "favorites--selected": isSelected },
    props.className
  )

  return <span
    ref={ref}
    className={className}
    onClick={onClick}
  >
    <StarBorderOutlined />
    <span className="favorites__content">
      <span className="favorites__name">
        {name}
      </span>
    </span>
  </span >
}

export default forwardRef(Favorites);