import StorageUpdateDto from "../../../Domain/Dtos/Storage/StorageUpdateDto";
import Storage from "../../../Domain/Entities/Storage/Storage";
import StorageShort from "../../../Domain/Entities/Storage/StorageShort";
import { Api } from "../../Api/Api";
import IStorageRepository from "./IStorageRepository";

class StorageRepository implements IStorageRepository {
  constructor(readonly api: typeof Api) { }

  async getAll() {
    return this.api.getStorages()
      .then((x) => x.map(Storage.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async getAllShort() {
    return this.api.getStoragesShort()
      .then((x) => x.map(StorageShort.fromApi))
      .catch((error) => {
        throw error.response;
      });
  }

  async getUserStorage() {
    return this.api.getUserStorage()
      .then(Storage.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }

  async update(data: StorageUpdateDto) {
    return this.api.updateStorage(data)
      .then(Storage.fromApi)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new StorageRepository(Api);