import { memo } from "react";
import Loader from "../../../Common/Components/Loader";
import Sidebar from "../../../Common/Components/Sidebar/Sidebar";
import Passcard from "../../../Domain/Entities/Passcard/Passcard";
import Storage from "../../../Domain/Entities/Storage/Storage";
import PasscardTable, { PasscardTableProps } from "../../Passcard/PasscardTable/PasscardTable";
import AdminStorageName from "./AdminStorageName";
import AdminUserStorage from "./AdminUserStorage";

export type StorageListProps = PasscardTableProps & {
  storages: Array<Storage>
  passcards: Array<Passcard>
  isPasscardsLoading?: boolean
  isStoragesLoading?: boolean
  selectStorage?: Storage
  onSelectStorage: (event: any, storage: Storage) => void
}

const MemoAdminUserStorage = memo(AdminUserStorage)

export function StorageList(props: StorageListProps) {
  const {
    storages, onSelectStorage, isStoragesLoading, selectStorage, passcards,
    isPasscardsLoading, ...rest
  } = props;

  return <div className="storage-list">
    <div className="storage-list__sidebar">
      <Sidebar>
        {isStoragesLoading && <Loader />}
        {!isStoragesLoading && storages.map((x) => <MemoAdminUserStorage
          key={x.id}
          storage={x}
          onClick={onSelectStorage}
          isSelected={x.id === selectStorage?.id}
        />)}
        {storages.length === 0 && <div style={{ textAlign: "center" }}>Список пуст</div>}
      </Sidebar>
    </div>
    <div className="storage-list__content">
      <div className="storage-list__title">
        <span className="storage-list__title-wrapper">
          {Boolean(selectStorage) && <AdminStorageName storage={selectStorage!} />}
        </span>
      </div>
      {isStoragesLoading && isPasscardsLoading && <Loader />}
      {!(isStoragesLoading && isPasscardsLoading) && (
        <PasscardTable
          passcards={passcards}
          {...rest}
          availableDragAndDrop={false}
        />
      )}
    </div>
    {/* <Outlet /> */}
  </div >
}