import { Form, Field, FormRenderProps } from "react-final-form";
import FormFieldWrapper from "../../Common/Components/ReactFinalForm/FormFieldWrapper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import PasswordField from "../../Common/Components/PasswordField";
import FormErrorMessage from "../../Common/Components/ReactFinalForm/FormErrorMessage";
import { isRequired } from "../../Common/validators";

export type UserFormProps = FormRenderProps & {
  disabled?: boolean;
  onCancel?: () => void;
}

export type ChangePasswordProfileFormSubmitData = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const PasswordFieldWrapped = FormFieldWrapper(PasswordField)


function ChangePasswordProfileForm(props: UserFormProps) {
  const { submitting, disabled, handleSubmit, onCancel } = props;
  return <form onSubmit={handleSubmit} className="change-password-profile-form">
    <Stack sx={{ marginTop: 1 }} spacing={1.5}>
      <Field
        label="Текущий пароль"
        name="oldPassword"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Новый пароль"
        name="newPassword"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <Field
        label="Подтвердите пароль"
        name="confirmPassword"
        component={PasswordFieldWrapped}
        disabled={disabled || submitting}
        validate={isRequired}
      />
      <FormErrorMessage />
      <Button
        type="submit"
        variant="outlined"
        disabled={disabled || submitting}
      >
        Сохранить
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onCancel}
        disabled={disabled || submitting}
      >
        Отмена
      </Button>
    </Stack>
  </form >
}

const DefaultChangePasswordProfileForm = (props: any) => <Form {...props} render={ChangePasswordProfileForm} />

export default DefaultChangePasswordProfileForm;