import IStorageRepository from "../../../Data/Repositories/StorageRepository/IStorageRepository";
import StorageRepository from "../../../Data/Repositories/StorageRepository/StorageRepository";
import StorageUpdateDto from "../../Dtos/Storage/StorageUpdateDto";
import Storage from "../../Entities/Storage/Storage";
import StorageShort from "../../Entities/Storage/StorageShort";
import IStorageService from "./IStorageService";

class UserService implements IStorageService {
  constructor(readonly repository: IStorageRepository) { }

  async getAll() {
    return this.repository.getAll()
      .then((x) => x.sort(Storage.compare))
  }

  async getAllShort() {
    return this.repository.getAllShort()
      .then((x) => x.sort(StorageShort.compare))
  }

  async getUserStorage() {
    return this.repository.getUserStorage()
  }

  async update(data: StorageUpdateDto) {
    return this.repository.update(data);
  }
}

export default new UserService(StorageRepository);