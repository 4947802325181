import ChangePasswordDto from "../../Dtos/Profile/ChangePasswordDto";
import IProfileService from "./IProfileService";
import IProfileRepository from "../../../Data/Repositories/ProfileRepository/IProfileRepository";
import ProfileRepository from "../../../Data/Repositories/ProfileRepository/ProfileRepository";
import ProfileUpdateDto from "../../Dtos/Profile/ProfileUpdateDto";

class ProfileService implements IProfileService {
  constructor(readonly repository: IProfileRepository) { }

  async changePassword(data: ChangePasswordDto) {
    return this.repository.changePassword(data)
  }
  async profile() {
    return this.repository.profile()
  }
  async update(data: ProfileUpdateDto) {
    return this.repository.update(data)
  }
}

export default new ProfileService(ProfileRepository);