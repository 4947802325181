import { memo } from "react"
import { Cell, Head, HeadCell, Row, TableWithFixedHeader } from "../../Common/Components/Table"
import Directory from "../../Domain/Entities/Directory/Directory"
import UserGroupShort from "../../Domain/Entities/UserGroup/UserGroupShort"
import { PERMISSIONS } from "../../Domain/Enums/Permissions"
import MatrixTableBody from "./MatrixTableBody"

export type MatrixTableProps = {
  directories: Array<Directory>
  userGroups: Array<UserGroupShort>
  groupedDirectoryUserGroupPermission: Map<number, Map<number, number>>
  onPermissionChange?: (event: any, directory: Directory, userGroup: UserGroupShort, permission: PERMISSIONS) => void
}

function MatrixTable(props: MatrixTableProps) {
  const { directories, groupedDirectoryUserGroupPermission, userGroups, onPermissionChange } = props;

  if (directories.length === 0 || userGroups.length === 0) {
    return <h3 style={{ textAlign: 'center' }}>Список пуст</h3>
  }

  return <div className="matrix-table">
    <TableWithFixedHeader
      fixedTopTitle
      fixedLeftColumn
      separateBorder
      striped
      noBorder
      cellShadow
    >
      <Head>
        <Row>
          <HeadCell width={180}>Папки</HeadCell>
          {
            userGroups.map((x) => <Cell component="th" key={x.id}>{x.name}</Cell>)
          }
        </Row>
      </Head>
      <MatrixTableBody
        directories={directories}
        groupedDirectoryUserGroupPermission={groupedDirectoryUserGroupPermission}
        userGroups={userGroups}
        onPermissionChange={onPermissionChange}
      />
    </TableWithFixedHeader>
  </div>
}

export default memo(MatrixTable)