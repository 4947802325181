import { Api } from "../../Api/Api";
import IImportPasscardsRepository from "./IImportPasscardsRepository";

class ExportPasscardsRepository implements IImportPasscardsRepository {
  constructor(readonly api: typeof Api) { }

  async importPasscards(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.api.importPasscards(formData)
      .catch((error) => {
        throw error.response;
      });
  }

  async importPasscardsFromPassmanWithHierarchy(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.api.importPasscardsFromPassmanWithHierarchy(formData)
      .catch((error) => {
        throw error.response;
      });
  }

  async importPasscardsFromBitwardenWithHierarchy(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.api.importPasscardsFromBitwardenWithHierarchy(formData)
      .catch((error) => {
        throw error.response;
      });
  }
}

export default new ExportPasscardsRepository(Api);