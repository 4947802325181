import { useQuery } from "react-query";
import ExportPasscardsService from "../../../Domain/Services/ExportPasscardsService/ExportPasscardsService";
import { GET_EXPORT_FILE_WITH_PASSCARDS_QK } from "../Constants";

export default function useExportPasscardsWithHierarchyForPassman(
  encrypted?: boolean,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) {
  return useQuery(
    [GET_EXPORT_FILE_WITH_PASSCARDS_QK, encrypted],
    () => ExportPasscardsService.exportPasscardsWithHierarchyForPassman(encrypted),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
}