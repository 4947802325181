import IUserRepository from "../../../Data/Repositories/UserRepository/IUserRepository";
import UserRepository from "../../../Data/Repositories/UserRepository/UserRepository";
import UserCreateDto from "../../Dtos/User/UserCreateDto";
import UserUpdateDto from "../../Dtos/User/UserUpdateDto";
import User from "../../Entities/User/User";
import IUserService from "./IUserService";

class UserService implements IUserService {
  constructor(readonly repository: IUserRepository) { }

  async getAll() {
    return this.repository.getAll()
      .then((x) => x.sort(User.compare))
  }

  async getAllShort() {
    return this.repository.getAllShort()
  }

  async update(data: UserUpdateDto) {
    return this.repository.update(data);
  }

  async create(data: UserCreateDto) {
    return this.repository.create(data);
  }

  async delete(id: number) {
    return this.repository.delete(id);
  }

  async enabledUser(id: number) {
    return this.repository.enabledUser(id);
  }

  async disabledUser(id: number) {
    return this.repository.disabledUser(id);
  }

  async dropUserPassword(id: number) {
    return this.repository.dropUserPassword(id);
  }
}

export default new UserService(UserRepository);