import { getRole, Role, ROLES } from "../../Enums/Roles";
import UserGroupShort from "../UserGroup/UserGroupShort";

export default class Profile {
  constructor(
    readonly id: number,
    readonly firstName: string,
    readonly lastName: string,
    readonly patronymicName: string,
    readonly nickname: number[],
    readonly email: string,
    readonly role: Role,
    readonly isBlocked: boolean,
    readonly userGroups: Array<UserGroupShort>,
  ) { }

  get isAdministator() {
    return this.role.role === ROLES.ADMIN
  }

  static fromApi(obj: any) {
    return new Profile(
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.patronymicName,
      obj.nickname,
      obj.email,
      getRole(obj.role),
      obj.isBlocked,
      obj.userGroups,
    )
  }
}