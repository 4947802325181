import Chip from "@mui/material/Chip";

export type UserGroupsGrouppedProps = {
  list: Array<string | number>
  count: number,
}

export default function Groupped(props: UserGroupsGrouppedProps) {
  const { list, count } = props;
  return <>
    {
      list
        .filter((value, index) => index < count)
        .map((x) => <Chip key={x} className="groupped-item" label={x} />)
    }
    {list.length > count && <Chip
      title={list.slice(count).join(', ')}
      label={`+${list.length - count}`}
    />
    }
  </>
}