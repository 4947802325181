import { compareByAlphabetically } from "../../../Common/utils";
import { PERMISSIONS } from "../../Enums/Permissions";

export default class Directory {
  constructor(
    readonly id: number,
    readonly parentId: number | undefined,
    readonly name: string,
    readonly permission: PERMISSIONS,
    readonly childrens: Directory[],
  ) {
    this.childrens = childrens.map(Directory.fromApi)
    this.parentId = parentId === 0 ? undefined : parentId
  }

  static fromApi(obj: Directory) {
    return new Directory(
      obj.id,
      obj.parentId,
      obj.name,
      obj.permission,
      obj.childrens,
    )
  }

  static compare = (a: Directory, b: Directory) => compareByAlphabetically(a, b, 'name')
}